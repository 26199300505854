import { v4 as uuidv4 } from "uuid";

/******************************************************/
//Tenant
/******************************************************/

/******************************************************/
//Azienda
/******************************************************/
export const getLocalStorageSelectedAzienda = async () => {
  return localStorage.getItem("selectedAzienda");
};
export const setLocalStorageSelectedAzienda = async (azienda) => {
  localStorage.setItem("selectedAzienda", azienda);
};

/******************************************************/
//User
/******************************************************/

/******************************************************/
//Tabelle di base
/******************************************************/
export const getTabelleBase = () => {
  return [
    { id: "base_genere", name: "Genere" },
    { id: "base_mezzo_pagamento", name: "Mezzo di pagamento" },
    { id: "base_natura_prestazione", name: "Natura della prestazione" },
    { id: "base_natura_rapporto", name: "Natura del rapporto" },
    { id: "base_origine_fondi", name: "Origine dei fondi" },
    { id: "base_scopo_prestazione", name: "Scopo della prestazione" },
    { id: "base_tipo_documento", name: "Tipologia documento" },
    { id: "base_tipo_identificazione", name: "Tipologia identificazione" },
    { id: "base_tipo_personalita", name: "Tipologia personalità" },
  ];
};

/******************************************************/
//Tabelle di base
/******************************************************/
export const getTabelleBaseExtra = () => {
  return [
    { id: "base_paese", name: "Nazioni" },
    { id: "base_provincia", name: "Province" },
    { id: "base_comune", name: "Comuni" },
  ];
};

/******************************************************/
//Tutte le tabelle non di base
/******************************************************/
export const getTabelle = () => {
  return [
    { id: "fascicolo", name: "Fascicoli" },
    { id: "prestazione", name: "Prestazioni" },
    { id: "rischio", name: "Rischi" },
    { id: "indicatore_anomalia", name: "Indicatori Anomalia" },
    { id: "anagrafica", name: "Anagrafiche" },
    { id: "allegato", name: "Allegati" },
    { id: "azienda", name: "Aziende" },
    { id: "tenant", name: "Tenant" },
    { id: "user", name: "Utenti" },
    { id: "sos", name: "Segnalazioni operazioni sospette" },
    { id: "autovalutazione", name: "Autovalutazioni" },
  ];
};

/******************************************************/
//Import dati
/******************************************************/
export const getLocalStorageImportType = async () => {
  return localStorage.getItem("importType");
};
export const setLocalStorageImportType = async (type) => {
  localStorage.setItem("importType", type);
};

/******************************************************/
//Fascicolo
/******************************************************/
export const getLocalStorageSelectedFascicolo = async () => {
  return localStorage.getItem("selectedFascicolo");
};
export const setLocalStorageSelectedFascicolo = async (fascicolo) => {
  localStorage.setItem("selectedFascicolo", fascicolo);
};
export const getFascicoloStatus = async (fascicolo) => {
  const anagrafiche = fascicolo.anagrafica_fascicolos;
  const indicatori_anomalia = fascicolo.indicatore_anomalia;
  var lastAnagrafiche = [];
  var hasCliente = false;
  var cliente = null;
  var hasTitEff = false;
  var hasCorrelate = true;
  var hasCompleteInfos = [];

  //Per ogni CF recupero l'ultima versione
  if (anagrafiche.length > 0) {
    anagrafiche.forEach((item) => {
      if (
        lastAnagrafiche.filter(
          (x) =>
            x.anagrafica.Cf === item.anagrafica.Cf &&
            x.base_tipo_anagrafica.Key === item.base_tipo_anagrafica.Key
        ).length <= 0
      ) {
        const lastVersion = anagrafiche
          .filter(
            (x) =>
              x.anagrafica.Cf === item.anagrafica.Cf &&
              x.base_tipo_anagrafica.Key === item.base_tipo_anagrafica.Key
          )
          .sort((a, b) => b.anagrafica.VersionN - a.anagrafica.VersionN)[0];
        lastAnagrafiche.push(lastVersion);
      }
    });

    hasCliente =
      lastAnagrafiche.filter(
        (x) =>
          x.base_tipo_anagrafica.Key === "tipo_anagrafica_1" ||
          x.base_tipo_anagrafica.Key === "tipo_anagrafica_2"
      ).length > 0;
    cliente = hasCliente
      ? lastAnagrafiche.filter(
          (x) =>
            x.base_tipo_anagrafica.Key === "tipo_anagrafica_1" ||
            x.base_tipo_anagrafica.Key === "tipo_anagrafica_2"
        )[0]
      : null;

    if (cliente.anagrafica.IsPg) {
      hasTitEff =
        lastAnagrafiche.filter(
          (x) => x.base_tipo_anagrafica.Key === "tipo_anagrafica_5"
        ).length > 0;
    }

    if (hasCliente && cliente !== null && cliente.anagrafica.IsPg) {
      hasCorrelate =
        lastAnagrafiche.filter(
          (x) =>
            x.base_tipo_anagrafica.Key === "tipo_anagrafica_3" ||
            x.base_tipo_anagrafica.Key === "tipo_anagrafica_4"
        ).length > 0;
    }

    for (var i = 0; i < lastAnagrafiche.length; i++) {
      const item = lastAnagrafiche[i];
      var isComplete = false;

      if (item.anagrafica.IsPg) {
        isComplete =
          item.anagrafica.Cf !== undefined &&
          item.anagrafica.Cf !== "" &&
          item.anagrafica.PIva !== undefined &&
          item.anagrafica.PIva !== "" &&
          item.anagrafica.Denominazione !== undefined &&
          item.anagrafica.Denominazione !== "" &&
          item.anagrafica.AddrPaeseId !== undefined &&
          item.anagrafica.AddrPaeseId > 0 &&
          ((item.anagrafica.AddrProvinciaId !== undefined &&
            item.anagrafica.AddrProvinciaId > 0) ||
            (item.anagrafica.AddrProvincia !== undefined &&
              item.anagrafica.AddrProvincia !== "")) &&
          ((item.anagrafica.AddrComuneId !== undefined &&
            item.anagrafica.AddrComuneId > 0) ||
            (item.anagrafica.AddrComune !== undefined &&
              item.anagrafica.AddrComune !== "")) &&
          item.anagrafica.AddrIndirizzo !== undefined &&
          item.anagrafica.AddrIndirizzo !== "" &&
          item.anagrafica.AddrCap !== undefined &&
          item.anagrafica.AddrCap !== "" &&
          item.anagrafica.anagrafica_identificaziones_aggregate.aggregate
            .count > 0;

        if (item.anagrafica.anagrafica_identificaziones.length > 0) {
          for (
            let i = 0;
            i < item.anagrafica.anagrafica_identificaziones.length;
            i++
          ) {
            const ident = item.anagrafica.anagrafica_identificaziones[i];

            if (ident.ModalitaIdentId <= 0 || ident.DocTipoId <= 0) {
              isComplete = false;
            }
          }
        }
      } else {
        isComplete =
          item.anagrafica.Cf !== undefined &&
          item.anagrafica.Cf !== "" &&
          item.anagrafica.Nome !== undefined &&
          item.anagrafica.Nome !== "" &&
          item.anagrafica.Cognome !== undefined &&
          item.anagrafica.Cognome !== "" &&
          item.anagrafica.Professione !== undefined &&
          item.anagrafica.Professione !== "" &&
          item.anagrafica.NascitaLuogo !== undefined &&
          item.anagrafica.NascitaLuogo !== "" &&
          item.anagrafica.NascitaData !== undefined &&
          item.anagrafica.NascitaData !== null &&
          item.anagrafica.AddrPaeseId !== undefined &&
          item.anagrafica.AddrPaeseId > 0 &&
          ((item.anagrafica.AddrProvinciaId !== undefined &&
            item.anagrafica.AddrProvinciaId > 0) ||
            (item.anagrafica.AddrProvincia !== undefined &&
              item.anagrafica.AddrProvincia !== "")) &&
          ((item.anagrafica.AddrComuneId !== undefined &&
            item.anagrafica.AddrComuneId > 0) ||
            (item.anagrafica.AddrComune !== undefined &&
              item.anagrafica.AddrComune !== "")) &&
          item.anagrafica.AddrIndirizzo !== undefined &&
          item.anagrafica.AddrIndirizzo !== "" &&
          item.anagrafica.AddrCap !== undefined &&
          item.anagrafica.AddrCap !== "" &&
          item.anagrafica.anagrafica_identificaziones_aggregate.aggregate
            .count > 0;

        if (item.anagrafica.anagrafica_identificaziones.length > 0) {
          for (
            let i = 0;
            i < item.anagrafica.anagrafica_identificaziones.length;
            i++
          ) {
            const ident = item.anagrafica.anagrafica_identificaziones[i];

            if (
              ident.ModalitaIdentId <= 0 ||
              ident.DocTipoId <= 0 ||
              ident.DocRilData === null ||
              ident.DocScad === null ||
              ident.IdentifiedByUser === ""
            ) {
              isComplete = false;
            }
          }
        }
      }

      hasCompleteInfos.push(isComplete);
    }
  }

  const prestazioni = fascicolo.prestaziones;
  const hasPrestazioni = prestazioni.length > 0;
  let isRischiComplete = true;
  let isIndAnomaliaComplete = true;

  if (hasPrestazioni) {
    for (let i = 0; i < prestazioni.length; i++) {
      const prestItem = prestazioni[i];
      const prestUsers = prestItem.prestazione_users;
      const rischioUsers = prestItem.rischios;

      for (let m = 0; m < prestUsers.length; m++) {
        if (isRischiComplete) {
          if (
            rischioUsers.filter((x) => x.ProvidedBy === prestUsers[m].UserId)
              .length <= 0
          ) {
            isRischiComplete = false;
          }
        }

        if (isIndAnomaliaComplete) {
          if (
            indicatori_anomalia.filter(
              (x) => x.ProvidedBy === prestUsers[m].UserId
            ).length <= 0
          ) {
            isIndAnomaliaComplete = false;
          }
        }
      }
    }
  }

  let result =
    hasCliente &&
    hasCorrelate &&
    !hasCompleteInfos.includes(false) &&
    hasPrestazioni &&
    isRischiComplete &&
    isIndAnomaliaComplete;
  if (hasCliente && cliente.anagrafica.IsPg) {
    result =
      hasCliente &&
      hasTitEff &&
      hasCorrelate &&
      !hasCompleteInfos.includes(false) &&
      hasPrestazioni &&
      isRischiComplete &&
      isIndAnomaliaComplete;
  }

  let resultReason = [];
  if (!hasCliente) {
    resultReason.push("Cliente non presente");
  }
  if (!hasTitEff) {
    resultReason.push("Titolare effettivo non presente");
  }
  if (!hasCorrelate) {
    resultReason.push("Anagrafiche correlate non presenti");
  }
  if (hasCompleteInfos.includes(false)) {
    resultReason.push("Anagrafiche non complete o identificazioni mancanti");
  }
  if (!hasPrestazioni) {
    resultReason.push("Prestazioni non presenti");
  }
  if (!isRischiComplete) {
    resultReason.push("Valutazioni del rischio non presenti");
  }
  if (!isIndAnomaliaComplete) {
    resultReason.push("Indicatori anomalia non presenti");
  }

  const jsonResult = {
    status: result,
    reasons: resultReason,
  };

  return jsonResult;
};
export const getLocalStorageSelectedEntity = async () => {
  return localStorage.getItem("selectedEntity");
};
export const setLocalStorageSelectedEntity = async (entityType, entityId) => {
  const item = { entityType: entityType, entityId: entityId };
  localStorage.setItem("selectedEntity", JSON.stringify(item));
};
export const removeLocalStorageSelectedEntity = async () => {
  localStorage.removeItem("selectedEntity");
};

/******************************************************/
//Autovalutazione rischio
/******************************************************/
export const getLocalStorageSelectedAutovalutazione = async () => {
  return localStorage.getItem("selectedAutovalutazione");
};
export const setLocalStorageSelectedAutovalutazione = async (
  autovalutazione
) => {
  localStorage.setItem("selectedAutovalutazione", autovalutazione);
};

/******************************************************/
//CryptoJs
/******************************************************/
export const getBase64String = (value) => {
  const cryptoJS = require("crypto-js");
  const byteValue = cryptoJS.enc.Utf8.parse(value);
  const base64Value = cryptoJS.enc.Base64.stringify(byteValue);
  return base64Value.toString();
};

/******************************************************/
//Allegati
/******************************************************/
export const getUniqueFileName = () => {
  const d = new Date();
  var name =
    uuidv4().replaceAll("-", "") +
    d.getFullYear() +
    d.getMonth() +
    d.getDate() +
    d.getDay() +
    d.getHours() +
    d.getMinutes() +
    d.getSeconds();
  return name;
};
export const getUniqueReportFileName = (reportTypeName) => {
  const d = new Date();
  var name =
    reportTypeName +
    "_" +
    d.getFullYear() +
    d.getMonth() +
    d.getDate() +
    d.getDay() +
    d.getHours() +
    d.getMinutes() +
    d.getSeconds();
  return name;
};

/******************************************************/
//Anagrafica
/******************************************************/
export const getDataFromFiscalCode = (fiscalCode) => {
  if (fiscalCode === "" || fiscalCode.length < 16 || fiscalCode.length > 16) {
    return "";
  }

  var annoLasPart = fiscalCode.substring(6, 8);

  var anno = "19" + annoLasPart;
  if (
    parseInt(annoLasPart) >= 0 &&
    parseInt("20" + annoLasPart) <= new Date().getFullYear()
  ) {
    anno = "20" + annoLasPart;
  }
  var mese = fiscalCode.substring(8, 9);
  var giorno = fiscalCode.substring(9, 11);
  var codComune = fiscalCode.substring(11, 15);
  var sesso = "M";

  var giornoN = parseInt(giorno);
  if (giornoN > 40) {
    giorno = (giornoN - 40).toString();
    sesso = "F";
  }

  giorno = giorno.length === 1 ? "0" + giorno : giorno;

  switch (mese) {
    case "A": {
      mese = "01";
      break;
    }
    case "B": {
      mese = "02";
      break;
    }
    case "C": {
      mese = "03";
      break;
    }
    case "D": {
      mese = "04";
      break;
    }
    case "E": {
      mese = "05";
      break;
    }
    case "H": {
      mese = "06";
      break;
    }
    case "L": {
      mese = "07";
      break;
    }
    case "M": {
      mese = "08";
      break;
    }
    case "P": {
      mese = "09";
      break;
    }
    case "R": {
      mese = "10";
      break;
    }
    case "S": {
      mese = "11";
      break;
    }
    case "T": {
      mese = "12";
      break;
    }
    default: {
      mese = 0;
      break;
    }
  }

  return {
    data: giorno + "/" + mese + "/" + anno,
    sesso: sesso,
    codComune: codComune,
  };
};
export const isPIvaValid = (pIva) => {
  if (pIva === "") {
    return "";
  }

  if (pIva.length !== 11) {
    return "La lunghezza della partita IVA non è corretta: la partita IVA dovrebbe essere lunga esattamente 11 caratteri.";
  }

  const validi = "0123456789";
  for (let i = 0; i < 11; i++) {
    if (validi.indexOf(pIva.charAt(i)) === -1)
      return (
        "La partita IVA contiene un carattere non valido `" +
        pIva.charAt(i) +
        "'. I caratteri validi sono le cifre."
      );
  }

  let s = 0;

  for (let i = 0; i <= 9; i += 2) {
    s += pIva.charCodeAt(i) - "0".charCodeAt(0);
  }

  for (let i = 1; i <= 9; i += 2) {
    let c = 2 * (pIva.charCodeAt(i) - "0".charCodeAt(0));
    if (c > 9) {
      c = c - 9;
    }
    s += c;
  }

  if ((10 - (s % 10)) % 10 !== pIva.charCodeAt(10) - "0".charCodeAt(0)) {
    return "La partita IVA non è valida: il codice di controllo non corrisponde.";
  }

  return "";
};
export const isFiscalCodeValid = (
  firstName,
  secondName,
  sex,
  bitrhDate,
  birthPlace,
  comuni,
  paesi
) => {
  ///####################INSERIMENTO DINAMICO DALLA FORM##############################///
  var nome = firstName.replace(/\s/g, "");
  var cognome = secondName;
  var localita = birthPlace;
  ///####################COGNOME&NOMI##############################///
  var consonanti_nome = [];
  var vocali_nome = [];
  var consonanti_cognome = [];
  var vocali_cognome = [];

  // VARIABILI FILTRO
  var consonanti;
  var vocali;

  // VARIABILE CONTROLLO
  var consonanti_prese_nome;
  var consonanti_prese_cognome;

  ///####################ANNO DATI##############################///

  var dataDiNascita = bitrhDate;
  var data = new Date(dataDiNascita);
  var anno = data.getFullYear().toString().substring(2); //PRENDI LE ULTIME DUE CIFRE
  ///####################MESE E GIORNO##############################///
  var cod_mese = ["A", "B", "C", "D", "E", "H", "L", "M", "P", "R", "S", "T"];
  var mese = data.getMonth() + 1; //perché getmonth parte da 0
  var giorno = data.getDate();
  ///#################### SESSO ##############################///
  var maschio = sex === 1;
  var femmina = sex === 2;

  //#1 STEP : LA LUNGHEZZA NOME
  //##############################SCANSIONE NOME#######################################//
  if (nome.length < 3) {
    nome += "x";
  } else {
    //#2 STEP : DISTINZIONE TRA VOCALI E CONSONANTI
    //##############################VOCALI&CONSONANTI##############################//
    for (var i = 0; i <= nome.length; i++) {
      if (nome.charAt(i).match(/[aeiouAEIOU]/)) {
        //REGEX CHE  TROVA VOCALI SCRITTE IN MAIUSCOLO O IN MINUSCOLO
        // prendo le vocali
        vocali_nome[i] = nome.charAt(i).toUpperCase();
        vocali = vocali_nome.filter(Boolean);
      } else if (
        nome
          .charAt(i)
          .match(
            /[/',/",/" ",/" *",/"è",/"È",/"É",/"é",/"à",/"À",/"á",/"Á",/"ì",/"Ì",/"Í",/"ò",/"Ò","/ó",/"ù",/"Ù",/"ú"]/
          )
      ) {
        // REGEX CHE TOGLIE VIRGOLE E APOSTROFI
        continue;
      } else {
        //#PRENDI CONSONANTI
        consonanti_nome[i] = nome.charAt(i).toUpperCase();
        //FILTRO PER GLI SPAZI ,,,, PULENDO L'ARRAY
        consonanti = consonanti_nome.filter(Boolean);

        //prendo le consonanti e tolgo gli spazi dove stavano le vocali
      }
    }
  }
  //#3 STEP ---> SE LE CONSONANTI SONO MAGGIORI O UGUALI A 4
  //##############################CONTROLLO#######################################//

  //SE LE CONSONANTI SONO PIU' DI 4
  if (consonanti.length >= 4) {
    //prendo le consonanti a posizione 0,2,3
    consonanti_prese_nome = [consonanti[0], consonanti[2], consonanti[3]];
    //document.write(" Consonanti prese " +   consonanti_prese_nome);
  }
  //SE LE CONSONANTI SONO 1
  else if (consonanti.length === 1) {
    consonanti_prese_nome = [consonanti[0], vocali[0], vocali[1]];
  }
  //SE LE CONSONANTI SONO MENO DI 3
  else if (consonanti.length < 3) {
    //prendo le consonanti a posizione 0,1 e la prima vocale
    consonanti_prese_nome = [consonanti[0], consonanti[1], vocali[0]];
  }
  //SE LE CONSONANTI SONO 3
  else if (consonanti.length === 3) {
    //prendo le consonanti a posizione 0,1,2
    consonanti_prese_nome = [consonanti[0], consonanti[1], consonanti[2]];
  } else if(consonanti.length === 0){
    consonanti_prese_nome = [vocali[0],vocali[1],vocali[2]]
  }

  //#4 STEP : COGNOME
  //##############################SCANSIONE COGNOME#######################################//
  if (cognome.length < 3) {
    cognome += "x";
  } else {
    //#5 STEP : DISTINZIONE TRA VOCALI E CONSONANTI
    //##############################VOCALI&CONSONANTI##############################//
    for (var j = 0; j <= cognome.length; j++) {
      if (cognome.charAt(j).match(/[aeiouAEIOU]/)) {
        // prendo le vocali
        vocali_cognome[j] = cognome.charAt(j).toUpperCase();
        vocali = vocali_cognome.filter(Boolean);
      } else if (cognome.charAt(j).match(/[/',/",/" ",/" *"]/)) {
        continue;
      } else {
        //#PRENDI CONSONANTI
        consonanti_cognome[j] = cognome.charAt(j).toUpperCase();
        //FILTRO PER GLI SPAZI ,,,, PULENDO L'ARRAY
        consonanti = consonanti_cognome.filter(Boolean);

        //prendo le consonanti e tolgo gli spazi dove stavano le vocali
      }
    }
  }
  //#6 STEP ---> SE LE CONSONANTI SONO MINORI DI 3
  //##############################CONTROLLO#######################################//

  if (consonanti.length < 3) {
    //prendo le consonanti a posizione 0,1 e la prima vocale
    consonanti_prese_cognome = [consonanti[0], consonanti[1], vocali[0]];
  } else {
    //prendo le consonanti a posizione 0,1,2
    consonanti_prese_cognome = [consonanti[0], consonanti[1], consonanti[2]];
  }

  //SE LE CONSONANTI SONO 1
  if (consonanti.length === 1) {
    consonanti_prese_cognome = [consonanti[0], vocali[0], vocali[1]];
  }

  if(consonanti.length === 0){
    consonanti_prese_cognome = [vocali[0],vocali[1],vocali[2]]
  }

  //##############################PARTE ANNO CON REGEX#######################################//

  //##############################PARTE MESE & GIORNO###################################MARTY--PART###//
  switch (mese) {
    case 1:
      mese = cod_mese[0];
      break;
    case 2:
      mese = cod_mese[1];
      break;
    case 3:
      mese = cod_mese[2];
      break;
    case 4:
      mese = cod_mese[3];
      break;
    case 5:
      mese = cod_mese[4];
      break;
    case 6:
      mese = cod_mese[5];
      break;
    case 7:
      mese = cod_mese[6];
      break;
    case 8:
      mese = cod_mese[7];
      break;
    case 9:
      mese = cod_mese[8];
      break;
    case 10:
      mese = cod_mese[9];
      break;
    case 11:
      mese = cod_mese[10];
      break;
    case 12:
      mese = cod_mese[11];
      break;
    default:
      break;
  }

  let giornoString = "";
  //SE FEMMINA
  if (femmina) {
    giornoString = (giorno += 40).toString();
  } // ALTRIMENTI MASCHIO
  else if (maschio) {
    if (giorno <= 9) {
      giornoString = "0" + giorno.toString();
    } else {
      giornoString = giorno.toString();
    }
  }
  var localita_presa = "";

  comuni.forEach((comune) => {
    if (comune.Nome.toUpperCase() === localita.toUpperCase())
      localita_presa = comune.CodBelfiore;
  });

  if (localita_presa === "") {
    paesi.forEach((paese) => {
      if (paese.Nome.toUpperCase() === localita.toUpperCase())
        localita_presa = paese.CodiceAt;
    });
  }

  if (localita_presa === "") {
    return "Non è stato possibile calcolare il Codice Fiscale, il paese di nascita specificato è inesistente";
  }
  //##############################CODICE FISCALE GENERATO#####################################//
  var CF =
    consonanti_prese_cognome +
    consonanti_prese_nome +
    anno +
    mese +
    giornoString +
    localita_presa;

  //##############################PULISCE IL CODICE FISCALE#######################VEDI FUNZIONE GetCF()###########//
  var CF_FX = GetCF(CF); // TOGLIE LE VIRGOLE SEPARANO LE CONSONANTI DEI NOMI E COGNOMI

  //##############################CODICE CONTROLLO###########################################//
  var alpha_dispari = [];
  var alpha_pari = [];

  var somma_dispari = 0; // INIZIALIZZA SOMMA DISPARI
  var somma_pari = 0; // INIZIALIZZA SOMMA PARI
  var somma_controllo = 0; // INIZIALIZZA SOMMA CONTROLLO
  var lettera_controllo;

  //#FINE OPERAZIONE
  // switch  cifra_definitiva in base alla somma_controllo a cui poi si associerà la lettera

  for (var k = 0; k < CF_FX.length; k++) {
    if (k % 2 !== 1) {
      alpha_dispari[k] = CF_FX.charAt(k);
      switch (alpha_dispari[k]) {
        case "0":
        case "A":
          alpha_dispari[k] = 1;
          break;
        case "1":
        case "B":
          alpha_dispari[k] = 0;
          break;
        case "2":
        case "C":
          alpha_dispari[k] = 5;
          break;
        case "3":
        case "D":
          alpha_dispari[k] = 7;
          break;
        case "4":
        case "E":
          alpha_dispari[k] = 9;
          break;
        case "5":
        case "F":
          alpha_dispari[k] = 13;
          break;
        case "6":
        case "G":
          alpha_dispari[k] = 15;
          break;
        case "7":
        case "H":
          alpha_dispari[k] = 17;
          break;
        case "8":
        case "I":
          alpha_dispari[k] = 19;
          break;
        case "9":
        case "J":
          alpha_dispari[k] = 21;
          break;
        case "K":
          alpha_dispari[k] = 2;
          break;
        case "L":
          alpha_dispari[k] = 4;
          break;
        case "M":
          alpha_dispari[k] = 18;
          break;
        case "N":
          alpha_dispari[k] = 20;
          break;
        case "O":
          alpha_dispari[k] = 11;
          break;
        case "P":
          alpha_dispari[k] = 3;
          break;
        case "Q":
          alpha_dispari[k] = 6;
          break;
        case "R":
          alpha_dispari[k] = 8;
          break;
        case "S":
          alpha_dispari[k] = 12;
          break;
        case "T":
          alpha_dispari[k] = 14;
          break;
        case "U":
          alpha_dispari[k] = 16;
          break;
        case "V":
          alpha_dispari[k] = 10;
          break;
        case "W":
          alpha_dispari[k] = 22;
          break;
        case "X":
          alpha_dispari[k] = 25;
          break;
        case "Y":
          alpha_dispari[k] = 24;
          break;
        case "Z":
          alpha_dispari[k] = 23;
          break;
        default:
          break;
      }
      //SOMMA DEI CARATTERI IN POSIZIONE DISPARI
      somma_dispari += alpha_dispari[k];
    } else {
      alpha_pari[k] = CF_FX.charAt(k);
      switch (alpha_pari[k]) {
        case "0":
        case "A":
          alpha_pari[k] = 0;
          break;
        case "1":
        case "B":
          alpha_pari[k] = 1;
          break;
        case "2":
        case "C":
          alpha_pari[k] = 2;
          break;
        case "3":
        case "D":
          alpha_pari[k] = 3;
          break;
        case "4":
        case "E":
          alpha_pari[k] = 4;
          break;
        case "5":
        case "F":
          alpha_pari[k] = 5;
          break;
        case "6":
        case "G":
          alpha_pari[k] = 6;
          break;
        case "7":
        case "H":
          alpha_pari[k] = 7;
          break;
        case "8":
        case "I":
          alpha_pari[k] = 8;
          break;
        case "9":
        case "J":
          alpha_pari[k] = 9;
          break;
        case "K":
          alpha_pari[k] = 10;
          break;
        case "L":
          alpha_pari[k] = 11;
          break;
        case "M":
          alpha_pari[k] = 12;
          break;
        case "N":
          alpha_pari[k] = 13;
          break;
        case "O":
          alpha_pari[k] = 14;
          break;
        case "P":
          alpha_pari[k] = 15;
          break;
        case "Q":
          alpha_pari[k] = 16;
          break;
        case "R":
          alpha_pari[k] = 17;
          break;
        case "S":
          alpha_pari[k] = 18;
          break;
        case "T":
          alpha_pari[k] = 19;
          break;
        case "U":
          alpha_pari[k] = 20;
          break;
        case "V":
          alpha_pari[k] = 21;
          break;
        case "W":
          alpha_pari[k] = 22;
          break;
        case "X":
          alpha_pari[k] = 23;
          break;
        case "Y":
          alpha_pari[k] = 24;
          break;
        case "Z":
          alpha_pari[k] = 25;
          break;
        default:
          break;
      }
      somma_pari += alpha_pari[k];
    }
  }
  //#SOMMA FINALE
  somma_controllo = (somma_dispari + somma_pari) % 26;
  //#ARRAY DELLE LETTERE CHE SI ASSOCIERANNO AL RISULTATO DELLA SOMMA CONTROLLO
  var caratteri_lista = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "I",
    "J",
    "K",
    "L",
    "M",
    "N",
    "O",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  for (var z = 0; z <= 26; z++) {
    if (somma_controllo === z) {
      lettera_controllo = caratteri_lista[z];
    }
  }
  return CF_FX + lettera_controllo;
};
function GetCF(Cod) {
  let CF_MOD;
  let CF_MOD_ORA = "";
  for (var i = 0; i <= Cod.length; ++i) {
    CF_MOD = Cod.charAt(i);

    if (CF_MOD === ",") {
      continue;
    } else {
      CF_MOD_ORA += Cod.charAt(i);
    }
  }
  return CF_MOD_ORA;
}
export const isGuid = (guid) => {
  const guidPattern =
    /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[4][0-9a-fA-F]{3}-[89abAB][0-9a-fA-F]{3}-[0-9a-fA-F]{12}$/;
  return guidPattern.test(guid);
};
export const isAFiscalCode = (fiscalCode) => {
  let isFiscalCode = fiscalCode.match(
    /^([A-Z]{6}[0-9LMNPQRSTUV]{2}[ABCDEHLMPRST]{1}[0-9LMNPQRSTUV]{2}[A-Z]{1}[0-9LMNPQRSTUV]{3}[A-Z]{1})$|([0-9]{11})$/
  );
  return isFiscalCode !== null;
};
export const isValidCodiceFiscale = (cf) => {
  if (typeof cf !== 'string') return false;
  
  // Converte il codice in maiuscolo
  cf = cf.toUpperCase();

  // Controlla la lunghezza e il formato (16 caratteri alfanumerici)
  const regex = /^[A-Z]{6}[0-9]{2}[A-Z][0-9]{2}[A-Z][0-9]{3}[A-Z]$/;
  if (!regex.test(cf)) return false;

  return true;
}
export const removeBlanks = (input) => {
  return input.replace(/\s+/g, '');
}
export const isNumericEU_VAT = (vatNumber) => {
  // Lista delle lunghezze valide delle partite IVA nei paesi membri dell'UE
  const validLengths = [11];
  // Controlla se la partita IVA è composta solo da cifre e se la lunghezza è valida
  const isNumeric = /^\d+$/.test(vatNumber);
  const isValidLength = validLengths.includes(vatNumber.length);

  return isNumeric && isValidLength;
}
export const isValidItalianVAT = (partitaIVA) => {
  // Controlla che la partita IVA sia composta da 11 cifre
  if (!/^\d{11}$/.test(partitaIVA)) {
      return false;
  }

  let s = 0;
  for (let i = 0; i < 11; i++) {
      let n = parseInt(partitaIVA.charAt(i), 10);
      if ((i + 1) % 2 === 0) {
          n = n * 2;
          if (n > 9) {
              n = n - 9;
          }
      }
      s += n;
  }

  return s % 10 === 0;
}
/******************************************************/
//DateTime
/******************************************************/
export const isDateValid = (date) => {
  const parsedDate = typeof date === "string" ? new Date(date) : date;
  return parsedDate instanceof Date && !isNaN(parsedDate);
};
export const monthDiff = (d1, d2) => {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months;
};
export const isSameDate = (d1,d2) => {
  return isDateValid(d1) && 
    isDateValid(d2) &&
    d1.getFullYear() === d2.getFullYear() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getDate() === d2.getDate();
}

/******************************************************/
//String
/******************************************************/
export function isAlphaNumeric(str) {
  var code, i, len;

  for (i = 0, len = str.length; i < len; i++) {
    code = str.charCodeAt(i);
    if (
      !(code > 47 && code < 58) && // numeric (0-9)
      !(code > 64 && code < 91) && // upper alpha (A-Z)
      !(code > 96 && code < 123)
    ) {
      // lower alpha (a-z)
      return false;
    }
  }
  return true;
}

/******************************************************/
//Autovalutazione
/******************************************************/
export function getAnalisiNMesiById(id){
  switch(id)
  {
    case 0:
      return 6;
    case 1: 
      return 12;
    case 2:
      return 24;
    case 3:
      return 36
    default:
      return 6
  }
}

/*************************************************/
//Prestazione
/************************************************/
export function getPrestPrefix(tenantTypeId){
  let prefixPrest = "";
      if (tenantTypeId === 1) {
        prefixPrest = "COMM";
      } else if (tenantTypeId === 2) {
        prefixPrest = "CONS";
      } else if (tenantTypeId === 3) {
        prefixPrest = "AVV";
      } else if (tenantTypeId === 4) {
        prefixPrest = "NOT";
      }
    return prefixPrest;
}