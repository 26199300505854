/******************************************************/
//#region Tenant
/******************************************************/
export const query_tenant_byId = `
query getTenantById($tenantId: String!) {
  tenant(where: { TenantId: {_eq: $tenantId}}, order_by: {StatusDate: desc}) {
    TenantId
    OwnerId
    Name
    Identifier
    IsReadOnly
    IsDisabled
    IsDeleted
    StatusDate
    VatNumber
    AddrStreetName
    AddrStreetNumber
    AddrCity
    AddrProvince
    AddrZipCode
    AddrCountry
    AddrFull
    Classifier
  }
}
`;
//#endregion

/******************************************************/
//#region Azienda
/******************************************************/
export const query_aziende_byTenant = `
query getAziendeByTenant($tenantId: String!) {
  azienda(where: {
    TenantId: {_eq: $tenantId}
  }, order_by: {Denominazione: asc}) {
    Id
    Denominazione
    Cf
    PIva
    AddrPaeseId
    AddrProvinciaId
    AddrProvincia
    AddrComuneId
    AddrComune
    AddrIndirizzo
    AddrN
    AddrCap
    CreatedOn
    ModifiedOn
    CreatedBy
    ModifiedBy
    MeteringSlotId
  }
}
`;
export const query_aziende_byId = `
query getAziendeByTenant($id: Int!) {
  azienda_by_pk(Id: $id) {
    Id
    TenantId
    Denominazione
    Cf
    PIva
    AddrPaeseId
    AddrProvinciaId
    AddrProvincia
    AddrComuneId
    AddrComune
    AddrIndirizzo
    AddrN
    AddrCap
    CreatedOn
    ModifiedOn
    CreatedBy
    ModifiedBy
    MeteringSlotId
  }
}
`;
export const query_aziende_byCfPIva = `
query getAziendeByCfPiva($tenantId: String!, $cf: String!, $pIva: String!) {
  azienda(where: {
    TenantId: {_eq: $tenantId}, Cf: {_ilike: $cf}, PIva:{_ilike: $pIva}
    }) {
    Id
    TenantId
    Denominazione
    Cf
    PIva
    AddrPaeseId
    AddrProvinciaId
    AddrProvincia
    AddrComuneId
    AddrComune
    AddrIndirizzo
    AddrN
    AddrCap
    CreatedOn
    ModifiedOn
    CreatedBy
    ModifiedBy
    MeteringSlotId
  }
}
`;
export const query_aziende_byNameCfPIva = `
query getAziendeByCfPiva($tenantId: String!, $denominazione: String!, $cf: String!, $pIva: String!) {
  azienda(where: {
    TenantId: {_eq: $tenantId}, Denominazione: {_ilike: $denominazione}, Cf: {_ilike: $cf}, PIva:{_ilike: $pIva}
    }) {
    Id
    TenantId
    Denominazione
    Cf
    PIva
    AddrPaeseId
    AddrProvinciaId
    AddrProvincia
    AddrComuneId
    AddrComune
    AddrIndirizzo
    AddrN
    AddrCap
    CreatedOn
    ModifiedOn
    CreatedBy
    ModifiedBy
    MeteringSlotId
  }
}
`;
//#endregion

/******************************************************/
//#region Tabelle di base
/******************************************************/
export const query_base_app_setting = `
query getBaseAppSetting($key: String!) {
  base_app_setting(where: { Key: {_eq: $key}}) {
    Id
    Key
    Value
  }
}
`;

export const query_base_security_area = `
query getBaseSecurityArea {
  base_security_area(order_by: {Order: asc}) {
    Id
    Key
    Value
    Description
    Order
  }
}
`;

export const query_base_all = `
query getBaseAll($tenantId: String!) {
  base_mezzo_pagamento(where: {_or: [{_or: [{IsSystem: {_eq: true}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}]}]}, order_by: {Value: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
  base_natura_prestazione(where: {_or: [{_or: [{IsSystem: {_eq: true}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}]}]}, order_by: {Value: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
  base_natura_rapporto(where: {_or: [{_or: [{IsSystem: {_eq: true}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}]}]}, order_by: {Value: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
  base_origine_fondi(where: {_or: [{_or: [{IsSystem: {_eq: true}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}]}]}, order_by: {Value: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
  base_scopo_prestazione(where: {_or: [{_or: [{IsSystem: {_eq: true}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}]}]}, order_by: {Value: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
  base_tipo_anagrafica(where: {_or: [{_or: [{IsSystem: {_eq: true}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}]}]}, order_by: {Value: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
  base_tipo_documento(where: {_or: [{_or: [{IsSystem: {_eq: true}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}]}]}, order_by: {Value: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
    PersonType
    DocumentTypeId
    ValidityMonths
    ValidityOffsetDays
    NextBirthDay
  }
  base_tipo_identificazione(where: {_or: [{_or: [{IsSystem: {_eq: true}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}]}]}, order_by: {Value: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
  base_tipo_personalita(where: {_or: [{_or: [{IsSystem: {_eq: true}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}]}]}, order_by: {Value: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
  base_genere(where: {_or: [{_or: [{IsSystem: {_eq: true}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}]}]}, order_by: {Value: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
  base_paese(order_by: {Nome: asc}) {
    Id
    Identificativo
    Nome
    IsRischio
    CodiceAt
  }
  base_provincia(order_by: {Nome: asc}) {
    Id
    Sigla
    Nome
    Rischio
  }
  base_comune(order_by: {Nome: asc}) {
    Id
    Codice
    Nome
    Provincia
    CodBelfiore
  }
  base_tipo_partecipazione {
    Id
    Key
    Nome
    Descrizione
  }
  base_tipo_patrimonio (where: {_or: [{_or: [{IsSystem: {_eq: true}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}]}]}, order_by: {Order: asc}){
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
  base_tipo_reddito (where: {_or: [{_or: [{IsSystem: {_eq: true}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}]}]}, order_by: {Order: asc}){
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;

//base_paese
export const query_base_paese = `
query getBasePaese {
  base_paese(order_by: {Nome: asc}) {
    Id
    Identificativo
    Nome
    IsRischio
    CodiceAt
  }
}
`;
export const query_base_paese_byId = `
query getBasePaeseById($id: Int!) {
  base_paese(where: {Id: {_eq: $id}}) {
    Id
    Identificativo
    Nome
    IsRischio
    CodiceAt
  }
}
`;
export const query_base_paese_byIdent = `
query getBasePaeseByIdent($identificativo: String!) {
  base_paese(where: {Identificativo: {_eq: $identificativo}}) {
    Id
    Identificativo
    Nome
    IsRischio
    CodiceAt
  }
}
`;
export const query_base_paese_byNome = `
query getBasePaeseByNome($nome: String!) {
  base_paese(where: {Nome: {_ilike: $nome}}) {
    Id
    Identificativo
    Nome
    IsRischio
    CodiceAt
  }
}
`;
export const query_base_paese_byCodAt = `
query getBaseComuneByCodAt($codiceAt: String!) {
  base_paese(where: {CodiceAt: {_ilike: $codiceAt}}) {
    Id
    Identificativo
    Nome
    IsRischio
    CodiceAt
  }
}
`;

//base_provincia
export const query_base_provincia = `
query getBaseProvincia {
  base_provincia(order_by: {Nome: asc}) {
    Id
    Sigla
    Nome
    Rischio
  }
}
`;
export const query_base_provincia_byId = `
query getBaseProvinciaById($id: Int!) {
  base_provincia_by_pk(Id: $id) {
    Id
    Sigla
    Nome
    Rischio
  }
}
`;
export const query_base_provincia_byIdent = `
query getBaseProvinciaByIdent($sigla: String!) {
  base_provincia(where: {Sigla: {_eq: $sigla}}) {
    Id
    Sigla
    Nome
    Rischio
  }
}
`;

//base_comune
export const query_base_comune = `
query getBaseComune($provincia: String!) {
  base_comune(where: {Provincia: {_eq: $provincia}}) {
    Id
    Codice
    Nome
    Provincia
    CodBelfiore
  }
}
`;
export const query_base_comune_byId = `
query getBaseComuneById($id: Int!) {
  base_comune_by_pk(Id: $id) {
    Id
    Codice
    Nome
    Provincia
    CodBelfiore
  }
}
`;
export const query_base_comune_byNome = `
query getBaseComuneByNome($nome: String!) {
  base_comune(where: {Nome: {_ilike: $nome}}) {
    Id
    Codice
    Nome
    Provincia
    CodBelfiore
  }
}
`;
export const query_base_comune_byCodBelfiore = `
query getBaseComuneByCodBelfiore($codBelfiore: String!) {
  base_comune(where: {CodBelfiore: {_ilike: $codBelfiore}}) {
    Id
    Codice
    Nome
    Provincia
    CodBelfiore
  }
}
`;

//base_mezzo_pagamento
export const query_base_mezzo_pagamento = `
query getBaseMezzoPagamento($tenantId: String!) {
  base_mezzo_pagamento(where: {_or: [{_or: [{IsSystem: {_eq: true}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}]}]}, order_by: {Value: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_mezzo_pagamento_system = `
query getBaseMezzoPagamentoSystem {
  base_mezzo_pagamento(where: {IsSystem: {_eq: true}}, order_by: {Order: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_mezzo_pagamento_byTenant = `
query getBaseMezzoPagamentoByTenant($tenantId: String!) {
  base_mezzo_pagamento(where: {IsSystem: {_eq: false}, TenantId: {_eq: $tenantId}}, order_by: {Order: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_mezzo_pagamento_byId = `
query getBaseMezzoPagamentoById($id: Int!) {
  base_mezzo_pagamento_by_pk(Id: $id) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_mezzo_pagamento_byValue = `
query getBaseMezzoPagamentoByValue($tenantId: String!, $value: String!) {
  base_mezzo_pagamento(where: {_or: [{_or: [{IsSystem: {_eq: true}, Value: {_eq: $value}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}, Value: {_eq: $value}}]}]}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;

//base_natura_prestazione
export const query_base_natura_prestazione = `
query getBaseNaturaPrestazione($tenantId: String!) {
  base_natura_prestazione(where: {_or: [{_or: [{IsSystem: {_eq: true}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}]}]}, order_by: {Value: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_natura_prestazione_system = `
query getBaseNaturaPrestazioneSystem {
  base_natura_prestazione(where: {IsSystem: {_eq: true}}, order_by: {Order: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_natura_prestazione_byTenant = `
query getBaseNaturaPrestazioneByTenant($tenantId: String!) {
  base_natura_prestazione(where: {IsSystem: {_eq: false}, TenantId: {_eq: $tenantId}}, order_by: {Order: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_natura_prestazione_byId = `
query getBaseNaturaPrestazioneById($id: Int!) {
  base_natura_prestazione_by_pk(Id: $id) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_natura_prestazione_byKey = `
query getBaseNaturaPrestazioneByKey($key: String!) {
  base_natura_prestazione(where: {Key: {_eq: $key}}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_natura_prestazione_byValue = `
query getBaseNaturaPrestazioneByValue($tenantId: String!, $value: String!) {
  base_natura_prestazione(where: {_or: [{_or: [{IsSystem: {_eq: true}, Value: {_eq: $value}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}, Value: {_eq: $value}}]}]}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;

//base_natura_rapporto
export const query_base_natura_rapporto = `
query getBaseNaturaRapporto($tenantId: String!) {
  base_natura_rapporto(where: {_or: [{_or: [{IsSystem: {_eq: true}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}]}]}, order_by: {Value: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_natura_rapporto_system = `
query getBaseNaturaRapportoSystem {
  base_natura_rapporto(where: {IsSystem: {_eq: true}}, order_by: {Order: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_natura_rapporto_byTenant = `
query getBaseNaturaRapportoByTenant($tenantId: String!) {
  base_natura_rapporto(where: {IsSystem: {_eq: false}, TenantId: {_eq: $tenantId}}, order_by: {Order: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_natura_rapporto_byId = `
query getBaseNaturaRapportoById($id: Int!) {
  base_natura_rapporto_by_pk(Id: $id) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_natura_rapporto_byValue = `
query getBaseNaturaRapportoByValue($tenantId: String!, $value: String!) {
  base_natura_rapporto(where: {_or: [{_or: [{IsSystem: {_eq: true}, Value: {_eq: $value}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}, Value: {_eq: $value}}]}]}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;

//base_origine_fondi
export const query_base_origine_fondi = `
query getBaseOrigineFondi($tenantId: String!) {
  base_origine_fondi(where: {_or: [{_or: [{IsSystem: {_eq: true}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}]}]}, order_by: {Value: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_origine_fondi_system = `
query getBaseOrigineFondiSystem {
  base_origine_fondi(where: {IsSystem: {_eq: true}}, order_by: {Order: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_origine_fondi_byTenant = `
query getBaseOrigineFondiByTenant($tenantId: String!) {
  base_origine_fondi(where: {IsSystem: {_eq: false}, TenantId: {_eq: $tenantId}}, order_by: {Order: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_origine_fondi_byId = `
query getBaseOrigineFondiById($id: Int!) {
  base_origine_fondi_by_pk(Id: $id) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_origine_fondi_byValue = `
query getBaseOrigineFondiByValue($tenantId: String!, $value: String!) {
  base_origine_fondi(where: {_or: [{_or: [{IsSystem: {_eq: true}, Value: {_eq: $value}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}, Value: {_eq: $value}}]}]}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;

//base_scopo_prestazione
export const query_base_scopo_prestazione = `
query getBaseScopoPrestazione($tenantId: String!) {
  base_scopo_prestazione(where: {_or: [{_or: [{IsSystem: {_eq: true}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}]}]}, order_by: {Value: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_scopo_prestazione_system = `
query getBaseScopoPrestazioneSystem {
  base_scopo_prestazione(where: {IsSystem: {_eq: true}}, order_by: {Order: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_scopo_prestazione_byTenant = `
query getBaseScopoPrestazioneByTenant($tenantId: String!) {
  base_scopo_prestazione(where: {IsSystem: {_eq: false}, TenantId: {_eq: $tenantId}}, order_by: {Order: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_scopo_prestazione_byId = `
query getBaseScopoPrestazioneById($id: Int!) {
  base_scopo_prestazione_by_pk(Id: $id) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_scopo_prestazione_byKey = `
query getBaseScopoPrestazioneById($key: String!) {
  base_scopo_prestazione(where: {Key: {_eq: $key}}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_scopo_prestazione_byValue = `
query getBaseScopoPrestazioneByValue($tenantId: String!, $value: String!) {
  base_scopo_prestazione(where: {_or: [{_or: [{IsSystem: {_eq: true}, Value: {_eq: $value}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}, Value: {_eq: $value}}]}]}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;

//base_tipo_anagrafica
export const query_base_tipo_anagrafica = `
query getBaseTipoAnagrafica($tenantId: String!) {
  base_tipo_anagrafica(where: {_or: [{_or: [{IsSystem: {_eq: true}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}]}]}, order_by: {Value: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_tipo_anagrafica_system = `
query getBaseTipoAnagraficaSystem {
  base_tipo_anagrafica(where: {IsSystem: {_eq: true}}, order_by: {Order: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_tipo_anagrafica_byTenant = `
query getBaseTipoAnagraficaByTenant($tenantId: String!) {
  base_tipo_anagrafica(where: {IsSystem: {_eq: false}, TenantId: {_eq: $tenantId}}, order_by: {Order: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_tipo_anagrafica_byId = `
query getBaseTipoAnagraficaById($id: Int!) {
  base_tipo_anagrafica(Id: $id) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_tipo_anagrafica_byKey = `
query getBaseTipoAnagraficaByKey($key: String!) {
  base_tipo_anagrafica(where: {Key: {_eq: $key}}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;

//base_tipo_documento
export const query_base_tipo_documento = `
query getBaseTipoDocumento($tenantId: String!) {
  base_tipo_documento(where: {_or: [{_or: [{IsSystem: {_eq: true}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}]}]}, order_by: {Value: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
    PersonType
  }
}
`;
export const query_base_tipo_documento_system = `
query getBaseTipoDocumentoSystem {
  base_tipo_documento(where: {IsSystem: {_eq: true}}, order_by: {Order: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
    PersonType
  }
}
`;
export const query_base_tipo_documento_byTenant = `
query getBaseTipoDocumentoByTenant($tenantId: String!) {
  base_tipo_documento(where: {IsSystem: {_eq: false}, TenantId: {_eq: $tenantId}}, order_by: {Order: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
    PersonType
  }
}
`;
export const query_base_tipo_documento_byId = `
query getBaseTipoDocumentoById($id: Int!) {
  base_tipo_documento_by_pk(Id: $id) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
    PersonType
    DocumentTypeId
    ValidityMonths
    ValidityOffsetDays
    NextBirthDay
  }
}
`;
export const query_base_tipo_documento_byValue = `
query getBaseTipoDocumentoByValue($tenantId: String!, $value: String!) {
  base_tipo_documento(where: {_or: [{_or: [{IsSystem: {_eq: true}, Value: {_eq: $value}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}, Value: {_eq: $value}}]}]}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;

//base_tipo_identificazione
export const query_base_tipo_identificazione = `
query getBaseTipoIdentificazione($tenantId: String!) {
  base_tipo_identificazione(where: {_or: [{_or: [{IsSystem: {_eq: true}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}]}]}, order_by: {Value: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_tipo_identificazione_system = `
query getBaseTipoIdentificazioneSystem {
  base_tipo_identificazione(where: {IsSystem: {_eq: true}}, order_by: {Order: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_tipo_identificazione_byTenant = `
query getBaseTipoIdentificazioneByTenant($tenantId: String!) {
  base_tipo_identificazione(where: {IsSystem: {_eq: false}, TenantId: {_eq: $tenantId}}, order_by: {Order: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_tipo_identificazione_byId = `
query getBaseTipoIdentificazioneyId($id: Int!) {
  base_tipo_identificazione_by_pk(Id: $id) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_tipo_identificazione_byValue = `
query getBaseTipoIdentificazioneByValue($tenantId: String!, $value: String!) {
  base_tipo_identificazione(where: {_or: [{_or: [{IsSystem: {_eq: true}, Value: {_eq: $value}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}, Value: {_eq: $value}}]}]}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;

//base_tipo_personalita
export const query_base_tipo_personalita = `
query getBaseTipoPersonalita($tenantId: String!) {
  base_tipo_personalita(where: {_or: [{_or: [{IsSystem: {_eq: true}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}]}]}, order_by: {Value: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_tipo_personalita_system = `
query getBaseTipoPersonalitaSystem {
  base_tipo_personalita(where: {IsSystem: {_eq: true}}, order_by: {Order: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_tipo_personalita_byTenant = `
query getBaseTipoPersonalitaByTenant($tenantId: String!) {
  base_tipo_personalita(where: {IsSystem: {_eq: false}, TenantId: {_eq: $tenantId}}, order_by: {Order: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_tipo_personalita_byId = `
query getBaseTipoPersonalitaId($id: Int!) {
  base_tipo_personalita_by_pk(Id: $id) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_tipo_personalita_byValue = `
query getBaseTipoPersonalitaByValue($tenantId: String!, $value: String!) {
  base_tipo_personalita(where: {_or: [{_or: [{IsSystem: {_eq: true}, Value: {_eq: $value}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}, Value: {_eq: $value}}]}]}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;

//base_tipo_prestazione
export const query_base_tipo_prestazione = `
query getBaseTipoPrestazione($tenantType: Int!) {
  base_tipo_prestazione(where: {BaseTenantTipoId: {_eq: $tenantType}}) {
    Id
    Nome
    Rischio
    Regole
    DataInizioValidita
    DataFineValidita
  }
}
`;
export const query_base_tipo_prestazione_ByDate = `
query getBaseTipoPrestazioneByDate($tenantType : Int!,$dateStart : date!){
  base_tipo_prestazione(
    where: 
    {BaseTenantTipoId: {_eq:$tenantType},
    _and : [ {DataInizioValidita: {_lte:$dateStart}, DataFineValidita: {_gte:$dateStart}}]}){
    Id
    Nome
    Rischio
    Regole
    DataInizioValidita
    DataFineValidita
  }
}
`
export const query_base_tipo_prestazione_all = `
query getBaseTipoPrestazioneAll {
  base_tipo_prestazione {
    Id
    BaseTenantTipoId
    Nome
    Rischio
    Regole
  }
}
`;
export const query_base_tipo_prestazione_byId = `
query getBaseTipoPrestazioneById($id: Int!) {
  base_tipo_prestazione_by_pk(where: {Id: {_eq: $id}}) {
    Id
    Nome
    Rischio
    Regole
  }
}
`;
export const query_base_tipo_prestazione_byNome = `
query getBaseTipoPrestazioneByNome($nome: String!) {
  base_tipo_prestazione(where: {Nome: {_eq: $nome}}) {
    Id
    Nome
    Rischio
    Regole
  }
}
`;

//base_tipo_partecipazione
export const query_base_tipo_partecipazione = `
query getBaseTipoPartecipazione {
  base_tipo_partecipazione {
    Id
    Key
    Nome
    Descrizione
  }
}
`;
export const query_base_tipo_partecipazione_byId = `
query getBaseTipoPartecipazioneById($id: Int!) {
  base_tipo_partecipazione_by_pk(where: {Id: {_eq: $id}}) {
    Id
    Key
    Nome
    Descrizione
  }
}
`;
export const query_base_tipo_partecipazione_byNome = `
query getBaseTipoPartecipazioneByNome($nome: String!) {
  base_tipo_partecipazione(where: {Nome: {_eq: $nome}}) {
    Id
    Key
    Nome
    Descrizione
  }
}
`;

//base_tipo_tenant
export const query_base_tipo_tenant = `
query getBaseTipoTenant {
  base_tipo_tenant(order_by: {Order: asc}) {
    Id
    Key
    Value
    Order
  }
}
`;
export const query_base_tipo_tenant_byId = `
query getBaseTipoTenantById($id: Int!) {
  base_tipo_tenant_by_pk(where: {Id: {_eq: $id}}) {
    Id
    Key
    Value
    Order
  }
}
`;

//base_valuta
export const query_base_valuta = `
query getBaseValuta {
  base_valuta(order_by: {IsoCode: asc}) {
    Id
    Country
    Currency
    IsoCode
    UicCode
    EurRate
    UsdRate
    UsdExcConv
    UsdExcConvCode
    RefDate
  }
}
`;
export const query_base_valuta_byISO = `
query getBaseValutaByIso($isoCode: String!) {
  base_valuta(where: {IsoCode: {_eq: $isoCode}}) {
    Id
    Country
    Currency
    IsoCode
    UicCode
    EurRate
    UsdRate
    UsdExcConv
    UsdExcConvCode
    RefDate
  }
}
`;

//base_genere
export const query_base_genere = `
query getBaseGenere($tenantId: String!) {
  base_genere(where: {_or: [{_or: [{IsSystem: {_eq: true}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}}]}]}, order_by: {Value: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_genere_system = `
query getBaseGenereSystem {
  base_genere(where: {IsSystem: {_eq: true}}, order_by: {Order: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_genere_byTenant = `
query getBaseGenereByTenant($tenantId: String!) {
  base_genere(where: {IsSystem: {_eq: false}, TenantId: {_eq: $tenantId}}, order_by: {Order: asc}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_genere_byId = `
query getBaseGenereId($id: Int!) {
  base_genere_by_pk(Id: $id) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_genere_byValue = `
query getBaseGenereByValue($tenantId: String!, $value: String!) {
  base_genere(where: {_or: [{_or: [{IsSystem: {_eq: true}, Value: {_eq: $value}}]}, {_or: [{TenantId: {_eq: $tenantId}, IsSystem: {_eq: false}, Value: {_eq: $value}}]}]}) {
    Id
    TenantId
    Key
    Value
    Order
    IsSystem
  }
}
`;
export const query_base_genere_inAnagrafica = `
query getBaseGenereInAnagrafica($tenantId: String!) {
  anagrafica(where: {anagrafica_fascicolos: {fascicolo: {TenantId: {_eq: $tenantId}}}}, distinct_on: GenereId) {
    GenereId
  }
}
`;

//base_rischio_grado
export const query_base_rischio_grado_byTenantTipo = `
query getBaseRischioGradoByTenantTipo($tenantTipoId: Int!) {
  base_rischio_grado(where: {BaseTenantTipoId: {_eq: $tenantTipoId}}) {
    Id
    TipoRischio
    GradoRischio
    AdeguataVerifica
    ValoreRischioDa
    ValoreRischioA
    Colore
    Offset
  }
}
`;

//base_indicatore_anomalia
export const query_base_indicatore_anomalia = `
query getBaseIndicatoreAnomalia {
  base_indicatore_anomalia(order_by: {Section: asc, Point: asc, SubPoint: asc}) {
    Id
    ParentId
    Section
    Point
    SubPoint
    Description
    IsVisible
  }
}
`;
//#endregion

/******************************************************/
//#region Fascicolo
/******************************************************/
export const query_fascicolo_byTenant = `
query getFascicoloByTenant($tenantId: String!, $aziendaId: Int!, $userId: String!) {
  fascicolo(where: {
    TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId},
    _or: [
      {_or: [{IsPrivate: {_eq: false}}]},
      {_or: [
        {anagrafica_fascicolos: {anagrafica: {anagrafica_identificaziones: {IdentifiedByUser: {_eq: $userId}}}}}, 
        {prestaziones: {prestazione_user: {UserId: {_eq: $userId}}}}
      ]}
  ]}, order_by: {CreatedOn: desc}) {
    Id
    TenantId
    AziendaId
    Name
    Date
    IsPrivate
    CreatedOn
    ModifiedOn
    prestaziones {
      EndDate
      prestazione_users {
        UserId
      }
      rischios {
        ProvidedBy
      }
      rischios_aggregate {
        aggregate {
          count
        }
      }
    }
    anagrafica_fascicolos(where: {anagrafica: {IsDeleted: {_eq: false}}}) {
      anagrafica {
        IsPg
        Cf
        VersionN
        PIva
        Denominazione
        Nome
        Cognome
        Professione
        NascitaLuogo
        NascitaData
        AddrPaeseId
        AddrProvinciaId
        AddrProvincia
        AddrComuneId
        AddrComune
        AddrIndirizzo
        AddrCap
        WorkspaceId
        anagrafica_identificaziones {
          Id
          ModalitaIdentId
          DocTipoId
          DocN
          DocRilDa
          DocRilData
          DocScad
          ApNome
          ApCf
          IdentifiedByUser
          IdentifiedOn
        }
        anagrafica_identificaziones_aggregate {
          aggregate {
            count
          }
        }
      }
      base_tipo_anagrafica {
        Key
      }
    }
    indicatore_anomalia {
      Id
      Date
      ProvidedBy
      Result
      Note
      CreatedOn
      ModifiedOn
      CreatedBy
      ModifiedBy
    }
  }
}
`;
export const query_fascicolo_byTenant_count = `
query countFascicoloByTenant($tenantId: String!, $aziendaId: Int!, $userId: String!) {
  fascicolo_aggregate(where: {
    TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId},
    _or: [
      {_or: [{IsPrivate: {_eq: false}}]},
      {_or: [
        {anagrafica_fascicolos: {anagrafica: {anagrafica_identificaziones: {IdentifiedByUser: {_eq: $userId}}}}}, 
        {prestaziones: {prestazione_user: {UserId: {_eq: $userId}}}}
      ]}
    ]
  }) {
    aggregate {
      count(columns: Id)
    }
  }
}
`;
export const query_fascicolo_byId = `
query getFascicoloById($id: Int!) {
  fascicolo_by_pk(Id: $id) {
    Id
    TenantId
    AziendaId
    Name
    Date
    IsPrivate
    CreatedOn
    ModifiedOn
    prestaziones {
      prestazione_users {
        UserId
      }
      rischios {
        ProvidedBy
      }
      rischios_aggregate {
        aggregate {
          count
        }
      }
    }
    anagrafica_fascicolos(where: {anagrafica: {IsDeleted: {_eq: false}}}) {
      anagrafica {
        IsPg
        Cf
        VersionN
        PIva
        Denominazione
        Nome
        Cognome
        Professione
        NascitaLuogo
        NascitaData
        AddrPaeseId
        AddrProvinciaId
        AddrProvincia
        AddrComuneId
        AddrComune
        AddrIndirizzo
        AddrCap
        WorkspaceId
        anagrafica_identificaziones {
          Id
          ModalitaIdentId
          DocTipoId
          DocN
          DocRilDa
          DocRilData
          DocScad
          ApNome
          ApCf
          IdentifiedByUser
          IdentifiedOn
        }
        anagrafica_identificaziones_aggregate {
          aggregate {
            count
          }
        }
      }
      base_tipo_anagrafica {
        Key
      }
    }
    indicatore_anomalia {
      Id
      Date
      ProvidedBy
      Result
      Note
      CreatedOn
      ModifiedOn
      CreatedBy
      ModifiedBy
    }
  }
}
`;
export const query_fascicolo_byAzienda_count = `
query countFascicoloByAzienda($tenantId: String!, $aziendaId: Int!) {
  fascicolo_aggregate(where: {
    TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}
  }) {
    aggregate {
      count(columns: Id)
    }
  }
}
`;
export const query_fascicolo_byTenant_byCustomer = `
query getFascicoloByTenant($tenantId: String!, $aziendaId: Int!) {
  fascicolo(where: {
      TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}
    }, order_by: {CreatedOn: desc}) {
    Id
    anagrafica_fascicolos(where: {
      anagrafica: {IsDeleted: {_eq: false}}, 
      base_tipo_anagrafica: {
        _or: [
          {_or: [{Key: {_eq: "tipo_anagrafica_1"}}]},
          {_or: [{Key: {_eq: "tipo_anagrafica_2"}}]}
        ]
      }
    }, order_by: {anagrafica: {VersionN: desc}}) {
      anagrafica {
        IsPg
        Cf
        VersionN
        PIva
        Denominazione
        WorkspaceId
      }
    }
  }
}
`;
//#endregion

/******************************************************/
//#region Fascicolo - Utenti
/******************************************************/
export const query_fascicolo_user_count_byUserId = `
query countFascicoloUserByUserId($tenantId: String!, $aziendaId: Int!, $userId: String!) {
  fascicolo_aggregate(where: {
    TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId},
    _or: [
      {_or: [
        {anagrafica_fascicolos: {anagrafica: {anagrafica_identificaziones: {IdentifiedByUser: {_eq: $userId}}}}}, 
        {prestaziones: {prestazione_user: {UserId: {_eq: $userId}}}}
      ]}
  ]}) {
    aggregate {
      count
    }
  }
}
`;
//#endregion

/******************************************************/
//#region Fascicolo - Anagrafiche
/******************************************************/
export const query_anagrafiche_byTenant_count = `
query countAnagraficheByTenant($tenantId: String!, $aziendaId: Int!, $userId: String!) {
  anagrafica_aggregate(where: {
    TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}, IsDeleted: {_eq: false},
    anagrafica_fascicolos: {
      fascicolo: {
        _or: [
          {_or: [{IsPrivate: {_eq: false}}]},
          {_or: [
            {anagrafica_fascicolos: {anagrafica:{anagrafica_identificaziones: {IdentifiedByUser: {_eq: $userId}}}}},
            {prestaziones: {prestazione_user: {UserId: {_eq: $userId}}}}
          ]}
        ]
      }
    }
  }, distinct_on: Cf) {
    aggregate {
      count(columns: Id)
    }
  }
}
`;
export const query_anagrafiche_byFascicolo = `
query getAnagraficheByFascicolo($fascicoloId: Int!) {
  anagrafica(where: {anagrafica_fascicolos: {FascicoloId: {_eq: $fascicoloId}}, IsDeleted: {_eq: false}}, order_by: {Cf: desc, VersionN: desc}) {
    Id
    ParentId
    IsPg
    Cf
    Nome
    Cognome
    GenereId
    NascitaLuogo
    NascitaData
    Professione
    Denominazione
    PIva
    ReaComune
    ReaN
    AddrPaeseId
    AddrProvinciaId
    AddrProvincia
    AddrComuneId
    AddrComune
    AddrIndirizzo
    AddrN
    AddrCap
    Addr2PaeseId
    Addr2ProvinciaId
    Addr2Provincia
    Addr2ComuneId
    Addr2Comune
    Addr2Indirizzo
    Addr2N
    Addr2Cap
    IsPep
    IsNotPepMoreThanYear
    PepCarica
    PepRuolo
    CreatedOn
    VersionN
    VersionDate
    IsDeleted
    CreatedBy
    ModifiedBy
    WorkspaceId
    AmlId
    Office1Id
    Office2Id
    TipoRedditoId
    TipoPatrimonioId
    CapSociale
    OrgAmmin
    DescAttivita
    TipoRedditoAltro
    TipoPatrimonioAltro
    anagrafica_identificaziones(order_by: {CreatedOn: desc}) {
      Id
      DocTipoId
      DocN
      DocRilDa
      DocRilData
      DocScad
      ApNome
      ApCf
      IdentifiedByUser
      IdentifiedOn
      CreatedOn
      ModifiedOn
      CreatedBy
      ModifiedBy
      base_tipo_identificazione {
        Id
        Key
        Value
      }
    }
    anagrafica_bancadatis {
      Id
      Repository
      Infos
      CreatedOn
    }
    anagrafica_fascicolos(where: {FascicoloId: {_eq: $fascicoloId}}) {
      Id
      AnagraficaId
      FascicoloId
      TipoAnagId
      TipoPartId
      TipoPartDesc
      DataFine
      TipoRappId
      TipoRappDesc
      base_tipo_anagrafica {
        Id
        Key
        Value
      }
    }
  }
}
`;
export const query_anagrafiche_byTenant = `
query getAnagraficheByTenant($tenantId: String!, $aziendaId: Int!, $userId: String!) {
  anagrafica(where: {
    TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}, IsDeleted: {_eq: false},
    anagrafica_fascicolos: {
			_or: [{_or: [{fascicolo: {IsPrivate: {_eq: false}}}]}, {_or: [{anagrafica: {anagrafica_fascicolos: {anagrafica: {anagrafica_identificaziones: {IdentifiedByUser: {_eq: $userId}}}}}}, {fascicolo: {prestaziones: {prestazione_user: {UserId: {_eq: $userId}}}}}]}]
    }
  }, order_by: {CreatedOn: desc}) {
    Id
    ParentId
    IsPg
    Cf
    Nome
    Cognome
    GenereId
    NascitaLuogo
    NascitaData
    Professione
    Denominazione
    PIva
    ReaComune
    ReaN
    AddrPaeseId
    AddrProvinciaId
    AddrProvincia
    AddrComuneId
    AddrComune
    AddrIndirizzo
    AddrN
    AddrCap
    Addr2PaeseId
    Addr2ProvinciaId
    Addr2Provincia
    Addr2ComuneId
    Addr2Comune
    Addr2Indirizzo
    Addr2N
    Addr2Cap
    IsPep
    IsNotPepMoreThanYear
    PepCarica
    PepRuolo
    CreatedOn
    VersionN
    VersionDate
    IsDeleted
    CreatedBy
    ModifiedBy
    WorkspaceId
    Office1Id
    Office2Id
    AmlId
    anagrafica_fascicolos {
      fascicolo {
        Id
        Name
        Date
      }
      base_tipo_anagrafica {
        Id
        Key
        Value
      }
      TipoPartId
      TipoPartDesc
    }
    anagrafica_identificaziones(order_by: {CreatedOn: desc}) {
      Id
      DocTipoId
      DocN
      DocRilDa
      DocRilData
      DocScad
      ApNome
      ApCf
      IdentifiedByUser
      IdentifiedOn
      CreatedOn
      ModifiedOn
      CreatedBy
      ModifiedBy
      base_tipo_identificazione {
        Id
        Key
        Value
      }
    }
    anagrafica_bancadatis {
      Id
      Repository
      Infos
      CreatedOn
    }
  }
}
`;
export const query_anagrafiche_byParentId = `
query getAnagraficheByTenant($tenantId: String!, $aziendaId: Int!, $parentId: Int!) {
  anagrafica(where: {
    TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}, IsDeleted: {_eq: false}, ParentId : {_eq: $parentId}    
  }, order_by: {CreatedOn: desc}) {
    Id
    ParentId
    IsPg
    Cf
    Nome
    Cognome
    GenereId
    NascitaLuogo
    NascitaData
    Professione
    Denominazione
    PIva
    ReaComune
    ReaN
    AddrPaeseId
    AddrProvinciaId
    AddrProvincia
    AddrComuneId
    AddrComune
    AddrIndirizzo
    AddrN
    AddrCap
    Addr2PaeseId
    Addr2ProvinciaId
    Addr2Provincia
    Addr2ComuneId
    Addr2Comune
    Addr2Indirizzo
    Addr2N
    Addr2Cap
    IsPep
    IsNotPepMoreThanYear
    PepCarica
    CreatedOn
    VersionN
    VersionDate
    IsDeleted
    CreatedBy
    ModifiedBy
    WorkspaceId
    anagrafica_fascicolos {
      fascicolo {
        Id
        Name
        Date
      }
      base_tipo_anagrafica {
        Id
        Key
        Value
      }
      TipoPartId
      TipoPartDesc
    }
    anagrafica_identificaziones(order_by: {CreatedOn: desc}) {
      Id
      DocTipoId
      DocN
      DocRilDa
      DocRilData
      DocScad
      ApNome
      ApCf
      IdentifiedByUser
      IdentifiedOn
      CreatedOn
      ModifiedOn
      CreatedBy
      ModifiedBy
      base_tipo_identificazione {
        Id
        Key
        Value
      }
    }
    anagrafica_bancadatis {
      Id
      Repository
      Infos
      CreatedOn
    }
  }
}
`;
export const query_anagrafiche_byId = `
query getAnagraficheByTenant($tenantId: String!, $aziendaId: Int!, $id: Int!) {
  anagrafica(where: {
    TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}, IsDeleted: {_eq: false}, Id : {_eq: $id}    
  }, order_by: {CreatedOn: desc}) {
    Id
    ParentId
    IsPg
    Cf
    Nome
    Cognome
    GenereId
    NascitaLuogo
    NascitaData
    Professione
    Denominazione
    PIva
    ReaComune
    ReaN
    AddrPaeseId
    AddrProvinciaId
    AddrProvincia
    AddrComuneId
    AddrComune
    AddrIndirizzo
    AddrN
    AddrCap
    Addr2PaeseId
    Addr2ProvinciaId
    Addr2Provincia
    Addr2ComuneId
    Addr2Comune
    Addr2Indirizzo
    Addr2N
    Addr2Cap
    IsPep
    IsNotPepMoreThanYear
    PepCarica
    CreatedOn
    VersionN
    VersionDate
    IsDeleted
    CreatedBy
    ModifiedBy
    WorkspaceId    
    AmlId
    Office1Id
    Office2Id
    TipoRedditoId
    TipoPatrimonioId
    CapSociale
    OrgAmmin
    DescAttivita
    TipoRedditoAltro
    TipoPatrimonioAltro
    anagrafica_fascicolos {
      fascicolo {
        Id
        Name
        Date
      }
      base_tipo_anagrafica {
        Id
        Key
        Value
      }
      TipoPartId
      TipoPartDesc
    }
    anagrafica_identificaziones(order_by: {CreatedOn: desc}) {
      Id
      DocTipoId
      DocN
      DocRilDa
      DocRilData
      DocScad
      ApNome
      ApCf
      IdentifiedByUser
      IdentifiedOn
      CreatedOn
      ModifiedOn
      CreatedBy
      ModifiedBy
      base_tipo_identificazione {
        Id
        Key
        Value
      }
    }
    anagrafica_bancadatis {
      Id
      Repository
      Infos
      CreatedOn
    }
  }
}
`;
export const query_anagrafiche_byFiscalCode = `
query getAnagraficheByCf($tenantId: String!, $aziendaId: Int!, $cf: String!) {
  anagrafica(where: {
    TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}, IsDeleted: {_eq: false}, IsPg: {_eq: false}, Cf: {_ilike: $cf}
  }, order_by: {VersionN: desc}) {
    Id
    ParentId
    IsPg
    Cf
    Nome
    Cognome
    GenereId
    NascitaLuogo
    NascitaData
    Professione
    Denominazione
    PIva
    ReaComune
    ReaN
    AddrPaeseId
    AddrProvinciaId
    AddrProvincia
    AddrComuneId
    AddrComune
    AddrIndirizzo
    AddrN
    AddrCap
    Addr2PaeseId
    Addr2ProvinciaId
    Addr2Provincia
    Addr2ComuneId
    Addr2Comune
    Addr2Indirizzo
    Addr2N
    Addr2Cap
    IsPep
    IsNotPepMoreThanYear
    PepCarica
    PepRuolo
    CreatedOn
    VersionN
    VersionDate
    IsDeleted
    CreatedBy
    ModifiedBy
    WorkspaceId
    AmlId
    Office1Id
    Office2Id
    TipoRedditoId
    TipoPatrimonioId
    CapSociale
    OrgAmmin
    DescAttivita
    TipoRedditoAltro
    TipoPatrimonioAltro
    anagrafica_fascicolos {
      Id
      fascicolo {
        Id
        Name
        Date
        CreatedBy
        ModifiedBy
      }
      base_tipo_anagrafica {
        Id
        Key
        Value
      }
      TipoPartId
      TipoPartDesc
    }
    anagrafica_identificaziones(order_by: {CreatedOn: desc}) {
      Id
      DocTipoId
      DocN
      DocRilDa
      DocRilData
      DocScad
      ApNome
      ApCf
      IdentifiedByUser
      IdentifiedOn
      CreatedOn
      ModifiedOn
      CreatedBy
      ModifiedBy
      base_tipo_identificazione {
        Id
        Key
        Value
      }
    }
    anagrafica_bancadatis {
      Id
      Repository
      Infos
      CreatedOn
    }
  }
}
`;
export const query_anagrafiche_byCf = `
query getAnagraficheByCf($tenantId: String!, $aziendaId: Int!, $cf: String!, $nome: String!, $cognome: String!) {
  anagrafica(where: {
    TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}, IsDeleted: {_eq: false}, IsPg: {_eq: false}, Cf: {_ilike: $cf}, Nome:{_ilike: $nome}, Cognome:{_ilike: $cognome}
  }, order_by: {VersionN: desc}) {
    Id
    ParentId
    IsPg
    Cf
    Nome
    Cognome
    GenereId
    NascitaLuogo
    NascitaData
    Professione
    Denominazione
    PIva
    ReaComune
    ReaN
    AddrPaeseId
    AddrProvinciaId
    AddrProvincia
    AddrComuneId
    AddrComune
    AddrIndirizzo
    AddrN
    AddrCap
    Addr2PaeseId
    Addr2ProvinciaId
    Addr2Provincia
    Addr2ComuneId
    Addr2Comune
    Addr2Indirizzo
    Addr2N
    Addr2Cap
    IsPep
    IsNotPepMoreThanYear
    PepCarica
    PepRuolo
    CreatedOn
    VersionN
    VersionDate
    IsDeleted
    CreatedBy
    ModifiedBy
    WorkspaceId
    AmlId
    Office1Id
    Office2Id    
    TipoRedditoId
    TipoPatrimonioId
    CapSociale
    OrgAmmin
    DescAttivita
    TipoRedditoAltro
    TipoPatrimonioAltro
    anagrafica_fascicolos {
      Id
      fascicolo {
        Id
        Name
        Date
        CreatedBy
        ModifiedBy
      }
      base_tipo_anagrafica {
        Id
        Key
        Value
      }
      TipoPartId
      TipoPartDesc
    }
    anagrafica_identificaziones(order_by: {CreatedOn: desc}) {
      Id
      DocTipoId
      DocN
      DocRilDa
      DocRilData
      DocScad
      ApNome
      ApCf
      IdentifiedByUser
      IdentifiedOn
      CreatedOn
      ModifiedOn
      CreatedBy
      ModifiedBy
      base_tipo_identificazione {
        Id
        Key
        Value
      }
    }
    anagrafica_bancadatis {
      Id
      Repository
      Infos
      CreatedOn
    }
  }
}
`;
export const query_anagrafiche_byCf_PG = `
query getAnagraficheByCf($tenantId: String!, $aziendaId: Int!, $cf: String!, $pIva: String!, $denominazione: String!) {
  anagrafica(where: {
    TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}, IsDeleted: {_eq: false}, IsPg: {_eq: true}, Cf: {_ilike: $cf}, PIva: {_ilike: $pIva}, Denominazione: {_ilike: $denominazione}
  }, order_by: {VersionN: desc}) {
    Id
    ParentId
    IsPg
    Cf
    Nome
    Cognome
    GenereId
    NascitaLuogo
    NascitaData
    Professione
    Denominazione
    PIva
    ReaComune
    ReaN
    AddrPaeseId
    AddrProvinciaId
    AddrProvincia
    AddrComuneId
    AddrComune
    AddrIndirizzo
    AddrN
    AddrCap
    Addr2PaeseId
    Addr2ProvinciaId
    Addr2Provincia
    Addr2ComuneId
    Addr2Comune
    Addr2Indirizzo
    Addr2N
    Addr2Cap
    IsPep
    IsNotPepMoreThanYear
    PepCarica
    PepRuolo
    CreatedOn
    VersionN
    VersionDate
    IsDeleted
    CreatedBy
    ModifiedBy
    WorkspaceId
    AmlId
    Office1Id
    Office2Id    
    TipoRedditoId
    TipoPatrimonioId
    CapSociale
    OrgAmmin
    DescAttivita
    TipoRedditoAltro
    TipoPatrimonioAltro
    anagrafica_fascicolos {
      Id
      fascicolo {
        Id
        Name
        Date
        CreatedBy
        ModifiedBy
      }
      base_tipo_anagrafica {
        Id
        Key
        Value
      }
      TipoPartId
      TipoPartDesc
    }
    anagrafica_identificaziones(order_by: {CreatedOn: desc}) {
      Id
      DocTipoId
      DocN
      DocRilDa
      DocRilData
      DocScad
      ApNome
      ApCf
      IdentifiedByUser
      IdentifiedOn
      CreatedOn
      ModifiedOn
      CreatedBy
      ModifiedBy
      base_tipo_identificazione {
        Id
        Key
        Value
      }
    }
    anagrafica_bancadatis {
      Id
      Repository
      Infos
      CreatedOn
    }
  }
}
`;
export const query_anagrafiche_docInScadenza = `
query getAnagraficheDocInScad($tenantId: String!, $aziendaId: Int!, $userId: String!) {
  anagrafica(where: {
    TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}, IsDeleted: {_eq: false}, IsPg: {_eq: false},
    anagrafica_fascicolos: {
      fascicolo: {
        _or: [
          {_or: [{IsPrivate: {_eq: false}}]},
          {_or: [
            {anagrafica_fascicolos: {anagrafica: {anagrafica_identificaziones: {IdentifiedByUser: {_eq: $userId}}}}}, 
            {prestaziones: {prestazione_user: {UserId: {_eq: $userId}}}}
          ]}
        ]
      }
    },
    anagrafica_identificaziones : {
      _and:[
      	{DocScad: {_is_null: false}}
      ],
    },      
  }, order_by: [{Cf: asc}, {VersionN: desc}], distinct_on: Cf) {
    anagrafica_fascicolos {
      fascicolo {
        Id
        Name
      }
    },
    Id,
    Denominazione,
    anagrafica_identificaziones (order_by: {DocScad : desc}) {
      DocScad
    }
  }
}
`;
export const query_anagrafiche_rel_fascicolo = `
query getAnagraficheRelFascicolo($anagraficaId: Int!) {
  anagrafica_fascicolo(where: 
    {AnagraficaId: {_eq: $anagraficaId}}) {
      Id
      AnagraficaId
      FascicoloId
      TipoAnagId
      TipoPartId
      TipoPartDesc
      DataFine
  }
}
`;
//#endregion

/******************************************************/
//#region Fascicolo - Anagrafiche - Banca dati
/******************************************************/
export const query_anagrafiche_bancaDati_byAnag = `
query getAnagraficheBancaDatiByTenant($anagraficaId: Int!) {
  anagrafica_bancadati(where: {AnagraficaId: {_eq: $anagraficaId}}, order_by: {CreatedOn: desc}) {
    Id
    Repository
    Infos
    CreatedOn
    CreatedBy
  }
}
`;
//#endregion

/******************************************************/
//#region Fascicolo - Prestazioni
/******************************************************/
export const query_prestazioni_byFascicolo = `
query getPrestazioniByFascicolo($fascicoloId: Int!) {
  prestazione(where: {FascicoloId: {_eq: $fascicoloId}}, order_by: {StartDate: desc}) {
    Id
    TipoKey
    PrestazioneId
    StartDate
    EndDate
    PaeseId
    ProvinciaId
    Provincia
    CittaId
    Citta
    ScopoId
    NaturaId
    Descrizione
    AstensioneMotivo
    IsEsoneroObbAst
    IsPrivate
    CreatedOn
    ModifiedOn
    CreatedBy
    ModifiedBy
    SettoreAttivita
    SettorePrestazione
    Ragione
    base_tipo_prestazione {
      BaseTenantTipoId
      Nome
      Regole
      Rischio
    }
    base_natura_prestazione {
      Value
    }
    base_scopo_prestazione {
      Value
    }
    prestazione_users {
      Id
      PrestazioneId
      UserId
    }
    prestazione_pagamentos {
      Id
      ValutaId
      ValutaData
      ValutaCambio
      ValutaImporto
      ImportoEuro
      ModalitaPagamentoId
      OrigineId
      CreatedOn
      ModifiedOn
      CreatedBy
      ModifiedBy
      Data
      base_valutum {
        IsoCode
      }
      base_mezzo_pagamento {
        Value
      }
      base_origine_fondi {
        Value
      }
    }
    rischios(order_by: {Date: desc}) {
      Id
      Date
      DataControlloCostante
      ProvidedBy
    }
  }
}
`;
export const query_prestazioni_byTenant_count = `
query countPrestazioniByTenant($tenantId: String!, $aziendaId: Int!, $userId: String!) {
  prestazione_aggregate(where: {
    fascicolo: {
      TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId},
      _or: [
        {_or: [{IsPrivate: {_eq: false}}]},
        {_or: [
          {anagrafica_fascicolos: {anagrafica: {anagrafica_identificaziones: {IdentifiedByUser: {_eq: $userId}}}}}, 
          {prestaziones: {prestazione_user: {UserId: {_eq: $userId}}}}
        ]}
      ]
    }
  }) {
    aggregate {
      count(columns: Id)
    }
  }
}
`;
export const query_prestazioni_byTenant = `
query getPrestazioniByTenant($tenantId: String!, $aziendaId: Int!, $userId: String!) {
  prestazione(where: {
    fascicolo: {
      TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId},
      _or: [
        {_or: [{IsPrivate: {_eq: false}}]},
        {_or: [
          {anagrafica_fascicolos: {anagrafica: {anagrafica_identificaziones: {IdentifiedByUser: {_eq: $userId}}}}}, 
          {prestaziones: {prestazione_user: {UserId: {_eq: $userId}}}}
        ]}
      ]
    }
  }, order_by: {StartDate: desc}) {
    Id
    TipoKey
    PrestazioneId
    StartDate
    EndDate
    PaeseId
    ProvinciaId
    Provincia
    CittaId
    Citta
    ScopoId
    NaturaId
    Descrizione
    AstensioneMotivo
    IsEsoneroObbAst
    IsPrivate
    CreatedOn
    ModifiedOn
    CreatedBy
    ModifiedBy
    fascicolo {
      Id
      Name
      Date
    }
    base_tipo_prestazione {
      Nome
      Rischio
    }
    base_scopo_prestazione {
      Value
    }
    base_natura_prestazione {
      Value
    }
    prestazione_pagamentos_aggregate {
      aggregate {
        count
      }
    }
    prestazione_users_aggregate {
      aggregate {
        count
      }
    }
    rischios_aggregate {
      aggregate {
        count
      }
    }
  }
}
`;
//#endregion

/******************************************************/
//#region Fascicolo - Valutazioni rischio
/******************************************************/
export const query_rischi_byPrestazione = `
query getRischiByPrestazione($prestazioneId: Int!) {
  rischio(where: {PrestazioneId: {_eq: $prestazioneId}}, order_by: {Date: desc}) {
    Id
    PrestazioneId
    Date
    SpA1Livello
    SpA1_1
    SpA1_2
    SpA1_3
    SpA1_4
    SpA1_5
    SpA1_6
    SpA1_7
    SpA1Altro
    SpA1AltroNote
    SpA2Livello
    SpA2_1
    SpA2_2
    SpA2_3
    SpA2Altro
    SpA2AltroNote
    SpA3Livello
    SpA3_1
    SpA3_2
    SpA3_3
    SpA3_4
    SpA3Altro
    SpA3AltroNote
    SpA4Livello
    SpA4_1
    SpA4_2
    SpA4_3
    SpA4_4
    SpA4Altro
    SpA4AltroNote
    SpASubTotale
    SpB1Livello
    SpB1_1
    SpB1_2
    SpB1_3
    SpB1Altro
    SpB1AltroNote
    SpB2Livello
    SpB2_1
    SpB2_2
    SpB2_3
    SpB2_4
    SpB2Altro
    SpB2AltroNote
    SpB3Livello
    SpB3_1
    SpB3_2
    SpB3_3
    SpB3Altro
    SpB3AltroNote
    SpB4Livello
    SpB4_1
    SpB4_2
    SpB4_3
    SpB4Altro
    SpB4AltroNote
    SpB5Livello
    SpB5_1
    SpB5_2
    SpB5_3
    SpB5Altro
    SpB5AltroNote
    SpB6Livello
    SpB6_1
    SpB6_2
    SpB6_3
    SpB6_4
    SpB6Altro
    SpB6AltroNote
    SpBSubTotale
    SpC1_1_Livello
    SpC1_2_Livello
    SpC1_3_Livello
    SpCSubTotale
    EfRiTotale
    EfSpTotale
    EfTotale
    EfAdeguataLivello
    ProvidedBy
    CreatedOn
    ModifiedOn
    HasTabellaB
    DataControlloCostante
    CreatedBy
    ModifiedBy
  }
}
`;
export const query_rischi_byTenant_count = `
query countRischiByTenant($tenantId: String!, $aziendaId: Int!, $userId: String!) {
  rischio_aggregate(where: {
    prestazione: {fascicolo: {
      TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId},
      _or: [
        {_or: [{IsPrivate: {_eq: false}}]},
        {_or: [
          {anagrafica_fascicolos: {anagrafica: {anagrafica_identificaziones: {IdentifiedByUser: {_eq: $userId}}}}}, 
          {prestaziones: {prestazione_user: {UserId: {_eq: $userId}}}}
        ]}
      ]
    }}
  }, distinct_on: PrestazioneId) {
    aggregate {
      count(columns: Id)
    }
  }
}
`;
export const query_rischi_byPrestazione_count = `
query countRischiByPrestazione($tenantId: String!, $aziendaId: Int!, $prestazioneId: Int!) {
  rischio_aggregate(where: {
    prestazione: {fascicolo: {TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}}, Id : {_eq: $prestazioneId}}
  }, distinct_on: PrestazioneId) {
    aggregate {
      count(columns: Id)
    }
  }
}
`;
export const query_rischi_byTenant = `
query getRischiByTenant($tenantId: String!, $aziendaId: Int!, $userId: String!) {
  rischio(where: {
    prestazione: {
      fascicolo: {
        TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId},
        _or: [
          {_or: [{IsPrivate: {_eq: false}}]},
          {_or: [
            {anagrafica_fascicolos: {anagrafica: {anagrafica_identificaziones: {IdentifiedByUser: {_eq: $userId}}}}}, 
            {prestaziones: {prestazione_user: {UserId: {_eq: $userId}}}}
          ]}
        ]
      }
    }
  }, order_by: {Date: desc}) {
    Id
    PrestazioneId
    Date
    SpA1Livello
    SpA1_1
    SpA1_2
    SpA1_3
    SpA1_4
    SpA1_5
    SpA1_6
    SpA1_7
    SpA1Altro
    SpA1AltroNote
    SpA2Livello
    SpA2_1
    SpA2_2
    SpA2_3
    SpA2Altro
    SpA2AltroNote
    SpA3Livello
    SpA3_1
    SpA3_2
    SpA3_3
    SpA3_4
    SpA3Altro
    SpA3AltroNote
    SpA4Livello
    SpA4_1
    SpA4_2
    SpA4_3
    SpA4_4
    SpA4Altro
    SpA4AltroNote
    SpASubTotale
    SpB1Livello
    SpB1_1
    SpB1_2
    SpB1_3
    SpB1Altro
    SpB1AltroNote
    SpB2Livello
    SpB2_1
    SpB2_2
    SpB2_3
    SpB2_4
    SpB2Altro
    SpB2AltroNote
    SpB3Livello
    SpB3_1
    SpB3_2
    SpB3_3
    SpB3Altro
    SpB3AltroNote
    SpB4Livello
    SpB4_1
    SpB4_2
    SpB4_3
    SpB4Altro
    SpB4AltroNote
    SpB5Livello
    SpB5_1
    SpB5_2
    SpB5_3
    SpB5Altro
    SpB5AltroNote
    SpB6Livello
    SpB6_1
    SpB6_2
    SpB6_3
    SpB6_4
    SpB6Altro
    SpB6AltroNote
    SpBSubTotale
    SpC1_1_Livello
    SpC1_2_Livello
    SpC1_3_Livello
    SpCSubTotale
    EfRiTotale
    EfSpTotale
    EfTotale
    EfAdeguataLivello
    ProvidedBy
    CreatedOn
    ModifiedOn
    HasTabellaB
    DataControlloCostante
    CreatedBy
    ModifiedBy
    prestazione {
      StartDate
      EndDate
      fascicolo {
        Id
        Name
      }
      base_tipo_prestazione {
        BaseTenantTipoId
        Nome
        Rischio
      }
      base_scopo_prestazione {
        Value
      }
      base_natura_prestazione {
        Value
      }
    }
  }
}
`;
export const query_rischio_byId = `
query getRischiByTenant($tenantId: String!, $aziendaId: Int!, $userId: String!, $id: Int!) {
  rischio(where: {
    Id: {_eq: $id },
    prestazione: {
      fascicolo: {
        TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId},
        _or: [
          {_or: [{IsPrivate: {_eq: false}}]},
          {_or: [
            {anagrafica_fascicolos: {anagrafica: {anagrafica_identificaziones: {IdentifiedByUser: {_eq: $userId}}}}}, 
            {prestaziones: {prestazione_user: {UserId: {_eq: $userId}}}}
          ]}
        ]
      }
    }
  }, order_by: {Date: desc}) {
    Id
    PrestazioneId
    Date
    SpA1Livello
    SpA1_1
    SpA1_2
    SpA1_3
    SpA1_4
    SpA1_5
    SpA1Altro
    SpA1AltroNote
    SpA2Livello
    SpA2_1
    SpA2_2
    SpA2_3
    SpA2Altro
    SpA2AltroNote
    SpA3Livello
    SpA3_1
    SpA3_2
    SpA3_3
    SpA3_4
    SpA3Altro
    SpA3AltroNote
    SpA4Livello
    SpA4_1
    SpA4_2
    SpA4Altro
    SpA4AltroNote
    SpASubTotale
    SpB1Livello
    SpB1_1
    SpB1_2
    SpB1_3
    SpB1Altro
    SpB1AltroNote
    SpB2Livello
    SpB2_1
    SpB2_2
    SpB2_3
    SpB2_4
    SpB2Altro
    SpB2AltroNote
    SpB3Livello
    SpB3_1
    SpB3_2
    SpB3Altro
    SpB3AltroNote
    SpB4Livello
    SpB4_1
    SpB4_2
    SpB4Altro
    SpB4AltroNote
    SpB5Livello
    SpB5_1
    SpB5_2
    SpB5_3
    SpB5Altro
    SpB5AltroNote
    SpB6Livello
    SpB6_1
    SpB6_2
    SpB6_3
    SpB6Altro
    SpB6AltroNote
    SpBSubTotale
    SpC1_1_Livello
    SpC1_2_Livello
    SpC1_3_Livello
    SpCSubTotale
    EfRiTotale
    EfSpTotale
    EfTotale
    EfAdeguataLivello
    ProvidedBy
    CreatedOn
    ModifiedOn
    HasTabellaB
    DataControlloCostante
    CreatedBy
    ModifiedBy
    prestazione {
      fascicolo {
        Id
        Name
      }
      base_tipo_prestazione {
        Nome
        Rischio
      }
      base_scopo_prestazione {
        Value
      }
      base_natura_prestazione {
        Value
      }
    }
  }
}
`;
export const query_rischi_byDataControlloCostante = `
query getRischiByTenant($tenantId: String!, $aziendaId: Int!, $userId: String!, $dtFrom: timestamp!, $dtTo: timestamp!) {
  rischio(where: {
    prestazione: {
      fascicolo: {
        TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId},
        _or: [
          {_or: [{IsPrivate: {_eq: false}}]},
          {_or: [
            {anagrafica_fascicolos: {anagrafica: {anagrafica_identificaziones: {IdentifiedByUser: {_eq: $userId}}}}}, 
            {prestaziones: {prestazione_user: {UserId: {_eq: $userId}}}}
          ]}
        ]
      }
    },
    _and:[
      {DataControlloCostante: {_gte: $dtFrom}},
      {DataControlloCostante: {_lte: $dtTo}}
    ]
  }, order_by: {DataControlloCostante: desc}) {
    Id
    PrestazioneId
    DataControlloCostante
    prestazione {
      fascicolo {
        Id
        Name
      }
    }
  }
}
`;
//#endregion

/******************************************************/
//#region Fascicolo - Indicatori anomalia
/******************************************************/
export const query_indicatori_anomalia_byFascicolo = `
query getIndicatoriByFascicolo($fascicoloId: Int!) {
  indicatore_anomalia(where: {FascicoloId: {_eq: $fascicoloId}}, order_by: {Date: desc}) {
    Id
    Date
    ProvidedBy
    Result
    Note
    CreatedOn
    ModifiedOn
    CreatedBy
    ModifiedBy
  }
}
`;
//#endregion

/******************************************************/
//#region Banca dati
/******************************************************/
export const query_bancaDati_byIdent = `
query getBancaDatiByIdent($identifier: String!, $companyName: String!) {
  bancadati(where: {Type: {_neq: "PF"}, 
  _or: [
    {_or: [{Infos: {_ilike: $identifier}}]},
    {_or: [{Infos: {_ilike: $companyName}}]}
  ]
}) {
    Id
    Repository
    Identifier
    Type
    Name
    Surname
    BornDate
    Infos
  }
}
`;
export const query_bancaDati_byName = `
query getBancaDatiByName($name: String!, $surname: String!, $bornDate: timestamp!) {
  bancadati(where: {Type: {_eq: "PF"}, 
    Name: {_ilike: $name}, Surname: {_ilike: $surname}, BornDate: {_eq: $bornDate}
  }) {
    Id
    Repository
    Identifier
    Type
    Name
    Surname
    BornDate
    Infos
  }
}
`;
//#endregion

/******************************************************/
//#region SOS
/******************************************************/
export const query_sos_byFascicolo = `
query getSOSByFascicolo($fascicoloId: Int!) {
  sos(where: {FascicoloId: {_eq: $fascicoloId}}, order_by: {Date: desc}) {
    Id
    Date
    Motivo
    Note
    ReceiptId
    ReportedByUser
    CreatedOn
    ModifiedOn
    CreatedBy
    ModifiedBy
    user {
      Name
      Surname
    }
  }
}
`;
export const query_sos_byTenant_count = `
query countSOSByTenant($tenantId: String!, $aziendaId: Int!, $userId: String!) {
  sos_aggregate(where: {
    fascicolo: {
      TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId},
      _or: [
        {_or: [{IsPrivate: {_eq: false}}]},
        {_or: [
          {anagrafica_fascicolos: {anagrafica: {anagrafica_identificaziones: {IdentifiedByUser: {_eq: $userId}}}}}, 
          {prestaziones: {prestazione_user: {UserId: {_eq: $userId}}}}
        ]}
      ]
    }
  }) {
    aggregate {
      count(columns: Id)
    }
  }
}
`;
export const query_sos_byTenant = `
query getSOSByTenant($tenantId: String!, $aziendaId: Int!, $userId: String!) {
  sos(where: {
    fascicolo: {
      TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId},
      _or: [
        {_or: [{IsPrivate: {_eq: false}}]},
        {_or: [
          {anagrafica_fascicolos: {anagrafica: {anagrafica_identificaziones: {IdentifiedByUser: {_eq: $userId}}}}}, 
          {prestaziones: {prestazione_user: {UserId: {_eq: $userId}}}}
        ]}
      ]
    }
  }, order_by: {Date: desc}) {
    Id
    Date
    Motivo
    Note
    ReceiptId
    ReportedByUser
    CreatedOn
    ModifiedOn
    CreatedBy
    ModifiedBy
    fascicolo {
      Id
      Name
    }
    user {
      Name
      Surname
    }
  }
}
`;
//#endregion

/******************************************************/
//#region Autovalutazione Rischio
/******************************************************/
export const query_autoValutazione_byTenant_count = `
query countAutovalutazioniByTenant($tenantId: String!, $aziendaId: Int!) {
  autovalutazione_aggregate(where: {TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}}) {
    aggregate {
      count(columns: Id)
    }
  }
}
`;
export const query_autoValutazione_byTenant = `
query getAutovalutazioniByTenant($tenantId: String!, $aziendaId: Int!) {
  autovalutazione(where: {TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}}, order_by: {Date: desc}) {
    Id
    TenantId
    AziendaId
    TenantTipoId
    Date
    Ri1
    Ri2
    Ri3
    Ri4
    Lv1
    Lv2
    Lv3
    Lv4
    RiTotale
    LvTotale
    RrTotale
    LivelloRischio
    ProvidedBy
    Note
    CreatedOn
    ModifiedOn
    ExpireDate
  }
}
`;
export const query_autoValutazione_byTenant_latest = `
query getAutovalutazioneByTenantLatest($tenantId: String!, $aziendaId: Int!) {
  autovalutazione(where: {TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}}, order_by: {Date: desc}, limit: 1) {
    Id
    TenantId
    AziendaId
    TenantTipoId
    Date
    Ri1
    Ri2
    Ri3
    Ri4
    Lv1
    Lv2
    Lv3
    Lv4
    RiTotale
    LvTotale
    RrTotale
    LivelloRischio
    ProvidedBy
    Note
    CreatedOn
    ModifiedOn
  }
}
`;
export const query_autoValutazione_byId = `
query getAutovalutazioneById($id: Int!) {
  autovalutazione_by_pk(Id: $id) {
    Id
    TenantId
    TenantTipoId
    Date
    Ri1
    Ri2
    Ri3
    Ri4
    Lv1
    Lv2
    Lv3
    Lv4
    RiTotale
    LvTotale
    RrTotale
    LivelloRischio
    ProvidedBy
    Note
    CreatedOn
    ModifiedOn
    ExpireDate
  }
}
`;
export const query_autoValutazione_byAzienda_count = `
query countAutovalutazioniByAzienda($tenantId: String!, $aziendaId: Int!) {
  autovalutazione_aggregate(where: {TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}}) {
    aggregate {
      count(columns: Id)
    }
  }
}
`;
export const query_autoValutazione_byExpireDate = `
query getAutovalutazioneByExpireDate($tenantId: String!, $aziendaId: Int!,$dtFrom: date!, $dtTo: date!){
  autovalutazione(where: {TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId},
  _and:[
      {ExpireDate: {_gte: $dtFrom}},
      {ExpireDate: {_lte: $dtTo}}
  ]}, order_by: {ExpireDate: desc}){
    Id
    TenantId
    AziendaId
    TenantTipoId
    Date
    Ri1
    Ri2
    Ri3
    Ri4
    Lv1
    Lv2
    Lv3
    Lv4
    RiTotale
    LvTotale
    RrTotale
    LivelloRischio
    ProvidedBy
    Note
    CreatedOn
    ModifiedOn
    ExpireDate
  }
}
`
//#endregion

/******************************************************/
//#region Autovalutazione Avvocato
/******************************************************/
export const query_autoValutazioneAvvocato_byId = `
query getAutovalutazioneById($id: Int!) {
  autovalutazione_avvocati_by_pk(Id: $id) {
    Id
    TenantId
    AziendaId
    AnalisiNMesi
    AreaGeografica
    IsAreaItalia
    AreaRischioId
    AreaClientiTipoId
    IsMotivoIdent
    ChkMotivoIdent1
    ChkMotivoIdent2
    ChkMotivoIdent3
    ChkMotivoIdent4
    ChkMotivoIdent5
    AttivitaTipicaId
    IsAttivitaContenziosa
    ClientiTipoId
    ClientiSettore
    IsClientiAreaSensibile
    IsClientiProvenienzaVaria
    ClientiProvenienzaId
    ClientiProvenienzaNote
    RisultatiId
    RischioProvvedimentoId
    RischioProvvedimentoNote
    ChkProcedure1
    ChkProcedure2
    ChkProcedure3
    ChkProcedure4
    ChkProcedure5
    ResponsabileId
    Date
    CreatedBy
    ModifiedBy
    CreatedOn
    ModifiedOn
    ProvdedBy
    ExpireDate
    OperateInRisk             
    SpecificCase            
    WhistleBlowingId           
    WhistleBlowingNotes 
    TerritoryRisk
  }
}
`;

export const query_autoValutazioneAvvocato_byTenant = `
query getAutovalutazioniAvvocatiByTenant($tenantId: String!, $aziendaId : Int!) {
  autovalutazione_avvocati(where: {TenantId: {_eq: $tenantId}, AziendaId: {_eq:$aziendaId}}) {
    Id
    TenantId
    AziendaId
    AnalisiNMesi
    AreaGeografica
    IsAreaItalia
    AreaRischioId
    AreaClientiTipoId
    IsMotivoIdent
    ChkMotivoIdent1
    ChkMotivoIdent2
    ChkMotivoIdent3
    ChkMotivoIdent4
    ChkMotivoIdent5
    AttivitaTipicaId
    IsAttivitaContenziosa
    ClientiTipoId
    ClientiSettore
    IsClientiAreaSensibile
    IsClientiProvenienzaVaria
    ClientiProvenienzaId
    ClientiProvenienzaNote
    RisultatiId
    RischioProvvedimentoId
    RischioProvvedimentoNote
    ChkProcedure1
    ChkProcedure2
    ChkProcedure3
    ChkProcedure4
    ChkProcedure5
    ResponsabileId
    Date
    CreatedBy
    ModifiedBy
    CreatedOn
    ModifiedOn
    ProvdedBy
    ExpireDate
    OperateInRisk             
    SpecificCase            
    WhistleBlowingId           
    WhistleBlowingNotes 
    TerritoryRisk
  }
}
`;

export const query_autoValutazioneAvvocato_byTenant_latest =`
query getAutovalutazioneAvvocatoByTenantLatest($tenantId : String!, $aziendaId : Int!){
  autovalutazione_avvocati(where: {TenantId: {_eq: $tenantId}, AziendaId: {_eq:$aziendaId}}, order_by: {CreatedOn: desc}, limit: 1){
    Id
    TenantId
    AziendaId
    Date
    CreatedBy
    ModifiedBy
    CreatedOn
    ModifiedOn
    ProvdedBy
  }
}
`
export const query_autoValutazioneAvv_byExpireDate = `
query getAutovalutazioneByExpireDate($tenantId: String!, $aziendaId: Int!,$dtFrom: date!, $dtTo: date!){
  autovalutazione_avvocati(where: {TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId},
  _and:[
      {ExpireDate: {_gte: $dtFrom}},
      {ExpireDate: {_lte: $dtTo}}
  ]}, order_by: {ExpireDate: desc}){
    Id
    TenantId
    AziendaId
    Date
    CreatedBy
    ModifiedBy
    CreatedOn
    ModifiedOn
    ProvdedBy
    ExpireDate
  }
}
`
/******************************************************/
//#region Allegati
/******************************************************/
export const query_allegato_byTenant_count = `
query countAllegatiByTenant($tenantId: String!) {
  allegato_aggregate(where: {TenantId: {_eq: $tenantId}}) {
    aggregate {
      count(columns: Id)
    }
  }
}
`;
export const query_allegato_byTenant = `
query getAllegatiByTenant($tenantId: String!) {
  allegato(where: {TenantId: {_eq: $tenantId}}, order_by: {CreatedOn: desc}) {
    Id
    EntityType
    EntityId
    FileName
    FileType
    FileUrl
    DisplayedName
    CreatedOn
    ModifiedOn
    CreatedBy
    ModifiedBy
  }
}
`;
export const query_allegato_byEntity = `
query getAllegatiByEntity($tenantId: String!, $entityType: String!, $entityId: Int!) {
  allegato(where: {TenantId: {_eq: $tenantId}, EntityType: {_eq: $entityType}, EntityId: {_eq: $entityId}}, order_by: {CreatedOn: desc}) {
    Id
    EntityType
    EntityId
    FileName
    FileType
    FileUrl
    DisplayedName
    CreatedOn
    ModifiedOn
    CreatedBy
    ModifiedBy
  }
}
`;
//#endregion

/******************************************************/
//#region Normativa
/******************************************************/
export const query_normativa = `
query getNormativa {
  normativa(order_by: {OrderN: asc}) {
    Id
    ParentId
    Title
    Subtitle
    Description
    TitoloN
    CapoN
    SezioneN
    ArticoloN
    OrderN
  }
}
`;
//#endregion

/******************************************************/
//#region Impostazioni
/******************************************************/
//Audit
export const query_audit = `
query getAudit($tenantId: String!, $aziendaId: Int!) {
  audit(where: { TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}}, order_by: {OperationDate: desc}) {
    Id
    TenantId
    UserId
    AziendaId
    Area
    Operation
    OperationDate
    EntityType
    EntityId
    OldValues
    NewValues
    user {
      Name
      Surname
    }
  }
}
`;

//Permessi
export const query_user_permission = `
query getUserPermission($userId: String!, $aziendaId: Int!, $area: String!) {
  user_permission(where: {UserId: {_eq: $userId}, AziendaId: {_eq: $aziendaId}, Area: {_eq: $area}}) {
    Id
    AziendaId
    Area
    CanRead
    CanCreate
    CanUpdate
    CanDelete
  }
}
`;
export const query_user_permission_byUserCount = `
query countUserPermissions($userId: String!, $aziendaId: Int!) {
  user_permission_aggregate(where: {UserId: {_eq: $userId}, AziendaId: {_eq: $aziendaId}}) {
    aggregate {
      count
    }
  }
}
`;
export const query_user_permission_byUser = `
query getUserPermissionByUser($userId: String!, $aziendaId: Int!) {
  user_permission(where: {UserId: {_eq: $userId}, AziendaId: {_eq: $aziendaId}}, order_by: {Id: asc}) {
    Id
    AziendaId
    Area
    CanRead
    CanCreate
    CanUpdate
    CanDelete
  }
}
`;
export const query_user_permission_hasTruePermsCount = `
query getUserPermissionHasTruePermsCount($userId: String!, $aziendaId: Int!) {
  user_permission_aggregate(where: {
    UserId: {_eq: $userId}, AziendaId: {_eq: $aziendaId}, 
    _or: [{CanRead: {_eq: true}}, {CanCreate: {_eq: true}}, {CanUpdate: {_eq: true}}, {CanDelete: {_eq: true}}]
  }
    ) {
      aggregate {
        count
      }
  }
}
`;

//User
export const query_users_byTenantId = `
query getUsersByTenantId($tenantId: String!) {
  user_tenant(where: {TenantId: {_eq: $tenantId}}, order_by: {user: {Username: asc}}) {
    UserId
    TenantId
    Type
    TenantTipoId
    base_tipo_tenant {
      Value
    }
    user {
      Username
      Email
      Name
      Surname
      Locale
      IsRespAntiRic
      IsActive
      IsDeleted
      IsBanned
      CreatedOn
      Cf
      LastVersion
      IsEnabledToCreateLawyers
      user_roles(where : {TenantId : {_eq:$tenantId}}){        
        Role
        DateStart
        DateEnd
      }
    }
  }
}
`;
export const query_users_byCompanyId = `
query getUsersByCompanyId($tenantId: String!, $aziendaId: Int!) {
  user_tenant(where: 
    {TenantId: {_eq: $tenantId},
    user: { user_permissions: {AziendaId: {_eq: $aziendaId}_or: [{CanRead: {_eq: true}}, {CanCreate: {_eq: true}}, {CanUpdate: {_eq: true}}, {CanDelete: {_eq: true}}]}}
  }, order_by: {user: {Username: asc}}) {
    UserId
    TenantId
    Type
    TenantTipoId
    base_tipo_tenant {
      Value
    }
    user {
      Username
      Email
      Name
      Surname
      Locale
      IsRespAntiRic
      IsActive
      IsDeleted
      IsBanned
      CreatedOn
      Cf
    }
  }
}
`;
export const query_user_byId = `
query getUserById($userId: String!) {
  user(where: {UserId: {_eq: $userId}}) {
    UserId
    Username
    Email
    Name
    Surname
    Locale
    IsRespAntiRic
    IsActive
    IsDeleted
    IsBanned
    CreatedOn
    Cf
    LastVersion
    IsEnabledToCreateLawyers
    user_tenants {
      Id
      UserId
      TenantId
      Type
      TenantTipoId
      base_tipo_tenant {
        Value
      }
    }
  }
}
`;
export const query_users_respAntiric = `
query getUsersRespAntiRic($tenantId: String!) {
  user_tenant(where: 
    {
      TenantId: {_eq: $tenantId}      
    }) {
    UserId
    TenantId
    Type
    TenantTipoId
    base_tipo_tenant {
      Value
    }
    user {
      Username
      Email
      Name
      Surname
      Locale
      IsRespAntiRic
      IsActive
      IsDeleted
      IsBanned
      CreatedOn
      Cf
      user_roles(where : {TenantId : {_eq:$tenantId}}){        
        Role
        DateStart
        DateEnd
      }
    }
  }
}
`;
export const query_releaseNotes = `
query getReleaseNotes {
  releasenotes {
    Id
    Version
    Date
    Pdf
  }
}
`;
//#endregion

/******************************************************/
//#region Notification
/******************************************************/
export const query_notification_byTenantId = `
query getNotificationsByTenantId($tenantId: String!, $aziendaId: Int!) {
  notification(where: { TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}}, order_by: {CreatedOn: desc}) {
    Id
    TenantId
    AziendaId
    UserId
    Type
    Payload
    IsRead
    ReadOn
    ReadByUser
    CreatedOn
  }
}
`;
export const query_notification_byTenantIdNotReaded = `
query getNotificationsByTenantId($tenantId: String!, $type: String!) {
  notification(where: { TenantId: {_eq: $tenantId}, Type: {_eq: $type}, IsRead: {_eq: false}}, order_by: {CreatedOn: desc}) {
    Id
    TenantId
    AziendaId
    UserId
    Type
    Payload
    IsRead
    ReadOn
    ReadByUser
    CreatedOn
  }
}
`;
//#endregion

/******************************************************/
//#region Stats
/******************************************************/
export const query_rischio_anagrafiche_stats = `
query getFascicoloByTenantCountryStats($tenantId: String!, $aziendaId: Int!, $userId: String!) {
  anagrafica(where: {
    TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}, IsDeleted:{_eq: false},
    anagrafica_fascicolos: {
      fascicolo: {
        _or: [
          {_or: [{IsPrivate: {_eq: false}}]},
          {_or: [
            {anagrafica_fascicolos: {anagrafica: {anagrafica_identificaziones: {IdentifiedByUser: {_eq: $userId}}}}}, 
            {prestaziones: {prestazione_user: {UserId: {_eq: $userId}}}}
          ]},
          {_or: [
            {anagrafica_fascicolos: {base_tipo_anagrafica: {Key: {_eq: "tipo_anagrafica_1"}}}},
            {anagrafica_fascicolos: {base_tipo_anagrafica: {Key: {_eq: "tipo_anagrafica_2"}}}}
          ]}
        ]
      }
    }
  }, order_by: [{Cf: asc}, {VersionN: desc}], distinct_on: Cf) {
    AddrPaeseId
    AddrProvinciaId
  }
}
`;
export const query_ppe_anagrafiche_stats = `
query getFascicoloByTenantCountryStats($tenantId: String!, $aziendaId: Int!, $userId: String!) {
  anagrafica(where: {
    TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}, IsDeleted: {_eq: false}, IsPg: {_eq: false},
    anagrafica_fascicolos: {
      fascicolo: {
        _or: [
          {_or: [{IsPrivate: {_eq: false}}]},
          {_or: [
            {anagrafica_fascicolos: {anagrafica: {anagrafica_identificaziones: {IdentifiedByUser: {_eq: $userId}}}}}, 
            {prestaziones: {prestazione_user: {UserId: {_eq: $userId}}}}
          ]}
        ]
      }
    }
  }, order_by: [{Cf: asc}, {VersionN: desc}], distinct_on: Cf) {
    IsPep
    IsNotPepMoreThanYear
  }
}
`;
export const query_prestazione_byTenant_Prest_stats = `
query getPrestByTenantPrestStats($tenantId: String!, $aziendaId: Int!, $userId: String!) {
  prestazione(where: {
    fascicolo: {
      TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId},
      _or: [
        {_or: [{IsPrivate: {_eq: false}}]},
        {_or: [
          {anagrafica_fascicolos: {anagrafica: {anagrafica_identificaziones: {IdentifiedByUser: {_eq: $userId}}}}}, 
          {prestaziones: {prestazione_user: {UserId: {_eq: $userId}}}}
        ]}
      ]
    }
  }, distinct_on: PrestazioneId) {
    base_tipo_prestazione {
      Rischio
    }
  }
}
`;
export const query_rischio_byTenant_stats = `
query getRischioByTenantStats($tenantId: String!, $aziendaId: Int!, $userId: String!) {
  rischio(where: {
    prestazione: {
      fascicolo: {
        TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId},
        _or: [
          {_or: [{IsPrivate: {_eq: false}}]},
          {_or: [
            {anagrafica_fascicolos: {anagrafica: {anagrafica_identificaziones: {IdentifiedByUser: {_eq: $userId}}}}}, 
            {prestaziones: {prestazione_user: {UserId: {_eq: $userId}}}}
          ]}
        ]
      }
    }
  }, order_by: [{Id: desc}, {PrestazioneId: asc}, {ProvidedBy: asc}], distinct_on: [Id, PrestazioneId, ProvidedBy]) {
    EfTotale
  }
}
`;
//#endregion

/******************************************************/
//#region Last activity
/******************************************************/
export const query_lastActivity_byUserId = `
query getLastActivityByUserId($tenantId: String!, $aziendaId: Int!, $userId: String!) {
  user_lastactivity(where: { TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}, UserId: {_eq: $userId}}) {
    Id
    TenantId
    AziendaId
    UserId
    Path
    EntityId
    EntityName
  }
}
`;
//#endregion

/******************************************************/
//#region Import Mapping
/******************************************************/
export const query_importMapping_byTenant = `
query getLastActivityByUserId($tenantId: String!, $aziendaId: Int!) {
  import_mapping(where: { TenantId: {_eq: $tenantId}, AziendaId: {_eq: $aziendaId}}) {
    Id
    TenantId
    AziendaId
    Type
    MappedValue
    Value
    CreatedOn
    ModifiedOn
    CreatedBy
    ModifiedBy
  }
}
`;
//#endregion

/******************************************************/
//#region System message
/******************************************************/
export const query_system_message = `
query getSystemMessage{
  system_message(limit:1){
    Id
    ShowMessage
    MessageAlert
    MessageVersion
    Version
  }
}
`

/******************************************************/
//#region User roles
/******************************************************/
export const get_user_roles_ByTenant = `
query getUserRolesByTenant($tenantId : String!,$role:String!){
  user_roles(where: { TenantId: {_eq: $tenantId}, Role: {_eq: $role}}){
    Id
    TenantId
    UserId
    Role
    DateStart
    DateEnd
    user {
      Username
      Email
      Name
      Surname
      Locale
      IsRespAntiRic
      IsActive
      IsDeleted
      IsBanned
      CreatedOn
      Cf
    }
  }
}
`

export const count_active_user_per_role =`
query CountUserRoles($role: String!,$tenantId:String!) {
  user_roles(where :{
    TenantId : {_eq:$tenantId},Role : {_eq:$role}, DateStart :{_lte:"TODAY"},
    _or : [{DateEnd : {_gt :"TODAY"}},{DateEnd :{_is_null:true}}]
  })
  { 
    Id
    TenantId
    UserId
    Role
    DateStart
    DateEnd
    user {
      Username
      Email
      Name
      Surname
      Locale
      IsRespAntiRic
      IsActive
      IsDeleted
      IsBanned
      CreatedOn
      Cf
  	}
  }
}
`