//#region Import
//Use
import { useNavigate } from "react-router-dom";
import { useSnackbar, useMutation, useTranslation, useQuery } from "@onefront/react-sdk";
import { useState,useEffect, Fragment } from "react";

//Vapor Compoonents
import VaporPage from "@vapor/react-custom/VaporPage";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";
import Grid from "@vapor/react-material/Grid";
import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";
import Checkbox from "@vapor/react-material/Checkbox";
import Stack from "@vapor/react-material/Stack";
import Button from "@vapor/react-material/Button";

//Custom components
import { Loading } from "../../components/Loading";
import { NotAuth } from "../../components/NotAuth";

//utilities
import { pathHome, pathExportDatiHome } from "../../businessLogic/constants";
import sharedDatas from "../../businessLogic/sharedDatas";
import { mutation_add_lastActivity_byUserId, mutation_add_log, mutation_delete_lastActivity } from "../../businessLogic/mutation";
import { getTabelle,getTabelleBase, getTabelleBaseExtra } from "../../businessLogic/bl";
import { FileDownload as DownloadIcon } from "@mui/icons-material";
import { query_allegato_byTenant, query_anagrafiche_byTenant, query_autoValutazione_byTenant, query_aziende_byTenant, query_base_comune, query_base_genere, query_base_mezzo_pagamento, query_base_natura_prestazione, query_base_natura_rapporto, query_base_origine_fondi, query_base_paese, query_base_provincia, query_base_scopo_prestazione, query_base_tipo_documento, query_base_tipo_identificazione, query_base_tipo_personalita, query_fascicolo_byTenant, query_indicatori_anomalia_byFascicolo, query_prestazioni_byFascicolo, query_prestazioni_byTenant, query_rischi_byTenant, query_sos_byTenant, query_tenant_byId, query_users_byTenantId } from "../../businessLogic/query";
//#endregion

export const ExportHome = () =>{

    //#region Constants
    const tenantId = sharedDatas.getPropertyByName("tenantId");
    const tenantModel = sharedDatas.getPropertyByName("tenantModel");
    const aziendaId = sharedDatas.getPropertyByName("aziendaId");
    const loggedUserId = sharedDatas.getPropertyByName("userId");
    const aziendaModelList = sharedDatas.getPropertyByName("aziendaModelList"); 
    const userModel = sharedDatas.getPropertyByName("userModel");
    const version = sharedDatas.getPropertyByName("version");
    const env = sharedDatas.getPropertyByName("env");
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    //#endregion

    //#region Use states
    const [alltabelleList, setAllTabelleList] = useState([])//contiene la lista delle tabelle utilizzate a DB    
    const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
    const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api
    const [isExportAllChecked,setIsExportAllChecked] = useState(false)
    const [, setIsTenantEnabled] = useState(false);
    const [toExportTables,setToExportTable] = useState([])
    const [isUserOwner] = useState(
    userModel !== null
        ? userModel.type === "OWNER"
        : false
    );    
    //#endregion            

    //#region Mutation
    const apiAddLastActivity = useMutation(mutation_add_lastActivity_byUserId, {
        graphqlEndpoint: "aml:hasura:api://v1/graphql",
    });
    const apiDeleteLastActivity = useMutation(mutation_delete_lastActivity, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
    });
    const apiAddLog = useMutation(mutation_add_log, {
        graphqlEndpoint: "aml:hasura:api://v1/graphql",
    });
    //#endregion

    //#region Query by tenant
    const baseGenereByTenant = useQuery(query_base_genere,
        {},
        { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );
    const baseMezzoPagamentoByTenant = useQuery(query_base_mezzo_pagamento,
        {},
        { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );
    const baseNaturaPrestByTenant = useQuery(query_base_natura_prestazione,
        {},
        { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );
    const baseNaturaRapptByTenant = useQuery(query_base_natura_rapporto,
        {},
        { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );
    const baseOrigineFondiByTenant = useQuery(query_base_origine_fondi,
        {},
        { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );
    const baseScopoPrestByTenant = useQuery(query_base_scopo_prestazione,
        {},
        { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );
    const baseTipoDocByTenant = useQuery(query_base_tipo_documento,
        {},
        { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );
    const baseTipoIdentByTenant = useQuery(query_base_tipo_identificazione,
        {},
        { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );
    const baseTipoPersByTenant = useQuery(query_base_tipo_personalita,
        {},
        { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );
    const allegatoByTenant = useQuery(query_allegato_byTenant,
        {},
        { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );
    const anagraficaByTenant = useQuery(query_anagrafiche_byTenant,
        {},
        { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );
    const autovalutazioneByTenant = useQuery(query_autoValutazione_byTenant,
        {},
        { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );
    const aziendaByTenant = useQuery(query_aziende_byTenant,
        {},
        { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );
    const fascicoloByTenant = useQuery(query_fascicolo_byTenant,
        {},
        { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );
    const prestazioneByTenant = useQuery(query_prestazioni_byTenant,
        {},
        { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );
    const sosByTenant = useQuery(query_sos_byTenant,
        {},
        {lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );
    const tenantById = useQuery(query_tenant_byId,
        {},
        {lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );
    const userByTenant = useQuery(query_users_byTenantId, 
        {},
        {lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );
    const rischiByTenant = useQuery(query_rischi_byTenant, 
        {},
        {lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );
    //#endregion

    //#region Query by fascicolo
    const prestByFascicolo = useQuery(query_prestazioni_byFascicolo, 
        {},
        {lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );
    const indAnomaliaByFascicolo = useQuery(query_indicatori_anomalia_byFascicolo, 
        {},
        {lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );    
    //#endregion

    //#region Query
    const basePaesi = useQuery(query_base_paese, 
        {},
        {lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );    
    const baseProvince = useQuery(query_base_provincia, 
        {},
        {lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );    
    const baseComuni = useQuery(query_base_comune, 
        {},
        {lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    );    
    //#endregion
    useEffect(() => {
        loadDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDataLoaded]);

    if (
        sharedDatas.getPropertyByName("userId") === "" ||
        sharedDatas.getPropertyByName("tenantId") === "" ||
        sharedDatas.getPropertyByName("aziendaId") === 0
    ) {
        navigate(pathHome);
    }

    const AddLog = async (message,stack,method) => {
        if(env === "production"){
            apiAddLog.fetch({
            tenantId: tenantId,
                aziendaId: aziendaId,
                level: "ERROR",
                message: message,
                stack: stack,
                area: "ExportHome",
                method: method,
                version: version,
            })
        }
    }

    //#region Load
    async function loadDatas(){
        try{
            if (
                tenantId === "" ||
                tenantModel === null ||
                aziendaId === 0 ||
                aziendaModelList === null ||
                loggedUserId === "" ||
                userModel === null
            ) {
                navigate("/");
            } else {
                setBackDropOpen(true); 
                
                if (!isDataLoaded) {
                    //Last Activity
                    await apiDeleteLastActivity.fetch({
                        tenantId: tenantId,
                        aziendaId: aziendaId,
                        userId: loggedUserId,
                    });
                    await apiAddLastActivity.fetch({
                        tenantId: tenantId,
                        aziendaId: aziendaId,
                        path: pathExportDatiHome,
                        entityId: "",
                        entityName: "",
                    });

                    /**************************************/
                    //Carico le informazioni relative al Tenant                    
                    const isEnabled =
                        !tenantModel.isReadOnly &&
                        !tenantModel.isDisabled &&
                        !tenantModel.isDeleted;
                    setIsTenantEnabled(isEnabled);                    

                    /**************************************/
                    //Carico le Tabelle
                    let tables = getTabelle().map(l=> {
                        l.checked = false
                        l.disabled = false
                        return l
                    });   

                    let tabBase = getTabelleBase().map(l=> {
                        l.checked = false
                        l.disabled = false
                        return l
                    }); 
                    
                    let tabBaseExtra = getTabelleBaseExtra().map(l=>{
                        l.checked = false
                        l.disabled = l.id === "base_comune"
                        return l
                    })

                    let allTables = tables.concat(tabBaseExtra,tabBase)                                             
                    setAllTabelleList(allTables)
                }       
            }        
        } catch (e) {
            AddLog(e.message,e.stack,"loadDatas")            
            enqueueSnackbar(t("error.generale"), { variant: "error" });
        } finally {
            setBackDropOpen(false);
            setIsDataLoaded(true);
        }
    }
    //#endregion

    //#region Handler
    const  handleCheck = async (event,item) => {
        const { id } = event.target;

        if(id === "tabSelected"){            
            //Aggiungo la tabella a quelle da esportare se il check è attivato, altrimenti la elimino dalle tabelle da esportare
            let newTablesToExport = toExportTables
            if(event.target.checked){                
                newTablesToExport.push(item)                
            } else {
                newTablesToExport = newTablesToExport.filter(x=> x!== item)                
            }                                    
            setToExportTable(newTablesToExport)
            
            //Abilito o disabilito la spunta dell'item passato
            let allTables = alltabelleList            
            let indexItemToCheck = allTables.findIndex(x=> x === item)
            let itemToCheck = allTables.find(x=> x === item)
            allTables = allTables.filter(x=> x !== itemToCheck)
            itemToCheck.checked = event.target.checked
            allTables.splice(indexItemToCheck,0,itemToCheck)
            
            //Se l'item è fascicolo disabilito quelli che sono già contenuti nell'export del fascicolo    
            if(item.id === "fascicolo"){
                let indexPrest = allTables.findIndex(x=> x.id === "prestazione")
                let indexRischi = allTables.findIndex(x=> x.id === "rischio")
                let indexIndAnomalia = allTables.findIndex(x=> x.id === "indicatore_anomalia")
                let indexAnag = allTables.findIndex(x=> x.id === "anagrafica")

                allTables[indexPrest].disabled = event.target.checked
                allTables[indexRischi].disabled = event.target.checked
                allTables[indexIndAnomalia].disabled = event.target.checked
                allTables[indexAnag].disabled = event.target.checked
            }            
            if(item.id === "base_provincia"){
                let indexComuni = allTables.findIndex(x=>x.id === "base_comune")
                allTables[indexComuni].disabled = !event.target.checked
                if(!event.target.checked) 
                    allTables[indexComuni].checked = false 
            }            
            setAllTabelleList(allTables)
            
        } else if (id === "tabSelectedAll"){
            //Abilito la spunta su esporta tutto
            setIsExportAllChecked(event.target.checked)
            //Abilito tutte le altre spunte
            alltabelleList.forEach(table => {
                table.checked = event.target.checked
                table.disabled = table.id === "base_comune" ? true : false
            });            
            setAllTabelleList(alltabelleList)

            if(event.target.checked)
                setToExportTable(alltabelleList)
            else
                setToExportTable([])
        }
    }

    const hanldeExport = async () => {
        setBackDropOpen(true)
        enqueueSnackbar(
            "L'operazione potrà richiedere qualche minuto, ti consigliamo di non chiudere il browser.",
            { variant: "warning" }
        );
        await exportTable(toExportTables)
        setBackDropOpen(false)
    }
    //#endregion

    //#region Methods
    const exportTable = async (tablesToExport)=> {
        //Inizializzo gli array per avere tutti i dati sempre disponibili
        let base_genere = []        
        let base_mezzo_pagamento = []        
        let base_natura_prestazione = []
        let base_natura_rapporto = []
        let base_origini_fondi = []
        let base_scopo_prestazione = []
        let base_tipo_documento = []
        let base_tipo_identificazione = []
        let base_tipo_personalita = []
        let allegati = []
        let anagrafiche = []
        let autovalutazioni = []
        let aziende = []
        let fascicoli = []        
        let prestazioni = []
        let rischi = []
        let sos = []
        let tenant = []
        let user = []
        let nazioni = []
        let province = []
        let comuni = []
        let datasToExport = []
        //Recupero tutti i dati secondo le selezioni dell'utente 
        //Se ha selezionato di esportare i fascicoli raccolgo tutto lì dentro
        //Altrimenti prendo i vari dati per tenant
        let isFascicoloSelected = tablesToExport.filter(x=> x.id === "fascicolo").length > 0
        if(isFascicoloSelected){
            let fascicoloExport = null
            let respFascicolo = await fascicoloByTenant.fetch({
                tenantId : tenantId,
                aziendaId : aziendaId,
                userId : loggedUserId
            })
            fascicoli = respFascicolo.data.data.fascicolo.filter(x=> x.AziendaId === aziendaId)            
            //Recupero le prestazioni e rischi dal fascicolo e anche gli indicatori di anomalia
            for (let index = 0; index < fascicoli.length; index++) {
                const fascicolo = fascicoli[index];
                const prestResp = await prestByFascicolo.fetch({
                    fascicoloId : fascicolo.Id
                })
                const indAnomaliaResp = await indAnomaliaByFascicolo.fetch({
                    fascicoloId : fascicolo.Id
                })        
                fascicolo.prestaziones = []  
                prestResp.data.data.prestazione.forEach(prestazione => {
                    fascicolo.prestaziones.push(prestazione)
                });      
                //fascicolo.prestaziones.push({"prestazioni" :prestResp.data.data.prestazione})                                
                fascicolo.indicatore_anomalia = []
                indAnomaliaResp.data.data.indicatore_anomalia.forEach(indAnomalia =>{
                    fascicolo.indicatore_anomalia.push(indAnomalia)
                })
                //fascicolo.indicatore_anomalia.push(indAnomaliaResp.data.data.indicatore_anomalia)
            }
            fascicoloExport = fascicoli
            datasToExport.push({"fascicoli" : fascicoloExport})            
        } 
        
        //Esporto quello che ho selezionato escluso il fascicolo e quello che già contiene
        for (let i = 0; i < tablesToExport.length; i++) {
            const table = tablesToExport[i]
            try {
                switch(table.id){
                    case "base_genere":                        
                        let respBaseGen = await baseGenereByTenant.fetch({
                            tenantId : tenantId
                        })
                        base_genere = respBaseGen.data.data.base_genere 
                        datasToExport.push({"base_genere" : base_genere})                       
                        break;
                    case "base_mezzo_pagamento":
                        let respBasePag = await baseMezzoPagamentoByTenant.fetch({
                            tenantId : tenantId
                        })
                        base_mezzo_pagamento = respBasePag.data.data.base_mezzo_pagamento   
                        datasToExport.push({"base_mezzo_pagamento" : base_mezzo_pagamento})                                            
                        break;
                    case "base_natura_prestazione":
                        let respBaseNatPrest = await baseNaturaPrestByTenant.fetch({
                            tenantId : tenantId
                        })
                        base_natura_prestazione = respBaseNatPrest.data.data.base_natura_prestazione 
                        datasToExport.push({"base_natura_prestazione":base_natura_prestazione})                                              
                        break;
                    case "base_natura_rapporto":
                        let respBaseNatRapp = await baseNaturaRapptByTenant.fetch({
                            tenantId : tenantId
                        })
                        base_natura_rapporto = respBaseNatRapp.data.data.base_natura_rapporto   
                        datasToExport.push({"base_natura_rapporto":base_natura_rapporto})                     
                        break;
                    case "base_origine_fondi":
                        let respBaseOrigFondi = await baseOrigineFondiByTenant.fetch({
                            tenantId : tenantId
                        })
                        base_origini_fondi = respBaseOrigFondi.data.data.base_origine_fondi        
                        datasToExport.push({"base_origini_fondi" : base_origini_fondi})                
                        break;
                    case "base_scopo_prestazione":
                        let respBaseScopoPrest = await baseScopoPrestByTenant.fetch({
                            tenantId : tenantId
                        })
                        base_scopo_prestazione = respBaseScopoPrest.data.data.base_scopo_prestazione 
                        datasToExport.push({"base_scopo_prestazione" : base_scopo_prestazione})                       
                        break;
                    case "base_tipo_documento":
                        let respBaseTipoDoc = await baseTipoDocByTenant.fetch({
                            tenantId : tenantId
                        })
                        base_tipo_documento = respBaseTipoDoc.data.data.base_tipo_documento 
                        datasToExport.push({"base_tipo_documento":base_tipo_documento})                       
                        break;
                    case "base_tipo_identificazione":
                        let respBaseTipoIdent = await baseTipoIdentByTenant.fetch({
                            tenantId : tenantId
                        })
                        base_tipo_identificazione = respBaseTipoIdent.data.data.base_tipo_identificazione 
                        datasToExport.push({"base_tipo_identificazione":base_tipo_identificazione})                       
                        break;
                    case "base_tipo_personalita":
                        let respBaseTipoPers = await baseTipoPersByTenant.fetch({
                            tenantId : tenantId
                        })
                        base_tipo_personalita = respBaseTipoPers.data.data.base_tipo_personalita 
                        datasToExport.push({"base_tipo_personalita":base_tipo_personalita})                       
                        break;
                    case "allegato":
                        let respAllegato = await allegatoByTenant.fetch({
                            tenantId : tenantId
                        })
                        allegati = respAllegato.data.data.allegato 
                        datasToExport.push({"allegati":allegati})                       
                        break;
                    case "anagrafica":
                        if(!isFascicoloSelected){
                            let respAnagrafica = await anagraficaByTenant.fetch({
                                tenantId : tenantId,
                                aziendaId: aziendaId,
                                userId: loggedUserId
                            })
                            anagrafiche = respAnagrafica.data.data.anagrafica
                            datasToExport.push({"anagrafiche":anagrafiche})                    
                        } 
                        break;
                    case "autovalutazione":
                        let respAutoval = await autovalutazioneByTenant.fetch({
                            tenantId : tenantId,
                            aziendaId : aziendaId
                        })
                        autovalutazioni = respAutoval.data.data.autovalutazione    
                        datasToExport.push({"autovalutazioni":autovalutazioni})                    
                        break;
                    case "azienda":
                        let respAzienda = await aziendaByTenant.fetch({
                            tenantId : tenantId
                        })
                        aziende = respAzienda.data.data.azienda.filter(x=> x.Id === aziendaId)        
                        datasToExport.push({"aziende":aziende})                
                        break;                                     
                        case "prestazione":
                        if(!isFascicoloSelected){
                            let respPrestazione = await prestazioneByTenant.fetch({
                                tenantId : tenantId,
                                aziendaId : aziendaId,
                                userId : loggedUserId
                            })
                            prestazioni = respPrestazione.data.data.prestazione
                            datasToExport.push({"prestazioni":prestazioni})
                        }
                        break;
                    case "rischio":
                        if(!isFascicoloSelected){
                            let respRischi = await rischiByTenant.fetch({
                                teneantId : tenantId,
                                aziendaId : aziendaId,
                                userId : loggedUserId
                            })
                            rischi = respRischi.data.data.rischio
                            datasToExport.push({"rischi":rischi})
                        }
                        break;
                    case "sos": 
                        let respSos = await sosByTenant.fetch({
                            tenantId : tenantId,
                            aziendaId : aziendaId,
                            userId : loggedUserId
                        })
                        sos = respSos.data.data.sos
                        datasToExport.push({"sos":sos})
                        break;                 
                    case "tenant": 
                        let respTenant = await tenantById.fetch({
                            tenantId : tenantId
                        })
                        tenant = respTenant.data.data.tenant 
                        datasToExport.push({"tenant":tenant})                       
                        break;
                    case "user":                        
                        let respUser = await userByTenant.fetch({
                            tenantId : tenantId
                        })
                        user = respUser.data.data.user_tenant    
                        datasToExport.push({"user": user})                    
                        break;
                    case "base_paese":
                        let respPaesi = await basePaesi.fetch()
                        nazioni = respPaesi.data.data.base_paese
                        datasToExport.push({"nazioni" : nazioni})
                        break;
                    case "base_provincia":
                        let respProvince = await baseProvince.fetch()
                        province = respProvince.data.data.base_provincia
                        datasToExport.push({"province" : province})
                        break;
                    case "base_comune":
                        for (let i = 0; i < province.length; i++) {
                            const provincia = province[i];
                            let respComuni = await baseComuni.fetch({
                                provincia : provincia.Sigla
                            })
                            comuni.push(respComuni.data.data.base_comune)                            
                        }                                                
                        datasToExport.push({"comuni" : comuni})
                        break;
                    default :
                        break; 
                }                
            } catch (e) {
                AddLog(`Error ${e.message} on table id ${table.id}`,e.stack,"exportTable")                
                enqueueSnackbar(t("error.generale"), { variant: "error" });
            }
        }                
        //Esportazione e download del file JSON
        createAndDownloadJSON(datasToExport)
    }

    const createAndDownloadJSON = (datasToExport) =>{
        const jsonData = new Blob([JSON.stringify(datasToExport)], { type: 'application/json' });
        const jsonURL = URL.createObjectURL(jsonData);
        const link = document.createElement('a');
        link.href = jsonURL;
        link.download = `export.json`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }
    //#endregion

    //#region Returning grapichs
    return (
        <Fragment>
            <VaporPage
                title="Export Dati"            
            >       

                <Loading open={backDropOpen} />

                {isDataLoaded && !isUserOwner ? <NotAuth /> : null}

                {isDataLoaded && isUserOwner ? (
                    <VaporPage.Section>
                        <Grid container sx={{width: "100%"}}>
                            <Grid item xs={9}>
                                <ExtendedTypography variant="titleSmall">
                                    Seleziona le tabelle da esportare :
                                </ExtendedTypography>
                            </Grid>

                            <Grid item xs={3}>
                                <Stack direction="row" alignItems="center">                                            
                                    <Checkbox
                                        id="tabSelectedAll"
                                        checked={isExportAllChecked}
                                        onChange={(evt) => handleCheck(evt)}
                                    />
                                    <ExtendedTypography variant="titleSmall">
                                        Seleziona tutti
                                    </ExtendedTypography>
                                </Stack>    
                            </Grid>
                        </Grid>
                        <Grid container sx={{ width: "100%", mt: 3 }}>
                            {alltabelleList.length > 0 &&
                                alltabelleList.map((tabella) =>(
                                    <Grid item xs={3} sx={{ pr: 2 }} >
                                            <Stack direction="row" alignItems="center">                                            
                                                <Checkbox
                                                    id="tabSelected"
                                                    checked={tabella.checked}
                                                    onChange={(evt) => handleCheck(evt,tabella)}
                                                    disabled={tabella.disabled}
                                                />
                                                <ExtendedTypography variant="titleSmall">
                                                    {tabella.name}
                                                </ExtendedTypography>
                                            </Stack>                                    
                                    </Grid>
                                ))
                            }                        
                        </Grid> 
                    </VaporPage.Section>
                ): null}
            </VaporPage> 

            {isUserOwner &&
                <VaporToolbar
                    variant="regular"
                    size="medium"
                    withoutAppBar={false}
                    contentRight={
                        <Button
                            variant="contained"
                            size="medium"
                            endIcon={<DownloadIcon />}
                            onClick={hanldeExport}
                            disabled={toExportTables.length === 0}
                        >
                            Esporta        
                        </Button>
                    }
                />
            }       
        </Fragment>
    )
    //#endregion
}