import React from "react";
import { DataGridPro,GridToolbar,itIT } from '@mui/x-data-grid-pro';
import Paper from '@mui/material/Paper';
import { useTranslation } from '@onefront/react-sdk';
import { chipGreen, chipRed } from "../../businessLogic/styles";
import VaporTag from "@vapor/react-custom/VaporTag";

export const PrestazioniList = ({prestListFromParent,utentiFromParentList,onSelection}) => {

    const prestList = prestListFromParent
    const profList = utentiFromParentList
    const { t } = useTranslation();    
    const columns = [        
        { 
            field: 'descrizione', 
            headerName : 'Descrizione', 
            flex: 0.5,            
            valueFormatter : (params) => {
                if(params.value === "")
                    return "{Nessuna Descrizione}"
                return params.value
            }                                
        },
        { field:'prestazioneNome', headerName: 'Prestazione', flex:0.5},       
        { 
            field:'dataStart',
            headerName: t("autovalutazione.form.data"),
            type: "date",    
            flex:0.2,       
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return new Date(params.value).toLocaleDateString();
            }
        },        
        { 
            field: 'professionisti', 
            headerName: 'Professionista',   
            flex:0.2,           
            valueFormatter : (params) => {
                if(params.value == null){
                    return "";
                }
                let nameToReutrn = profList.filter(
                    (x) => x.userId === params.value[0].UserId
                )[0].completeName
                return nameToReutrn
            }
        },
        { 
            field:'rischi',
            headerName:'Valutazione del rischio',
            flex:0.3,
            renderCell: (params) => renderColorCell(params.row),
            valueGetter : (params) => renderColorCellGetter(params.row)
        }
    ]

    const handleRowSelection = (event) => {                  
        onSelection(event)
    }

    //#region Render
    const renderColorCell = (item)=>{
        if(item.rischi.length > 0){
            return <VaporTag variant='standard' label='Presente' sx={chipGreen} />;
        } else {
            return <VaporTag variant='standard' label='Assente' sx={chipRed} />;
        }
    }
    const renderColorCellGetter = (item) => {
        if(item.rischi.length > 0){
            return "Presente"
        } else {
            return "Assente"
        }
    }
    //#endregion

    const paginationModel = { page: 0, pageSize: 5 };
    return (
        <Paper sx={{height : 400 , width : '100%'}}>
            <DataGridPro
                rows={prestList}
                columns={columns}
                initialState={{pagination : paginationModel}}
                slots={{ toolbar: GridToolbar }}
                localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                }
                pageSizeOptions={[5,10]}
                isRowSelectable={item=> item.row.rischi.length > 0}
                checkboxSelection                
                onRowSelectionModelChange={handleRowSelection}
                sx={{border: 0}}
                slotProps={{
                    toolbar: { printOptions: { disableToolbarButton: true } },
                }}                
            />            
        </Paper>
    )
}