import React, { Fragment } from "react";
import Stack from "@vapor/react-material/Stack";
import Box from "@vapor/react-material/Box";
import Button from "@vapor/react-material/Button";
import Modal from "@vapor/react-material/Modal";
import Divider from "@vapor/react-material/Divider";
import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";

//Style
import { modalStyle } from "../businessLogic/styles";

export const ModalErrorVersion = ({ show, messageFromParent, onOk }) => {
    const handleModalOk = (e) => {
        onOk(e);
    };

    return (
        <Fragment>
            <Modal
                key="confirmSaveModal"
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                open={show}
            >
                <Box sx={modalStyle}>
                <Stack
                    direction="column"
                    spacing={0}
                    justifyContent="left"
                    alignItems="left"
                >
                    <ExtendedTypography
                    variant="titleSmall"
                    color="primary.main"
                    sx={{ p: 2, pl: 3 }}
                    >
                        AVVISO!
                    </ExtendedTypography>
                    <Divider orientation="horizontal" flexItem />
                    <ExtendedTypography variant="subtitle2" sx={{ p: 2, pl: 3, pt: 3 }}>
                        {messageFromParent.MessageVersion}
                    </ExtendedTypography>
                </Stack>
                <Stack
                    direction="row"
                    spacing={1}
                    sx={{ p: 1, mt: 1, bgcolor: "#F2F5F8" }}
                    justifyContent="right"
                    alignItems="right"
                >                    
                    <Button variant="contained" size="small" onClick={handleModalOk}>
                        Ok
                    </Button>
                </Stack>
                </Box>
            </Modal>
        </Fragment>
    );
};
