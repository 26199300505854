//#region Import
import React, { useState, useEffect } from "react";
import sharedDatas from "../../businessLogic/sharedDatas";
import { useNavigate } from "react-router-dom";

//oneFront SDK
import {
  useSnackbar,
  useAuth,
  useQuery,
  useMutation,
  usePost,
  useTranslation,
} from "@onefront/react-sdk";

import VaporPage from "@vapor/react-custom/VaporPage";
import VaporTag from "@vapor/react-custom/VaporTag";

//Vapor Components

import Grid from "@vapor/react-material/Grid";
import Stack from "@vapor/react-material/Stack";
import IconButton from "@vapor/react-material/IconButton";

import { ExtendedTabs, ExtendedTab } from "@vapor/react-extended";

import { DataGridPro, GridToolbar, itIT } from "@mui/x-data-grid-pro";

//Icons
import { ArrowForward as DetailIcon } from "@mui/icons-material";

//Custom styles
import {
  chipGreen,
  chipYellow,
  chipOrange,
  chipRed,
} from "../../businessLogic/styles";

//Custom components
import { Loading } from "../../components/Loading";
import { NotAuth } from "../../components/NotAuth";
import { NoData } from "../../components/NoData";

//Constants, Api and Data Models
import {
  bsaAnagrafica,
  pathAnagraficaHome,
  pathHome,
} from "../../businessLogic/constants";
import {
  setLocalStorageSelectedFascicolo,
  setLocalStorageSelectedEntity,
  isGuid,  
  isAlphaNumeric
} from "../../businessLogic/bl";
import {
  userPermissionModel,
  anagraficaModel,
  basePaeseModel,
  baseProvinciaModel,
} from "../../businessLogic/models";
import {
  query_anagrafiche_byTenant,
  query_base_paese,
  query_base_provincia,
} from "../../businessLogic/query";
import {
  mutation_add_log,
  mutation_add_lastActivity_byUserId,
  mutation_delete_lastActivity,
  mutation_update_anagrafica_wsId,
} from "../../businessLogic/mutation";

//Other components
import { v4 as uuidv4 } from "uuid";
//#endregion

export const AnagraficaHome = ({ basePath }) => {
  
  //#region Constants
  const tenantId = sharedDatas.getPropertyByName("tenantId");
  const aziendaId = sharedDatas.getPropertyByName("aziendaId");
  const loggedUserId = sharedDatas.getPropertyByName("userId");
  const version = sharedDatas.getPropertyByName("version");
  const env = sharedDatas.getPropertyByName("env");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  if (
    sharedDatas.getPropertyByName("userId") === "" ||
    sharedDatas.getPropertyByName("tenantId") === ""
  ) {
    navigate(pathHome);
  }
  const tsApiWorkspaceRead = usePost("aml:workspace-read:api://search", {
    lazy: true,
  });
  //#endregion

  //#region Query
  const apiBasePaese = useQuery(
    query_base_paese,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseProvincia = useQuery(
    query_base_provincia,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAnagraficheByTenant = useQuery(
    query_anagrafiche_byTenant,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  //#endregion

  //#region Mutation
  const apiAddLog = useMutation(mutation_add_log, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddLastActivity = useMutation(mutation_add_lastActivity_byUserId, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeleteLastActivity = useMutation(mutation_delete_lastActivity, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUpdateWsId =useMutation(mutation_update_anagrafica_wsId, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  //#endregion

  //#region UseStates
  const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
  const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api
  const [userPermissions, setUserPermissions] = useState(
    new userPermissionModel(
      0,
      aziendaId,
      bsaAnagrafica,
      false,
      false,
      false,
      false
    )
  ); //Contiene i permessi utente
  const [, setAnagraficheList] = useState([]); //Contiene le anagrafiche
  //DataGrid
  const [, setDataItems] = useState([]); //Contiene i records
  const [dataColumns, setDataColumns] = useState([]); //Contiene le columns
  const [dataRows, setDataRows] = useState([]); //Contiene le rows
  const [, setFilteredRows] = useState([]);
  const [, setDataItems2] = useState([]); //Contiene i records
  const [dataColumns2, setDataColumns2] = useState([]); //Contiene le columns della DataGrid
  const [dataRows2, setDataRows2] = useState([]); //Contiene le rows della DataGrid
  //TS Digital Api
  const [accessToken] = useState(useAuth().token);
  const tsApiWorkspace = usePost("aml:workspace-read:api://search", { lazy: true });
  const tsApiAddAnagOnWs = usePost("aml:workspace-write:api://",
    {},
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        "X-App-Name": "TS420",
        "X-App-Version": "1.0.0",
        "X-Request-ID": uuidv4(),
        "X-Correlation-ID": uuidv4(),
        "Content-Type": "application/json",
        "X-Item-ID": tenantId,
        "X-User-ID": loggedUserId,
      },
    }
  );
  //Tabs
  const [selectedTab, setSelectedTab] = useState("tabAnagrafiche");
  const handleChangeTab = (event, newValue) => {
    setSelectedTab(newValue);
  };
  //#endregion

  useEffect(() => {
    loadDatas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoaded]);

//#region Logs
const AddLog = async (message,stack,method) => {
  if(env === "production"){
    apiAddLog.fetch({
      tenantId: tenantId,
        aziendaId: aziendaId,
        level: "ERROR",
        message: message,
        stack: stack,
        area: "AnagraficaHome",
        method: method,
        version: version,
    })
  }
}
//#endregion

  //#region  Methods
  async function loadDatas() {
    try {
      if (
        sharedDatas.getPropertyByName("tenantId") === "" ||
        sharedDatas.getPropertyByName("tenantModel") === null ||
        sharedDatas.getPropertyByName("aziendaId") === 0 ||
        sharedDatas.getPropertyByName("aziendaModelList") === null ||
        sharedDatas.getPropertyByName("userId") === "" ||
        sharedDatas.getPropertyByName("userModel") === null
      ) {
        navigate("/");
      } else {
        setBackDropOpen(true);

        if (!isDataLoaded && loggedUserId !== "" && tenantId !== "") {
          //Last Activity
          await apiDeleteLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            userId: loggedUserId,
          });
          await apiAddLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            path: pathAnagraficaHome,
            entityId: "",
            entityName: "",
          });

          /**************************************/
          //Carico i permessi relativi all'utente
          const userPerms = sharedDatas
            .getPropertyByName("userPermissions")
            .filter((x) => x.area === bsaAnagrafica)[0];
          setUserPermissions(userPerms);

          //Recupero le countries
          const tbPaeseResponse = await apiBasePaese.fetch();
          let arrPaeseItems = [];
          tbPaeseResponse.data.data.base_paese.map((item) =>
            arrPaeseItems.push(
              new basePaeseModel(
                item.Id,
                item.Nome,
                item.Identificativo,
                item.IsRischio
              )
            )
          );

          //Recupero le province
          const tbProvinceResponse = await apiBaseProvincia.fetch();
          let arrProvinceItems = [];
          tbProvinceResponse.data.data.base_provincia.map((item) =>
            arrProvinceItems.push(
              new baseProvinciaModel(
                item.Id,
                item.Nome,
                item.Sigla,
                item.Rischio
              )
            )
          );

          /**************************************/
          //Carico le anagrafiche
          setDataColumns([
            { field: "id", headerName: "Id", flex: 0.2, type: "number" },
            {
              field : "denominazione",
              headerName: "Denominazione",
              flex: 0.5,
              filterable: true,
              sortable: true,
              pinnable: true,
              disableColumnMenu: false,
              hideDescendantCount: true,
            },
            {
              field: "cf",
              headerName: "Codice Fiscale",
              flex: 0.3,
              editable: false,
              filterable: true,
            },
            { field: "pIva", headerName: "P. Iva", flex: 0.3, editable: false,filterable: true, },
            {
              field: "identificato",
              headerName: "Identificato",
              type: "boolean",
              flex: 0.3,
              editable: false,
              filterable: true,
            },
            { field: "ppe", headerName: "PPE", flex: 0.5, editable: false,filterable: true, },
            {
              field: "rischioAreaGeo",
              headerName: "Rischio area geografica anagrafica",
              flex: 0.5,
              editable: false,
              renderCell: (params) => renderLivelloRischio(params.row),
              valueGetter: (params) => renderLivelloRischioS(params.row),
            },
            { field: "ruolo", headerName: "Ruolo", flex: 0.4, editable: false,filterable: true,},
            {
              field: "data",
              headerName: "Data",
              type: "date",
              flex: 0.3,
              editable: false,
              filterable: true,
              valueFormatter: (params) => {
                if (params.value == null) {
                  return "";
                }
                return new Date(params.value).toLocaleDateString();
              },
            },
            {
              field: "actions",
              headerName: "",
              flex: 0.2,
              filterable: false,
              renderCell: (params) =>
                renderDetailsButton(userPerms, params.row),
            },
          ]);
          const anagResponse = await apiAnagraficheByTenant.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            userId: loggedUserId,
          });
          const arrItems = [];

          for (let i = 0; i < anagResponse.data.data.anagrafica.length; i++) {
            let item = anagResponse.data.data.anagrafica[i];

            if (item.anagrafica_fascicolos.length > 0) {
              //Recupero l'ultima versione
              const lastVersion = anagResponse.data.data.anagrafica.filter(
                (x) => x.Cf === item.Cf && x.IsPg === item.IsPg
              );
              lastVersion.sort((a, b) => b.versionN - a.versionN); //ordino per versionN desc
              item = lastVersion[0];

              if (arrItems.filter((x) => x.id === item.Id).length <= 0) {
                const fascicoliArr = [];

                for (let m = 0; m < item.anagrafica_fascicolos.length; m++) {
                  const fascicoloItem = item.anagrafica_fascicolos[m].fascicolo;

                  if (
                    fascicoliArr.filter((x) => x.id === fascicoloItem.Id)
                      .length <= 0
                  ) {
                    const fascicoloModel = {
                      id: fascicoloItem.Id,
                      nome: fascicoloItem.Name,
                      data: fascicoloItem.Date,
                      anagId: item.Id,
                      ruolo:
                        item.anagrafica_fascicolos[m].base_tipo_anagrafica
                          .Value,
                    };
                    fascicoliArr.push(fascicoloModel);
                  }
                }

                const hasIdentificazioni =
                  item.anagrafica_identificaziones.length > 0 ? "Sì" : "No";

                let ppe = "-";
                if (!item.IsPg) {
                  if (item.IsPep) {
                    ppe = "E' una persona politicamente esposta";
                  } else {
                    if (!item.IsNotPepMoreThanYear) {
                      ppe = "Non è una persona politicamente esposta";
                    } else if (item.IsNotPepMoreThanYear) {
                      ppe =
                        "Non è una persona politicamente esposta da più di un anno";
                    }
                  }
                }

                var denominazione = item.Nome + " " + item.Cognome;
                if (item.IsPg) {
                  denominazione = item.Denominazione;
                }

                //Rischio area geografica
                let rischioAreaGeo = 1;
                if (item.AddrPaeseId !== null && item.AddrPaeseId > 0) {
                  const country = arrPaeseItems.filter(
                    (x) => x.id === item.AddrPaeseId
                  )[0];
                  if (country.identificativo === "ITA") {
                    if (item.AddrProvinciaId > 0) {
                      const provincia = arrProvinceItems.filter(
                        (x) => x.id === item.AddrProvinciaId
                      )[0];
                      rischioAreaGeo = provincia.rischio;
                    }
                  } else {
                    rischioAreaGeo = 4;
                  }
                }

                for (let f = 0; f < fascicoliArr.length; f++) {
                  const itemModel = {
                    path: [
                      denominazione,
                      fascicoliArr[f].nome,
                      fascicoliArr[f].ruolo,
                    ],
                    pathId: uuidv4(),
                    id: item.Id,
                    cf: item.Cf,
                    pIva: item.PIva,
                    denominazione: denominazione,
                    identificato: hasIdentificazioni,
                    ppe: ppe,
                    rischioAreaGeo: rischioAreaGeo,
                    fascicoloId: fascicoliArr[f].id,
                    fascicolo: fascicoliArr[f].nome,
                    ruolo: fascicoliArr[f].ruolo,
                    data: fascicoliArr[f].data,
                  };
                  arrItems.push(itemModel);
                }
              }
            }
          }

          setAnagraficheList(arrItems);
          setDataItems(arrItems);
          setDataRows(arrItems);
          setFilteredRows(arrItems);
          /**************************************/
          //Carico le anagrafiche da Workspace
          try {
            setDataColumns2([
              { field: "id", headerName: "Id", flex: 0.2 },
              {
                field: "cf",
                headerName: "Codice Fiscale",
                flex: 0.3,
                editable: false,
              },
              {
                field: "pIva",
                headerName: "P. Iva",
                flex: 0.3,
                editable: false,
              },
              {
                field: "denominazione",
                headerName: "Denominazione",
                flex: 1,
                editable: false,
              },
              { field: "nome", headerName: "Nome", flex: 0.5, editable: false },
              {
                field: "cognome",
                headerName: "Cognome",
                flex: 0.5,
                editable: false,
              },
              { field: "actions", headerName: "", flex: 0.2 },
            ]);

            const anagWSResponse = await tsApiWorkspace.fetch({
              headers: {
                Authorization: "Bearer " + accessToken,
                "X-App-Name": "TS420",
                "X-App-Version": "1.0.0",
                "X-Request-ID": uuidv4(),
                "X-Correlation-ID": uuidv4(),
                "Content-Type": "application/json",
                "X-Item-ID": tenantId,
                "X-User-ID": loggedUserId,
                "X-Workspace-ID": tenantId
              },
              data: {
                ownerId: tenantId,
                status: "ACTIVE",
              },
            });
            const tenantModel = sharedDatas.getPropertyByName("tenantModel");

            const arrItems2 = [];
            var counter = 1000000; //Setto un id Int per l'anagrafica
            for (let i = 0; i < anagWSResponse.data.content.length; i++) {
              const anagrafica = anagWSResponse.data.content[i];
              //if (arrItems.filter(x => x.cf === anagrafica.personalData.taxIdentifier).length <= 0 && arrItems.filter(x => x.cf === tenantModel.vatNumber).length <= 0) {
              if (
                arrItems.filter(
                  (x) => x.cf === anagrafica.personalData.taxIdentifier
                ).length <= 0 &&
                anagrafica.personalData.taxIdentifier !== tenantModel.vatNumber
              ) {
                const anagAv = new anagraficaModel(
                  counter,
                  0,
                  anagrafica.classifier === "COMPANY" ? true : false,
                  anagrafica.personalData.taxIdentifier,
                  anagrafica.personalData.name,
                  anagrafica.personalData.surname,
                  0,
                  anagrafica.personalData.birthplace,
                  anagrafica.personalData.birthDate,
                  "",
                  anagrafica.personalData.businessName,
                  anagrafica.personalData.vatIdentifier,
                  "",
                  "",
                  0,
                  0,
                  "",
                  0,
                  "",
                  "",
                  "",
                  "",
                  0,
                  0,
                  "",
                  0,
                  "",
                  "",
                  "",
                  "",
                  false,
                  false,
                  1,
                  new Date(),
                  false,
                  [],
                  [],
                  "",
                  loggedUserId
                );

                arrItems2.push(anagAv);
                counter = counter + 1;
              }
            }
            let toDelFromAvailable = arrItems2.filter(
              (item) => item.cf && item.pIva
            );
            toDelFromAvailable.forEach((item) => {
              let indexToDel = arrItems2.findIndex(x=> x.id === item.id);
              arrItems2.splice(indexToDel, 1);
            });

            setDataItems2(arrItems2);
            setDataRows2(arrItems2);
          } catch (e) {
            AddLog(e.message,e.stack,"loadDatas")            
          }

          for (let I = 0; I < anagResponse.data.data.anagrafica.length; I++) {
            const anag = anagResponse.data.data.anagrafica[I];
            if(!isGuid(anag.WorkspaceId)){
              try {
                const searchApiWorkSpaceResp = await tsApiWorkspaceRead.fetch({
                  headers: {
                    Authorization: "Bearer " + accessToken,
                    "X-App-Name": "TS420",
                    "X-App-Version": "1.0.0",
                    "X-Request-ID": uuidv4(),
                    "X-Correlation-ID": uuidv4(),
                    "Content-Type": "application/json",
                    "X-Item-ID": tenantId,
                    "X-User-ID": loggedUserId,
                  },
                  data: {
                    ownerId: tenantId,
                    identifier : anag.IsPg? anag.PIva : anag.Cf,
                    status: "ACTIVE",
                  },
                });
                
                if(searchApiWorkSpaceResp.data.content.length === 1)
                  anag.WorkspaceId = searchApiWorkSpaceResp.data.content[0].id                                    
                else if(searchApiWorkSpaceResp.data.content.length === 0)
                  anag.WorkspaceId = await saveAnagOnWorkSpace(anag)
                
                if(anag.Id > 0){
                  let updateWsIdResp = await apiUpdateWsId.fetch({
                    id : anag.Id,
                    wsId: anag.WorkspaceId
                  })
                  console.log(updateWsIdResp)
                }
              } catch (e) {
                AddLog(e.message,e.stack,`SyncWorkspace failed on anag Id ${anag.id}`)                
              }
            }
          }
        }
      }
    } catch (e) {
      AddLog(e.message,e.stack,"loadDatas")      
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      setIsDataLoaded(true);
    }
  }  
  //#endregion

  //#region Render Methods
  //DataGrid
  //Grid Button Detail
  const renderLivelloRischio = (item) => {
    if (item.rischioAreaGeo !== undefined) {
      let livelloS = t("valutazione.form.rischioInerenteLivello1");
      let livelloC = chipGreen;

      switch (item.rischioAreaGeo) {
        case 1: {
          livelloS = t("valutazione.form.rischioInerenteLivello1");
          livelloC = chipGreen;
          break;
        }
        case 2: {
          livelloS = t("valutazione.form.rischioInerenteLivello2");
          livelloC = chipYellow;
          break;
        }
        case 3: {
          livelloS = t("valutazione.form.rischioInerenteLivello3");
          livelloC = chipOrange;
          break;
        }
        case 4: {
          livelloS = t("valutazione.form.rischioInerenteLivello4");
          livelloC = chipRed;
          break;
        }
        default: {
          // livelloS = t("valutazione.form.rischioInerenteLivello1");
          // livelloC = chipGreen;
          break;
        }
      }

      return <VaporTag variant="standard" label={livelloS} sx={livelloC} />;
    } else {
      return null;
    }
  };
  const renderLivelloRischioS = (item) => {
    if (item.rischioAreaGeo !== undefined) {
      let livelloS = t("valutazione.form.rischioInerenteLivello1");

      switch (item.rischioAreaGeo) {
        case 1: {
          livelloS = t("valutazione.form.rischioInerenteLivello1");
          break;
        }
        case 2: {
          livelloS = t("valutazione.form.rischioInerenteLivello2");
          break;
        }
        case 3: {
          livelloS = t("valutazione.form.rischioInerenteLivello3");
          break;
        }
        case 4: {
          livelloS = t("valutazione.form.rischioInerenteLivello4");
          break;
        }
        default: {
          //livelloS = t("valutazione.form.rischioInerenteLivello1");
          break;
        }
      }

      return livelloS;
    } else {
      return null;
    }
  };
  const renderDetailsButton = (userPerms, item) => {
    if (item.id !== undefined) {
      return (
        <Stack direction="row" spacing={0}>
          {userPerms.canRead ? (
            <IconButton
              color="primary"
              onClick={(e) => handleGridBtnDetailClick(e, item)}
            >
              <DetailIcon />
            </IconButton>
          ) : null}
        </Stack>
      );
    } else {
      return null;
    }
  };
  //#endregion
  
  //#region Handler
  const handleGridBtnDetailClick = (event, item) => {
    if (item.fascicoloId !== undefined && item.id !== undefined) {
      event.stopPropagation();
      setLocalStorageSelectedFascicolo(item.fascicoloId);
      setLocalStorageSelectedEntity("anagrafica", item.id);
      navigate("/fascicolo/manage");
    }
  };
  //#endregion

  //#region WSMethods
  const saveAnagOnWorkSpace = async (anagrafica) => {
    var data = null;
    let isPfFiscalCode = isAlphaNumeric(anagrafica.cf) && anagrafica.cf.length === 16;
    try {      
      if (anagrafica.IsPg) {
        var personalData = null
        //L'anagrafica è una ditta individuale
        if(isPfFiscalCode){          
          personalData = {
            taxIdentifier: anagrafica.Cf,
            vatIdentifier: anagrafica.PIva,
            businessName: anagrafica.Denominazione,
            countryCode: "IT",
            name: anagrafica.Denominazione,
            surname: anagrafica.Denominazione,
            birthDate:
                anagrafica.NascitaData !== null
                  ? anagrafica.NascitaData
                  : "1900-01-01T01:00:00",
              birthplace:
                anagrafica.NascitaLuogo !== "" ? anagrafica.NascitaLuogo : "N.D.",
              gender: anagrafica.GenereId === 1 ? "M" : "F",
          }
        } else { //L'anagrafica è una ditta normale
          personalData = {
            taxIdentifier: anagrafica.Cf,
            vatIdentifier: anagrafica.PIva,
            businessName: anagrafica.Denominazione,
            countryCode: "IT",
          }
        }

        data = {
          ownerId: tenantId,
          classifier: "COMPANY",
          personalData: personalData,
          baseCurrency: "EUR",
        };
      } else {
        data = {
          ownerId: tenantId,
          classifier: "PERSON",
          personalData: {
            taxIdentifier: anagrafica.Cf,
            countryCode: "IT",
            name: anagrafica.Nome,
            surname: anagrafica.Cognome,
            birthDate:
              anagrafica.NascitaData !== null
                ? anagrafica.NascitaData
                : "1900-01-01T01:00:00",
            birthplace:
              anagrafica.NascitaLuogo !== "" ? anagrafica.NascitaLuogo : "N.D.",
            gender: anagrafica.GenereId === 1 ? "M" : "F",
          },
          baseCurrency: "EUR",
        };
      }

      const wsResult = await tsApiAddAnagOnWs.fetch({
        headers: {
          Authorization: "Bearer " + accessToken,
          "X-App-Name": "TS420",
          "X-App-Version": "1.0.0",
          "X-Request-ID": uuidv4(),
          "X-Correlation-ID": uuidv4(),
          "Content-Type": "application/json",
          "X-Item-ID": tenantId,
          "X-User-ID": loggedUserId,
        },
        data,
      });
      let wsId = wsResult.data !== null ? wsResult.data.id : "";
      return wsId;
    } catch (e) {
      AddLog(`Error : ${e.message}, while creating workspace of anag id '${anagrafica.id}'`,e.stack,"SaveAnagOnWorkSpace")      
    }
  }
  //#endregion
  //#region Return Graphics
  return (
    <VaporPage title={t("nav.anagrafiche")}>
      <Loading open={backDropOpen} />

      {isDataLoaded && !userPermissions.canRead ? <NotAuth /> : null}

      {isDataLoaded && userPermissions.canRead ? (
        <VaporPage.Section>
          <ExtendedTabs
            variant="standard"
            size="small"
            value={selectedTab}
            onChange={handleChangeTab}
            sx={{ mb: 2 }}
          >
            <ExtendedTab
              value="tabAnagrafiche"
              label={t("anagrafica.anagraficaForm.label1")}
            />
            <ExtendedTab
              value="tabDisponibili"
              label={t("anagrafica.anagraficaForm.label2")}
            />
          </ExtendedTabs>

          {selectedTab === "tabAnagrafiche" ? (
            <Grid container>
              <Grid item xs={12} sx={{ width: "100%" }}>
                {isDataLoaded && dataRows.length > 0 ? (
                  <DataGridPro
                    rows={dataRows}
                    columns={dataColumns}
                    getRowId={(row) => row.pathId}
                    autoHeight={true}
                    slots={{ toolbar: GridToolbar }}
                    pageSizeOptions={[25, 50, 100]}
                    localeText={
                      itIT.components.MuiDataGrid.defaultProps.localeText
                    }
                    pagination
                    disableRowSelectionOnClick
                    treeData
                    getTreeDataPath={(row) => row.path}
                    onRowClick={(item, e) =>
                      handleGridBtnDetailClick(e, item.row)
                    }
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          id: false,
                        },
                      },
                      pagination: {
                        paginationModel: {
                          pageSize: 25,
                        },
                      },
                    }}                    
                    slotProps={{
                      toolbar: { printOptions: { disableToolbarButton: true } },
                    }}
                  />
                ) : (
                  <NoData size="small" />
                )}
              </Grid>
            </Grid>
          ) : null}

          {selectedTab === "tabDisponibili" ? (
            <Grid container>
              <Grid item xs={12} sx={{ width: "100%" }}>
                {dataRows2.length > 0 ? (
                  // <TableData tableSize="medium" columns={dataColumns2} rows={dataRows2} customRowItems={null} actions={null}></TableData>
                  <DataGridPro
                    rows={dataRows2}
                    columns={dataColumns2}
                    autoHeight={true}
                    slots={{ toolbar: GridToolbar }}
                    pageSizeOptions={[25, 50, 100]}
                    localeText={
                      itIT.components.MuiDataGrid.defaultProps.localeText
                    }
                    pagination
                    disableRowSelectionOnClick
                    initialState={{
                      columns: {
                        columnVisibilityModel: {
                          id: false,
                        },
                      },
                      pagination: {
                        paginationModel: {
                          pageSize: 25,
                        },
                      },
                    }}
                    slotProps={{
                      toolbar: { printOptions: { disableToolbarButton: true } },
                    }}
                  />
                ) : (
                  <NoData />
                )}
              </Grid>
            </Grid>
          ) : null}
        </VaporPage.Section>
      ) : null}
    </VaporPage>
  );
  //#endregion
};
