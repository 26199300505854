/*PATH*/
export const pathHome = "/";
export const pathAziendaHome = "/azienda/home";
export const pathAziendaAdd = "/azienda/manage";
export const pathFascicoloHome = "/fascicolo";
export const pathFascicoloNew = "/fascicolo/new";
export const pathFascicoloAdd = "/fascicolo/manage";
export const pathAnagraficaHome = "/anagrafica";
export const pathAnagraficaImportDesktop = "/anagrafica/import";
export const pathPrestazioneHome = "/prestazione";
export const pathRischioHome = "/rischio";
export const pathAutoValutazioneHome = "/autovalutazione";
export const pathAutoValutazioneAdd = "/autovalutazione/manage";
export const pathSegnalazioneHome = "/segnalazione";
export const pathNormativaHome = "/normativa";
export const pathImpTabBaseHome = "/impostazioni/tabellabase";
export const pathImpUtenteHome = "/impostazioni/utente";
export const pathImpPermessoHome = "/impostazioni/permesso";
export const pathImpAuditHome = "/impostazioni/audit";
export const pathReleaseNotesHome = "/impostazioni/releaseNotes";
export const pathExportDatiHome = "/export";
export const pathNominationHome = "/impostazioni/nominations"

//SECURITY AREA
export const bsaTenant = "tenant";
export const bsaAzienda = "azienda";
export const bsaFascicolo = "fascicolo";
export const bsaAnagrafica = "anagrafica";
export const bsaPrestazione = "prestazione";
export const bsaRischio = "rischio";
export const bsaAutovalutazione = "autovalutazione";
export const bsaSegnalazione = "segnalazione";
export const bsaNormativa = "normativa";
export const bsaSettingsTabellaBase = "settings_tabella_base";
export const bsaSettingsUser = "settings_user";
export const bsaSettingsAudit = "settings_audit";
export const bsaSettingsPermission = "settings_permission";
export const bsaSettingsReleaseNotes = "settings_releasenotes";
export const bsaSettingsExport = "exportDati";
export const bsaSettingsNominations = "settings_nomination"

/*STYLES*/
export const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "450px",
    bgcolor: "#FFFFFF",
    p: 2,
    borderRadius: "5px",
};

/*NOTISTACK*/
export const anchorOrigin = { vertical: "top", horizontal: "right" };
export const autoHideDuration = 3500;
export const maxSnack = 3;
