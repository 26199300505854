//#region Imports
import React, { useState, useEffect, Fragment } from "react";
import sharedDatas from "../../businessLogic/sharedDatas";
import { useNavigate } from "react-router-dom";
import info from "../../info.json"; //Contiene la versione

//oneFront SDK
import {
  useSnackbar,
  useAuth,
  useTokenData,
  useQuery,
  useMutation,
  useGet,
  useTranslation,
} from "@onefront/react-sdk";

import VaporTag from "@vapor/react-custom/VaporTag";

import {
  DataGridPro,
  GridToolbar,
  itIT,
} from "@mui/x-data-grid-pro";

//Vapor Components
import Stack from "@vapor/react-material/Stack";
import IconButton from "@vapor/react-material/IconButton";
import Card from "@vapor/react-material/Card";
import CardContent from "@vapor/react-material/CardContent";
import CardActions from "@vapor/react-material/CardActions";
import Box from "@vapor/react-material/Box";
import Button from "@vapor/react-material/Button";
import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";
import ExtendedTabs from "@vapor/react-extended/ExtendedTabs";
import ExtendedTab from "@vapor/react-extended/ExtendedTab";

//Custom styles
import {
  chipGreen,
  chipYellow,
  chipOrange,
  chipRed,
} from "../../businessLogic/styles";

//Icons
import {
  ArrowForward as ArrowForwardIcon,
} from "@mui/icons-material";

//Custom components
import { Loading } from "../../components/Loading";
import { NoData } from "../../components/NoData";
import { ModalVersion } from "../../components/ModalVersion";

//Business Logic
import {
  setLocalStorageSelectedFascicolo,
  getFascicoloStatus,
  setLocalStorageSelectedAutovalutazione,
  setLocalStorageSelectedAzienda,
  setLocalStorageSelectedEntity,
  setLocalStorageImportType,
  monthDiff,
} from "../../businessLogic/bl";

//Constants
import {
  pathFascicoloHome,
  pathFascicoloAdd,
  pathAnagraficaHome,
  pathAnagraficaImportDesktop,
  pathPrestazioneHome,
  pathRischioHome,
  pathSegnalazioneHome,
  bsaSettingsPermission,
  pathAutoValutazioneHome,
  pathAutoValutazioneAdd,
  pathNormativaHome,
  pathImpTabBaseHome,
  pathImpUtenteHome,
  pathImpAuditHome,
  pathAziendaHome,
  pathAziendaAdd,
  pathReleaseNotesHome,
  pathExportDatiHome,
  pathNominationHome,
} from "../../businessLogic/constants";

//Models
import {
  tenantModel,
  aziendaModel,
  userModel,
  userPermissionModel,
  baseSecurityAreaModel,
  notificationModel,
  fascicoloModel,
  basePaeseModel,
  baseProvinciaModel,
} from "../../businessLogic/models";

//Queries
import {
  query_aziende_byTenant,
  query_aziende_byNameCfPIva,
  query_user_permission_byUser,
  query_user_permission_hasTruePermsCount,
  query_base_security_area,
  query_user_byId,
  query_user_permission_byUserCount,
  query_fascicolo_byTenant,
  query_autoValutazione_byTenant_latest,
  query_users_respAntiric,
  query_notification_byTenantIdNotReaded,
  query_sos_byTenant_count,
  query_rischi_byDataControlloCostante,
  query_base_paese,
  query_base_provincia,
  query_prestazione_byTenant_Prest_stats,
  query_rischio_byTenant_stats,
  query_rischio_anagrafiche_stats,
  query_ppe_anagrafiche_stats,
  query_lastActivity_byUserId,
  query_base_all,
  query_anagrafiche_docInScadenza,
  query_prestazioni_byTenant_count,
  query_autoValutazioneAvvocato_byTenant_latest,
  query_system_message,
  query_autoValutazione_byExpireDate,
  query_autoValutazioneAvv_byExpireDate,
} from "../../businessLogic/query";

//Mutations
import {
  mutation_add_audit,
  mutation_update_tenant,
  mutation_add_azienda,
  mutation_update_azienda,
  mutation_add_user,
  mutation_add_user_tenant,
  mutation_add_user_permission,
  mutation_update_user,
  mutation_add_log,
  mutation_add_metering_consumption,  
} from "../../businessLogic/mutation";

//Other components
import { v4 as uuidv4 } from "uuid";
import postal from "postal";
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer } from "recharts";
import { VersionAlert } from "../../components/VersionAlert";
import { ModalErrorVersion } from "../../components/ModalErrorVersion";
//#endregion

export const Dashboard = ({ basePath }) => {
  const loggedUserId = useTokenData("sub");
  const aziendaId = sharedDatas.getPropertyByName("aziendaId");
  const version = sharedDatas.getPropertyByName("version");
  const env = sharedDatas.getPropertyByName("env");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  //#region Query
  const apiUserById = useQuery(
    query_user_byId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseSecurityArea = useQuery(
    query_base_security_area,
    {},
    { lazy: false, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAziendeByTenant = useQuery(
    query_aziende_byTenant,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAziendeByNameCfPIva = useQuery(
    query_aziende_byNameCfPIva,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseAll = useQuery(
    query_base_all,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUserHasTruePermsCount = useQuery(
    query_user_permission_hasTruePermsCount,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const getSystemMessage = useQuery(
    query_system_message,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
);
  //#endregion

  //#region Mutation
  const apiAddLog = useMutation(mutation_add_log, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUpdateTenant = useMutation(mutation_update_tenant, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAzienda = useMutation(mutation_add_azienda, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUpdateAzienda = useMutation(mutation_update_azienda, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddUser = useMutation(mutation_add_user, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddUserTenant = useMutation(mutation_add_user_tenant, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUpdateUser = useMutation(mutation_update_user, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddMeteringConsumption = useMutation(
    mutation_add_metering_consumption,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAddAudit = useMutation(mutation_add_audit, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddUserPermission = useMutation(mutation_add_user_permission, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  }); 
  //#endregion

  //#region TsDigital
  /*----------------------------------------*/
  /*TOUCH POINT DA TSDIGITAL*/
  /*----------------------------------------*/
  const [isTokenReady, setIsTokenReady] = useState(false);
  const [tenantId, setTenantId] = useState(
    sharedDatas.getPropertyByName("tenantId")
  );
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [isLastVersionModalShow,setIsLastVersionModalShow] = useState(false);
  const [isVersionCorrect,setIsVersionCorrect] = useState(true)
  const [message,setMessage] = useState(null)

  //TS Digital Api
  const [accessToken] = useState(useAuth().token);
  const tsApiTenant = useGet({ lazy: true });
  const tsApiUser = useGet(
    "aml:user-read-agyo:api:///" + loggedUserId + "?profileType=FULL",
    { lazy: true }
  );

  const authSessionData = useAuth();
  let authSessionDataResponse = "";
  //let authTenantId = "0eca44f9-2db4-4af5-a10a-2abf385879d1" //Mazzoli enterprise ruolo commercialista 
  //let authTenantId = "5a174d90-9554-418c-8464-dc4fc41f79d7" //Polisportiva mazzoli ruolo avvocato; //solo per poterlo testare in locale
  let authTenantId = "";

  if (authSessionData.sessionData !== null) {
    authTenantId = authSessionData.sessionData.itemId;
    authSessionDataResponse = JSON.stringify(authSessionData.sessionData);
  }

  useEffect(() => {
    loadFromTSDigital();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTokenReady]);

  async function loadFromTSDigital() {
    console.log(process.env.NODE_ENV)
    sharedDatas.setPropertyValue("env",process.env.NODE_ENV)
    if (!isTokenReady && authTenantId !== "") {
      if (
        sharedDatas.getPropertyByName("tenantId") !== "" &&
        sharedDatas.getPropertyByName("tenantModel") !== null &&
        sharedDatas.getPropertyByName("aziendaId") !== 0 &&
        sharedDatas.getPropertyByName("aziendaModelList") !== null &&
        sharedDatas.getPropertyByName("userId") !== "" &&
        sharedDatas.getPropertyByName("userModel") !== null
      ) {
        setTenantId(sharedDatas.getPropertyByName("tenantId"));
        setUserName(sharedDatas.getPropertyByName("userModel").completeName);
        setIsUserLoaded(true);
        setIsTokenReady(true);
      } else {
        //Carico il Tenant
        sharedDatas.setPropertyValue("tenantId", authTenantId);
        setTenantId(sharedDatas.getPropertyByName("tenantId"));

        let taxId = "";

        //Carico il Tenant
        let tenantItemResponse = null;
        try {
          const tsTenantResponse = await tsApiTenant.fetch({
            url:
              "aml:registry-read-agyo:api://" +
              authTenantId +
              "?packageType=BASE&pagination.itemsPerPage=1&pagination.pageNumber=0",
            headers: {
              Authorization: "Bearer " + accessToken,
              "X-App-Name": "TS420",
              "X-App-Version": "1.0.0",
              "X-Request-ID": uuidv4(),
              "X-Correlation-ID": uuidv4(),
              "Content-Type": "application/json",
              "X-Item-ID": tenantId,
              "X-User-ID": loggedUserId,
            },
          });

          const tenantItem = tsTenantResponse.data.item;

          //if (items.length > 0) {
          const item = tenantItem.base;
          tenantItemResponse = JSON.stringify(item);

          const itemId = item.id;
          taxId = item.identifier.taxId;
          const itemName = item.details.description;
          const vatNumber = item.identifier.vatNumber;
          var classifier = item.details.classifier;

          const addresses = item.details.addresses;
          var addrStreetName = "";
          var addrStreetNumber = "";
          var addrCity = "";
          var addrProvince = "";
          var addrZipCode = "";
          var addrCountry = "";
          var addrFull = "";

          for (let address of addresses) {
            if (address.types[0] === "REGISTERED_OFFICE") {
              addrStreetName = address.streetName;
              addrStreetNumber = address.streetNumber;
              addrCity = address.city;
              addrProvince = address.province;
              addrZipCode = address.zipCode;
              addrCountry = address.country;
              addrFull = address.fullAddress;
            }
          }

          if (
            addrStreetName === "" &&
            addrStreetNumber === "" &&
            addrCity === "" &&
            addrProvince === "" &&
            addrZipCode === "" &&
            addrCountry === "" &&
            addrFull === "" &&
            addresses.length > 0
          ) {
            addrStreetName = addresses[0].streetName;
            addrStreetNumber = addresses[0].streetNumber;
            addrCity = addresses[0].city;
            addrProvince = addresses[0].province;
            addrZipCode = addresses[0].zipCode;
            addrCountry = addresses[0].country;
            addrFull = addresses[0].fullAddress;
          }

          const updateTenantResponse = await apiUpdateTenant.fetch({
            tenantId: itemId,
            name: itemName,
            identifier: taxId,
            vatNumber: vatNumber,
            addrStreetName: addrStreetName,
            addrStreetNumber: addrStreetNumber,
            addrCity: addrCity,
            addrProvince: addrProvince,
            addrZipCode: addrZipCode,
            addrCountry: addrCountry,
            addrFull: addrFull,
            classifier: classifier,
          });
          const result = updateTenantResponse.data.data.update_tenant.returning;

          if (result.length > 0) {
            const itemR = result[0];
            const tenantM = new tenantModel(
              itemR.TenantId,
              itemR.OwnerId,
              itemR.Name,
              itemR.Identifier,
              itemR.IsReadOnly,
              itemR.IsDisabled,
              itemR.IsDeleted,
              itemR.StatusDate,
              itemR.VatNumber,
              itemR.AddrStreetName,
              itemR.AddrStreetNumber,
              itemR.AddrCity,
              itemR.AddrProvince,
              itemR.AddrZipCode,
              itemR.AddrCountry,
              itemR.AddrFull,
              itemR.Classifier
            );
            sharedDatas.setPropertyValue("tenantModel", tenantM);
          }
        } catch (e) {
          AddLog(e.message,e.stack + " - " +authSessionDataResponse +" - " +tenantItemResponse,"loadFromTSDigital/Tenant")          
          enqueueSnackbar("Spiacente... non è stato possibile recuperare la tua sottoscrizione, riprova. \n Se il problema persiste contatta l'assistenza", { variant: "error" });
        }

        //Carico lo User
        let canUserAccess = false;
        let currentVersion =  info.version;
        let userItemResponse = null;
        try {
          const tsUserResponse = await tsApiUser.fetch({
            headers: {
              Authorization: "Bearer " + accessToken,
              "X-App-Name": "TS420",
              "X-App-Version": "1.0.0",
              "X-Request-ID": uuidv4(),
              "X-Correlation-ID": uuidv4(),
              "Content-Type": "application/json",
              "X-Item-ID": authTenantId,
              "X-User-ID": loggedUserId,
            },
          });

          let userRole = "";
          userItemResponse = JSON.stringify(tsUserResponse.data);
          const profile = tsUserResponse.data.profile;
          const roles = tsUserResponse.data.roles;
          const status = tsUserResponse.data.status;

          for (let role of roles) {
            if (
              role.resourceId === taxId &&
              userRole === "" &&
              canUserAccess === false
            ) {
              canUserAccess =
                role.actionKey === "OWNER" ||
                (role.actionKey === "ADMIN" && role.appId === "AML");
              if (canUserAccess) {
                userRole = role.actionKey;
              }
            }
          }

          if (canUserAccess === false) {
            setBackDropOpen(false);
            AddLog("L'utente non ha i permessi per poter accedere alla procedura, occorre rivolgersi all'utente con il ruolo di OWNER per la necessaria abilitazione","","loadFromTSDigital/canUserAccess")            
            enqueueSnackbar(
              "L'utente non ha i permessi per poter accedere alla procedura, occorre rivolgersi all'utente con il ruolo di OWNER per la necessaria abilitazione",
              { variant: "error" }
            );
          } else {
            const firstName = profile.firstName;
            const lastName =
              profile.lastName === "" ||
              profile.lastName === null ||
              profile.lastName === undefined
                ? profile.firstName
                : profile.lastName;
            const userNew = new userModel(
              loggedUserId,
              authTenantId,
              profile.id,
              profile.id,
              firstName,
              lastName,
              firstName + " " + lastName,
              userRole,
              profile.language !== null ? profile.language : "it-IT",
              1,
              false,
              "",
              status.active,
              status.deleted,
              status.banned,
              new Date(),
              "",
              currentVersion
            );
            sharedDatas.setPropertyValue("userId", loggedUserId);

            const userResponse = await apiUserById.fetch({
              userId: loggedUserId,
            });
            const result = userResponse.data.data.user;

            //Se l'utente esiste
            if (result.length > 0) {
              const item = result[0];
              const tenantsArr = item.user_tenants;
              let userM = new userModel(
                item.UserId,
                userNew.tenantId,
                item.Username,
                item.Email,
                item.Name,
                item.Surname,
                item.Name + " " + item.Surname,
                userNew.type,
                item.Locale,
                userNew.tenantTipoId,
                item.IsRespAntiRic,
                userNew.tenantTipoS,
                item.IsActive,
                item.IsDeleted,
                item.IsBanned,
                item.CreatedOn,
                item.Cf,
                item.LastVersion,
                item.IsEnabledToCreateLawyers
              );

              //Se è già collegato al tenant
              if (
                tenantsArr.filter((x) => x.TenantId === authTenantId).length > 0
              ) {
                const itemT = tenantsArr.filter(
                  (x) => x.TenantId === authTenantId
                )[0];
                await apiUpdateUser.fetch({
                  tenantId: itemT.TenantId,
                  userId: item.UserId,
                  name: userNew.name,
                  surname: userNew.surname,
                  isRespAntiRic: item.IsRespAntiRic,
                  isActive: item.IsActive,
                  isDeleted: item.IsDeleted,
                  isBanned: item.IsBanned,
                  createdOn: item.CreatedOn,
                  cf: item.Cf,
                  lastVersion : item.LastVersion
                });
                userM = new userModel(
                  item.UserId,
                  itemT.TenantId,
                  item.Username,
                  item.Email,
                  userNew.name,
                  userNew.surname,
                  userNew.name + " " + userNew.surname,
                  itemT.Type,
                  item.Locale,
                  itemT.TenantTipoId,
                  item.IsRespAntiRic,
                  itemT.base_tipo_tenant.Value,
                  item.IsActive,
                  item.IsDeleted,
                  item.IsBanned,
                  item.CreatedOn,
                  item.Cf,
                  item.LastVersion,
                  item.IsEnabledToCreateLawyers
                );
              } else {
                const addUserTenantResponse = await apiAddUserTenant.fetch({
                  tenantId: userNew.tenantId,
                  userId: userNew.userId,
                  type: userNew.type,
                  tenantTipoId: userNew.tenantTipoId,
                });
                const resultUT =
                  addUserTenantResponse.data.data.insert_user_tenant_one;
                await apiUpdateUser.fetch({
                  tenantId: resultUT.TenantId,
                  userId: item.UserId,
                  name: userNew.name,
                  surname: userNew.surname,
                  isRespAntiRic: item.IsRespAntiRic,
                  isActive: item.IsActive,
                  isDeleted: item.IsDeleted,
                  isBanned: item.IsBanned,
                  createdOn: item.CreatedOn,
                  cf: item.Cf,
                });
                userM = new userModel(
                  item.UserId,
                  resultUT.TenantId,
                  item.Username,
                  item.Email,
                  userNew.name,
                  userNew.surname,
                  userNew.name + " " + userNew.surname,
                  resultUT.Type,
                  item.Locale,
                  resultUT.TenantTipoId,
                  item.IsRespAntiRic,
                  resultUT.base_tipo_tenant.Value,
                  item.IsActive,
                  item.IsDeleted,
                  item.IsBanned,
                  item.CreatedOn,
                  item.Cf,
                  item.LastVersion,
                  item.IsEnabledToCreateLawyers
                );
              }

              sharedDatas.setPropertyValue("userModel", userM);
              setUserName(userM.completeName);
              setIsUserLoaded(true);

              postal.publish({
                channel: "user",
                topic: "user.updatedLoggedUser",
                data: {
                  user: userM,
                },
              });
            } else {
              const addUserResponse = await apiAddUser.fetch({
                userId: userNew.userId,
                username: userNew.username,
                email: userNew.email,
                name: userNew.name,
                surname: userNew.surname,
                locale: userNew.locale,
                isRespAntiRic: userNew.isRespAntiRic,
                isActive: userNew.isActive,
                isDeleted: userNew.isDeleted,
                isBanned: userNew.isBanned,
                createdOn: userNew.createdOn,
                LastVersion : userNew.LastVersion,
              });
              const item = addUserResponse.data.data.insert_user_one;

              //Aggiungo lo user_tenant
              const addUserTenantResponse = await apiAddUserTenant.fetch({
                tenantId: userNew.tenantId,
                userId: userNew.userId,
                type: userNew.type,
                tenantTipoId: userNew.tenantTipoId,
              });
              const resultUT =
                addUserTenantResponse.data.data.insert_user_tenant_one;

              const userN = new userModel(
                item.id,
                resultUT.TenantId,
                item.Username,
                item.Email,
                item.Name,
                item.Surname,
                item.Name + " " + item.Surname,
                resultUT.Type,
                item.Locale,
                resultUT.TenantTipoId,
                item.IsRespAntiRic,
                resultUT.base_tipo_tenant.Value,
                item.IsActive,
                item.IsDeleted,
                item.IsBanned,
                item.CreatedOn,
                item.Cf,
                item.LastVersion
              );
              sharedDatas.setPropertyValue("userModel", userN);
              setUserName(userN.completeName);
              setIsUserLoaded(true);
              postal.publish({
                channel: "user",
                topic: "user.updatedLoggedUser",
                data: {
                  user: userN,
                },
              });
            }
          }
        } catch (e) {
          AddLog(e.message,e.stack +" - " +authSessionDataResponse +" - " +userItemResponse,"loadFromTSDigital/User")          
        }
        
        if (canUserAccess) {
          //Carico i Soggetti obbligati
          try {
            const tenantModel = sharedDatas.getPropertyByName("tenantModel");
            const userModel = sharedDatas.getPropertyByName("userModel");
            const aziendeResponse = await apiAziendeByTenant.fetch({
              tenantId: authTenantId,
            });
            const aziendeByNameCfPIvaResponse =
              await apiAziendeByNameCfPIva.fetch({
                tenantId: authTenantId,
                denominazione: tenantModel.name,
                cf: tenantModel.identifier,
                pIva: tenantModel.vatNumber,
              });
            const arrAziende = [];

            for (let i = 0; i < aziendeResponse.data.data.azienda.length; i++) {
              const item = aziendeResponse.data.data.azienda[i];

              const aziendePermsCountResponse = await apiUserHasTruePermsCount.fetch({
                userId: loggedUserId,
                aziendaId: item.Id,
              });

              let userPermsCount = aziendePermsCountResponse.data.data.user_permission_aggregate.aggregate.count
              //Se l'utente è di tipo OWNER può vederle tutte
              if (userModel.type === "OWNER") {
                arrAziende.push(
                  new aziendaModel(
                    item.Id,
                    tenantModel.tenantId,
                    item.Denominazione,
                    item.Cf,
                    item.PIva,
                    item.AddrPaeseId,
                    item.AddrProvinciaId,
                    item.AddrProvincia,
                    item.AddrComuneId,
                    item.AddrComune,
                    item.AddrIndirizzo,
                    item.AddrN,
                    item.AddrCap,
                    item.CreatedOn,
                    item.ModifiedOn,
                    item.CreatedBy,
                    item.ModifiedBy,
                    item.MeteringSlotId
                  )
                );
              } else {
                // Se l'utente corrente ha almeno un permesso impostato a True allora gli includo il soggetto tra quelli selezionabili
                if (userPermsCount > 0 || (userModel.type === "ADMIN" && userPermsCount === 0)) {
                  arrAziende.push(
                    new aziendaModel(
                      item.Id,
                      tenantModel.tenantId,
                      item.Denominazione,
                      item.Cf,
                      item.PIva,
                      item.AddrPaeseId,
                      item.AddrProvinciaId,
                      item.AddrProvincia,
                      item.AddrComuneId,
                      item.AddrComune,
                      item.AddrIndirizzo,
                      item.AddrN,
                      item.AddrCap,
                      item.CreatedOn,
                      item.ModifiedOn,
                      item.CreatedBy,
                      item.ModifiedBy,
                      item.MeteringSlotId
                    )
                  );
                }
              }
            }

            if (
              arrAziende.length <= 0 &&
              aziendeByNameCfPIvaResponse.data.data.azienda.length <= 0
            ) {
              const tenantCountry =
                tenantModel.addrCountry === "IT"
                  ? "ITA"
                  : tenantModel.addrCountry;
              const apiBaseAllResponse = await apiBaseAll.fetch({
                tenantId: authTenantId,
              });
              const paeseId =
                apiBaseAllResponse.data.data.base_paese.filter(
                  (x) =>
                    x.Identificativo.toLowerCase() ===
                    tenantCountry.toLowerCase()
                ).length > 0
                  ? apiBaseAllResponse.data.data.base_paese.filter(
                      (x) =>
                        x.Identificativo.toLowerCase() ===
                        tenantCountry.toLowerCase()
                    )[0].Id
                  : apiBaseAllResponse.data.data.base_paese.filter(
                      (x) => x.Identificativo === "ITA"
                    )[0].Id;

              const provinciaId =
                apiBaseAllResponse.data.data.base_provincia.filter(
                  (x) =>
                    x.Sigla.toLowerCase() ===
                    tenantModel.addrProvince.toLowerCase()
                ).length > 0
                  ? apiBaseAllResponse.data.data.base_provincia.filter(
                      (x) =>
                        x.Sigla.toLowerCase() ===
                        tenantModel.addrProvince.toLowerCase()
                    )[0].Id
                  : null;

              const comuneId =
                apiBaseAllResponse.data.data.base_comune.filter(
                  (x) =>
                    x.Nome.toLowerCase().indexOf(
                      tenantModel.addrCity.toLowerCase()
                    ) > -1
                ).length > 0
                  ? apiBaseAllResponse.data.data.base_comune.filter(
                      (x) =>
                        x.Nome.toLowerCase().indexOf(
                          tenantModel.addrCity.toLowerCase()
                        ) > -1
                    )[0].Id
                  : null;

              let aziendaNew = new aziendaModel(
                0,
                authTenantId,
                tenantModel.name,
                tenantModel.identifier,
                tenantModel.vatNumber,
                paeseId,
                provinciaId,
                tenantModel.addrProvince,
                comuneId,
                tenantModel.addrCity,
                tenantModel.addrStreetName,
                tenantModel.addrStreetNumber,
                tenantModel.addrZipCode,
                null,
                null,
                loggedUserId,
                "",
                0
              );
              const addAziendaResponse = await apiAddAzienda.fetch({
                tenantId: authTenantId,
                denominazione: aziendaNew.denominazione,
                cf: aziendaNew.cf,
                pIva: aziendaNew.pIva,
                addrPaeseId: aziendaNew.addrPaeseId,
                addrProvinciaId: aziendaNew.addrProvinciaId,
                addrProvincia: aziendaNew.addrProvincia,
                addrComuneId: aziendaNew.addrComuneId,
                addrComune: aziendaNew.addrComune,
                addrIndirizzo: aziendaNew.addrIndirizzo,
                addrN: aziendaNew.addrN,
                addrCap: aziendaNew.addrCap,
              });

              aziendaNew.id =
                addAziendaResponse.data.data.insert_azienda_one.Id;
              arrAziende.push(aziendaNew);
              sharedDatas.setPropertyValue("aziendaId", aziendaNew.id);

              //Aggiungo il consumo sul metering
              let slotId = 0;

              const addConsumptionResp = await apiAddMeteringConsumption.fetch({
                tenantId: tenantId,
                companyName: aziendaNew.denominazione,
                companyId: aziendaNew.id,
              });
              slotId =
                addConsumptionResp.data.data.addMeteringConsumption.value;

              if (slotId === 0 || slotId === null || slotId === undefined) {
                enqueueSnackbar(t("error.generale"), { variant: "error" });
              } else {
                await apiUpdateAzienda.fetch({
                  id: aziendaNew.id,
                  cf: aziendaNew.cf,
                  pIva: aziendaNew.pIva,
                  denominazione: aziendaNew.denominazione,
                  addrPaeseId: aziendaNew.addrPaeseId,
                  addrProvinciaId: aziendaNew.addrProvinciaId,
                  addrProvincia: aziendaNew.addrProvincia,
                  addrComuneId: aziendaNew.addrComuneId,
                  addrComune: aziendaNew.addrComune,
                  addrIndirizzo: aziendaNew.addrIndirizzo,
                  addrN: aziendaNew.addrN,
                  addrCap: aziendaNew.addrCap,
                  meteringSlotId: slotId,
                });
              }
            } else {
              if (arrAziende.length > 0) {
                sharedDatas.setPropertyValue(
                  "aziendaId",
                  arrAziende.filter((x) => x.cf === tenantModel.identifier)[0]
                    .id
                );
              } else {
                setBackDropOpen(false);
                AddLog("L'utente non ha i permessi per poter accedere alla procedura, occorre rivolgersi all'utente con il ruolo di OWNER per la necessaria abilitazione","","loadFromTSDigital/canUserAccess")                
                enqueueSnackbar(
                  "L'utente non ha i permessi per poter accedere alla procedura, occorre rivolgersi all'utente con il ruolo di OWNER per la necessaria abilitazione",
                  { variant: "error" }
                );
              }
            }

            if (arrAziende.length > 0) {
              sharedDatas.setPropertyValue("aziendaModelList", arrAziende);

              //Invio una notifica alla topBar per la visualizzazione dei soggetti nell'apposito menù
              postal.publish({
                channel: "azienda",
                topic: "azienda.update",
                data: {
                  aziende: arrAziende,
                },
              });
            }
          } catch (e) {
            AddLog(e.message,e.stack,"loadFromTSDigital/Azienda")            
          }

          //Gainsight
          window.initializeGainsightPX();
          window.aptrinsic(
            "identify",
            {
              id: loggedUserId,
              email: sharedDatas.getPropertyByName("userModel").email,
              firstName: sharedDatas.getPropertyByName("userModel").name,
              lastName: sharedDatas.getPropertyByName("userModel").surname,
              signUpDate: new Date(
                sharedDatas.getPropertyByName("userModel").createdOn
              ).getTime(), //unix time in ms
            },
            {
              id: tenantId, //Required
              name: sharedDatas.getPropertyByName("tenantModel").name,
            }
          );
        }

        if (sharedDatas.getPropertyByName("aziendaId") > 0) {
          setIsTokenReady(true);
        }
        //Controlllo se è necessario visualizzare la popup che avvisa della nuova versione rilasciata oppure se bloccare l'utente perchè la versione è più vecchia dell'ultimo rilascio
        let updatedUserModel = sharedDatas.getPropertyByName("userModel")
        if (updatedUserModel && updatedUserModel.lastVersion !== currentVersion){          
          setIsLastVersionModalShow(true)        
        }
      }
    }
  }
  /*----------------------------------------*/
  /*FINE TOUCH POINT DA TSDIGITAL*/
  /*----------------------------------------*/
  //#endregion

  const AddLog = async (message,stack,method) => {
    if(env === "production"){
        apiAddLog.fetch({
        tenantId: tenantId,
            aziendaId: aziendaId,
            level: "ERROR",
            message: message,
            stack: stack,
            area: "Dashboard",
            method: method,
            version: version,
        })
    }
  }

  //#region Query
  const apiUserRespAntiRic = useQuery(
    query_users_respAntiric,
    { tenantId: tenantId },
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiPermissionByUser = useQuery(
    query_user_permission_byUser,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiPermissionByUserCount = useQuery(
    query_user_permission_byUserCount,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiRischiByDataControlloCostante = useQuery(
    query_rischi_byDataControlloCostante,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAutovalutazioneByExpire = useQuery(
    query_autoValutazione_byExpireDate,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAutovalutazioneAvvByExpire = useQuery(
    query_autoValutazioneAvv_byExpireDate,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAutovalByTenantLatest = useQuery(
    query_autoValutazione_byTenant_latest,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAutovalAvvByTenantLatest = useQuery(
    query_autoValutazioneAvvocato_byTenant_latest,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiNotificationNotReaded = useQuery(
    query_notification_byTenantIdNotReaded,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiFascicoli = useQuery(
    query_fascicolo_byTenant,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiSOSByTenantCount = useQuery(
    query_sos_byTenant_count,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  const apiBasePaese = useQuery(
    query_base_paese,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseProvincia = useQuery(
    query_base_provincia,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiRischioAnagraficheStats = useQuery(
    query_rischio_anagrafiche_stats,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiPPEAnagraficheStats = useQuery(
    query_ppe_anagrafiche_stats,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiPrestByTenantPrestStats = useQuery(
    query_prestazione_byTenant_Prest_stats,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiPrestByTenantCount = useQuery(
    query_prestazioni_byTenant_count,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiRischioByTenantStats = useQuery(
    query_rischio_byTenant_stats,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAnagDocInScadenza = useQuery(
    query_anagrafiche_docInScadenza,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUserLastActivity = useQuery(
    query_lastActivity_byUserId,
    { tenantId: tenantId, userId: loggedUserId },
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  //#endregion

  //#region UseState
  const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
  const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api
  const [isPermsLoaded, setIsPermsLoaded] = useState(false);

  //User
  const [userName, setUserName] = useState("");
  const [userRespAntiricName, setUserRespAntiricName] = useState("");

  //Autovalutazione
  const [livelloS, setLivelloS] = useState("");
  const [livelloC, setLivelloC] = useState(chipGreen);

  const [, setBsaList] = useState([]); //Contiene il result delle base security areas
  const [, setPermessiList] = useState([]); //Contiene il result dei permessi

  const [entityCounters, setEntityCounters] = useState({
    fascicoli: 0,
    anagrafiche: 0,
    prestazioni: 0,
    valutazioni: 0,
    sos: 0,
  }); //Counters entità
  const [, setNotificationList] = useState([]);
  const [, setOpenNotificationDetail] = useState(0);
  const [rischiList, setRischiList] = useState([]);
  const [rischiColumns, setRischiColumns] = useState([]);
  const [autovalList,setAutovalList] = useState([])
  const [autovalColumns,setAutovalColumns] = useState([])
  const [anagDocInScadList, setAnagDocInScadList] = useState([]);
  const [anagDocInScadListColumns, setAnagDocInScadListColumns] = useState([]);
  const [, setFascicoliTotN] = useState(0);
  const [fascicoliIncompleteList, setFascicoliIncompleteList] = useState([]);
  const [fascicoliIncompleteColumns, setFascicoliIncompleteColumns] = useState([]);
  const [fascicoliCompleteList,setFascicoliCompleteList]=useState([]);
  const [fascicoliCompleteColumns,setFascicoliCompleteColumns]= useState([]);
  const [statsFascicoliData, setStatsFascicoliData] = useState([]);
  const [statsPfPgData, setStatsPfPgData] = useState([]);
  const [statsPrestData, setStatsPrestData] = useState([]);
  const [statsRischiData, setStatsRischiData] = useState([]);
  const [tenantTypeId,setTenantTypeId] = useState(0)
  //#endregion

  //Last Activity
  const [lastActivity, setLastActivity] = useState({
    path: "",
    entityId: "",
    entityName: "",
    area: "",
  });  
  //#region Charts
  const RADIAN = Math.PI / 180;
  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
    fill,
  }) => {
    const radius = innerRadius + outerRadius * 0.8;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill={fill}
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline='central'
      >
        {`${(percent * 100).toFixed(2)}%`}
      </text>
    );
  };
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            background: "#FFFFFF",
            padding: "4px",
            border: "1px solid #5676D4",
          }}
        >
          {`${payload[0].name}`}
        </div>
      );
    }

    return null;
  };
  //#endregion

  //#region Antiriciclaggio Desktop
  const tsApiARicDesktop = useGet(
    "aml:aric-desktop:licensing://info_licenza.cfm?magic=12345",
    { lazy: true }
  );
  const [isARicDesktopActive, setIsARicDesktopActive] = useState(false);
  //#endregion

  //#region Effects
  useEffect(() => {
    setIsPermsLoaded(false);
    setIsDataLoaded(false);

    //Svuoto i dati
    setEntityCounters((prevState) => ({
      ...prevState,
      fascicoli: 0,
      anagrafiche: 0,
      prestazioni: 0,
      valutazioni: 0,
      sos: 0,
    }));
    setNotificationList([]);
    setOpenNotificationDetail(0);
    setRischiList([]);
    setRischiColumns([]);
    setAnagDocInScadList([]);
    setAnagDocInScadListColumns([]);
    setFascicoliIncompleteList([]);
    setFascicoliIncompleteColumns([]);

    setStatsFascicoliData([]);
    setStatsPfPgData([]);
    setStatsPrestData([]);
    setStatsRischiData([]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharedDatas.getPropertyByName("aziendaId")]);
  useEffect(() => {
    loadPermessi();
    loadDatas();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTokenReady, isDataLoaded]);
  //#endregion

  //#region Load Functions
  const loadPermessi = async () => {
    try {
      if (isTokenReady && !isPermsLoaded) {

        //Se l'utente ha ruolo di Admin (L) di default ha tutti i permessi, altrimenti nessuno in quanto deve essere l'Admin a fornire i permessi
        const userRole = sharedDatas.getPropertyByName("userModel").type;
        const canRead = userRole === "OWNER" || userRole === "ADMIN";
        const canCreate = userRole === "OWNER" || userRole === "ADMIN";
        const canUpdate = userRole === "OWNER" || userRole === "ADMIN";
        const canDelete = userRole === "OWNER" || userRole === "ADMIN";

        let permessiList = [];

        if (sharedDatas.getPropertyByName("userPermissions") !== null) {
          permessiList = sharedDatas
            .getPropertyByName("userPermissions")
            .filter((x) => x.aziendaId === aziendaId);
        }


        if (permessiList.length > 0) {
          setPermessiList(sharedDatas.getPropertyByName("userPermissions"));
          setIsPermsLoaded(true);
        } else {
          const bsaResponse = await apiBaseSecurityArea.fetch();
          const areaItems = [];
          bsaResponse.data.data.base_security_area.map((item) =>
            areaItems.push(
              new baseSecurityAreaModel(
                item.Id,
                item.Key,
                item.Value,
                item.Description,
                item.Order
              )
            )
          );
          setBsaList(areaItems);

          //Verifico se per l'utente selezionato esistono i permessi
          const countUserPermsResponse = await apiPermissionByUserCount.fetch({
            userId: loggedUserId,
            aziendaId: aziendaId,
          });
          const hasUserPermissions =
            countUserPermsResponse.data.data.user_permission_aggregate.aggregate
              .count > 0;

          //Controllo se l'utente ha tutti i tipi di permesso disponibili
          const hasAllUserPermissions = countUserPermsResponse.data.data.user_permission_aggregate.aggregate
          .count === areaItems.length ;

          //Prendo i permessi attualmente impostati sull'utente
          const actualPerms = await apiPermissionByUser.fetch({
            userId: loggedUserId,
            aziendaId: aziendaId,
          });

          //Se l'utente non ha tutti i permessi disponibili li aggiungo
          if(!hasAllUserPermissions){
            var alreadyPermittedArea = actualPerms.data.data.user_permission.map(item=> item.Area)
            var permsMissing = areaItems.filter(item=> !alreadyPermittedArea.includes(item.key))

            for (let i = 0; i < permsMissing.length; i++) {
              const element = permsMissing[i];
              await apiAddUserPermission.fetch({
                userId: loggedUserId,
                aziendaId: aziendaId,
                area: element.key,
                canRead: canRead,
                canCreate: canCreate,
                canUpdate: canUpdate,
                canDelete: canDelete,
              });
            }
          }

          //Se per l'utente esistono i permessi li recupero, altrimenti glieli imposto di default
          if (hasUserPermissions) {
            const userPermsresponse = await apiPermissionByUser.fetch({
              userId: loggedUserId,
              aziendaId: aziendaId,
            });
            const arrItems = [];
            userPermsresponse.data.data.user_permission.map((item) =>
              arrItems.push(
                new userPermissionModel(
                  item.Id,
                  item.AziendaId,
                  areaItems.filter((bsa) => bsa.key === item.Area)[0].key,
                  item.CanRead,
                  item.CanCreate,
                  item.CanUpdate,
                  item.CanDelete
                )
              )
            );
            setPermessiList(arrItems);
            sharedDatas.setPropertyValue("userPermissions", arrItems);
            setIsPermsLoaded(true);
          } else {
            let count = 0;
            for (var i = 0; i < areaItems.length; i++) {
              const area = areaItems[i];
              const adUserPermsResponse = await apiAddUserPermission.fetch({
                userId: loggedUserId,
                aziendaId: aziendaId,
                area: area.key,
                canRead: canRead,
                canCreate: canCreate,
                canUpdate: canUpdate,
                canDelete: canDelete,
              });
              const result =
                adUserPermsResponse.data.data.insert_user_permission_one;
              const newValues = new userPermissionModel(
                result.Id,
                result.AziendaId,
                result.Area,
                result.CanRead,
                result.CanCreate,
                result.CanUpdate,
                result.CanDelete
              );

              //Aggiungo la Audit per tracciare l'azione
              apiAddAudit.fetch({
                tenantId: tenantId,
                aziendaId: aziendaId,
                area: bsaSettingsPermission,
                operation: "Create",
                entityType: "user_permission",
                entityId: result.Id.toString(),
                oldValues: "",
                newValues: JSON.stringify(newValues),
              });

              count++;
              if (areaItems.length === count) {
                setIsPermsLoaded(false);
                loadPermessi();
              }
            }
          }
        }
      }
    } catch (e) {
      AddLog(e.message,e.stack,"loadPermessi")      
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };
  const loadDatas = async () => {
    try {
      if (isTokenReady && !isDataLoaded) {
        //Software Version
        sharedDatas.setPropertyValue("version", info.version);

        //Counters
        const countSOSResponse = await apiSOSByTenantCount.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          userId: loggedUserId,
        });
        setEntityCounters((prevState) => ({
          ...prevState,
          sos: countSOSResponse.data.data.sos_aggregate.aggregate.count,
        }));

        //Autovalutazione di studio 
        let tenantTypeId = sharedDatas.getPropertyByName("userModel").tenantTipoId
        setTenantTypeId(tenantTypeId)  

        if(tenantTypeId !== 3){
          const countAutoResponse = await apiAutovalByTenantLatest.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
          });
          const item = countAutoResponse.data.data.autovalutazione;
  
          if (item.length > 0) {
            const itemLatest = item[0];
  
            switch (itemLatest.LivelloRischio) {
              case 1: {
                setLivelloS(t("autovalutazione.form.livelloRischio1"));
                setLivelloC(chipGreen);
                break;
              }
              case 2: {
                setLivelloS(t("autovalutazione.form.livelloRischio2"));
                setLivelloC(chipYellow);
                break;
              }
              case 3: {
                setLivelloS(t("autovalutazione.form.livelloRischio3"));
                setLivelloC(chipOrange);
                break;
              }
              case 4: {
                setLivelloS(t("autovalutazione.form.livelloRischio4"));
                setLivelloC(chipRed);
                break;
              }
              default: {
                // setLivelloS(t("autovalutazione.form.livelloRischio1"));
                // setLivelloC(chipGreen);
                break;
              }
            }
          } else {
            setLivelloS("");
            setLivelloC(chipGreen);
          }
        } else if (tenantTypeId === 3){
          const autoValAvvResponse = await apiAutovalAvvByTenantLatest.fetch({
            tenantId : tenantId,
            aziendaId : aziendaId
          })

          let item
          if(autoValAvvResponse.data.errors !== undefined){
            item = []
          } else {
            item = autoValAvvResponse.data.data.autovalutazione_avvocati;
          }

          if(item.length > 0){
            setLivelloS(`Ultima Autovalutazione presente del ${new Date(item[0].CreatedOn).toLocaleDateString()}`)
            setLivelloC(chipGreen)
          } else {
            setLivelloS("Nessuna Autovalutazione presente per lo sutdio")
            setLivelloC(chipRed)
          }
        } 

        //Recupero il responsabile antiriciclaggio
        const userRespResponse = await apiUserRespAntiRic.fetch({
          tenantId: tenantId,
        });
        const resp = userRespResponse.data.data.user_tenant;
        if (resp.length > 0) {
          const item = resp.filter(user => user.user.user_roles.some(role => role.Role === "responsabile_antiriciclaggio" && (new Date(role.DateEnd) > new Date() || role.DateEnd === null)))[0]
          if(item !== undefined){
            const userR = new userModel(
              item.UserId,
              item.TenantId,
              item.user.Username,
              item.user.Email,
              item.user.Name,
              item.user.Surname,
              item.user.Name + " " + item.user.Surname,
              item.Type,
              item.user.Locale,
              item.TenantTipoId,
              item.user.user_roles.filter(p=>p.Role === "responsabile_antiriciclaggio").length > 0,
              item.base_tipo_tenant.Value,
              item.user.IsActive,
              item.user.IsDeleted,
              item.user.IsBanned,
              item.user.CreatedOn,
              item.user.Cf,
              item.user.LastVersion,
              item.user.IsEnabledToCreateLawyers,
              item.user.user_roles.length > 0 ? new Date(item.user.user_roles[0].DateStart) <= new Date() && (item.user.user_roles[0].DateEnd !== null ? new Date(item.user.user_roles[0].DateEnd) >= new Date() : true) : false,
              item.user.user_roles.length > 0 ? item.user.user_roles[0].DateStart : "",
              item.user.user_roles.length > 0 ? item.user.user_roles[0].DateEnd : "",
              item.user.user_roles.length > 0 ? item.user.user_roles[0].Role === "responsabile_antiriciclaggio" ? "Responsabile antiriciclaggio" : "Revisore indipendente" : "Nessuno"
            );          
            if(userR.isRespAntiRic && userR.roleActive){
              setUserRespAntiricName(userR.completeName);
            }
          }
        }

        //Recupero le notifiche per il Tenant
        const notificheResponse = await apiNotificationNotReaded.fetch({
          tenantId: tenantId,
          type: "anagrafica.update",
        });
        const notifche = notificheResponse.data.data.notification;
        let arrNotifiche = [];
        notifche.forEach((item) => {
          arrNotifiche.push(
            new notificationModel(
              item.Id,
              item.TenantId,
              item.AziendaId,
              item.UserId,
              item.Type,
              item.Payload,
              item.IsRead,
              item.ReadOn,
              item.ReadByUser,
              item.CreatedOn
            )
          );
        });
        setNotificationList(arrNotifiche);

        //Recupero le countries
        const tbPaeseResponse = await apiBasePaese.fetch();
        let arrPaeseItems = [];
        tbPaeseResponse.data.data.base_paese.map((item) =>
          arrPaeseItems.push(
            new basePaeseModel(
              item.Id,
              item.Nome,
              item.Identificativo,
              item.IsRischio
            )
          )
        );

        //Recupero le province
        const tbProvinceResponse = await apiBaseProvincia.fetch();
        let arrProvinceItems = [];
        tbProvinceResponse.data.data.base_provincia.map((item) =>
          arrProvinceItems.push(
            new baseProvinciaModel(item.Id, item.Nome, item.Sigla, item.Rischio)
          )
        );

        /**************************************/
        //Last Activity
        const lastActivityResponse = await apiUserLastActivity.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          userId: loggedUserId,
        });
        if (lastActivityResponse.data.data.user_lastactivity.length > 0) {
          const item = lastActivityResponse.data.data.user_lastactivity[0];
          let area = "";
          switch (item.Path) {
            case pathAziendaHome: {
              area = "Gestione Aziende";
              break;
            }
            case pathAziendaAdd: {
              area = "Gestione Azienda";
              break;
            }
            case pathFascicoloHome: {
              area = "Gestione Fascicoli";
              break;
            }
            case pathFascicoloAdd: {
              area = "Gestione Fascicolo";
              break;
            }
            case pathAnagraficaHome: {
              area = "Gestione Anagrafiche";
              break;
            }
            case pathPrestazioneHome: {
              area = "Gestione Prestazioni";
              break;
            }
            case pathRischioHome: {
              area = "Gestione Valutazioni rischio";
              break;
            }
            case pathAutoValutazioneHome: {
              area = "Gestione Autovalutazioni del rischio";
              break;
            }
            case pathAutoValutazioneAdd: {
              area = "Gestione Autovalutazione del rischio";
              break;
            }
            case pathSegnalazioneHome: {
              area = "Gestione Segnalazioni di operazione sospetta";
              break;
            }
            case pathNormativaHome: {
              area = "Consultazione Normativa";
              break;
            }
            case pathImpTabBaseHome: {
              area = "Gestione Tabelle di base";
              break;
            }
            case pathImpUtenteHome: {
              area = "Gestione Utenti";
              break;
            }
            case pathImpAuditHome: {
              area = "Consultazione Audit";
              break;
            }
            case pathReleaseNotesHome : {
              area = "Consultazione Note di Rilascio"
              break;
            }
            case pathExportDatiHome : {
              area = "Export dati"
              break;
            }
            case pathNominationHome : {
              area = "Nomina dei ruoli"
              break;
            }
            default:
              break;
          }
          setLastActivity((prevState) => ({
            ...prevState,
            path: item.Path,
            entityId: item.EntityId,
            entityName: item.EntityName,
            area: area,
          }));
        }

        /**************************************/
        //Stats
        //Rischio geografico anagrafiche
        const statsRischioAnagraficheResponse =
          await apiRischioAnagraficheStats.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            userId: loggedUserId,
          });
        let statsArrRischioAnagrafiche = [];
        let nRischioAnagrafiche = 0;
        let rischioGeo1 = 0;
        let rischioGeo2 = 0;
        let rischioGeo3 = 0;
        let rischioGeo4 = 0;

        for (
          let i = 0;
          i < statsRischioAnagraficheResponse.data.data.anagrafica.length;
          i++
        ) {
          const item = statsRischioAnagraficheResponse.data.data.anagrafica[i];
          if (item.AddrPaeseId !== null && item.AddrPaeseId > 0) {
            const country = arrPaeseItems.filter(
              (x) => x.id === item.AddrPaeseId
            )[0];
            if (country.identificativo === "ITA") {
              if (item.AddrProvinciaId > 0) {
                const provincia = arrProvinceItems.filter(
                  (x) => x.id === item.AddrProvinciaId
                )[0];
                switch (provincia.rischio) {
                  case 1: {
                    rischioGeo1 = rischioGeo1 + 1;
                    break;
                  }
                  case 2: {
                    rischioGeo2 = rischioGeo2 + 1;
                    break;
                  }
                  case 3: {
                    rischioGeo3 = rischioGeo3 + 1;
                    break;
                  }
                  case 4: {
                    rischioGeo4 = rischioGeo4 + 1;
                    break;
                  }
                  default:
                    break;
                }
              }
            } else {
              rischioGeo4 = rischioGeo4 + 1;
            }
          }
        }
        nRischioAnagrafiche =
          statsRischioAnagraficheResponse.data.data.anagrafica.length;
        setEntityCounters((prevState) => ({
          ...prevState,
          fascicoli: nRischioAnagrafiche,
        }));

        const rischioGeo1Perc = parseFloat(
          ((rischioGeo1 / nRischioAnagrafiche) * 100).toFixed(2)
        );
        const rischioGeo2Perc = parseFloat(
          ((rischioGeo2 / nRischioAnagrafiche) * 100).toFixed(2)
        );
        const rischioGeo3Perc = parseFloat(
          ((rischioGeo3 / nRischioAnagrafiche) * 100).toFixed(2)
        );
        const rischioGeo4Perc = parseFloat(
          ((rischioGeo4 / nRischioAnagrafiche) * 100).toFixed(2)
        );

        if (!isNaN(rischioGeo1Perc) && rischioGeo1Perc > 0) {
          statsArrRischioAnagrafiche.push({
            name: t("valutazione.form.rischioInerenteLivello1"),
            value: rischioGeo1Perc,
            fill: "#09822A",
          });
        }
        if (!isNaN(rischioGeo2Perc) && rischioGeo2Perc > 0) {
          statsArrRischioAnagrafiche.push({
            name: t("valutazione.form.rischioInerenteLivello2"),
            value: rischioGeo2Perc,
            fill: "#FDB927",
          });
        }
        if (!isNaN(rischioGeo3Perc) && rischioGeo3Perc > 0) {
          statsArrRischioAnagrafiche.push({
            name: t("valutazione.form.rischioInerenteLivello3"),
            value: rischioGeo3Perc,
            fill: "#FC4E3D",
          });
        }
        if (!isNaN(rischioGeo4Perc) && rischioGeo4Perc > 0) {
          statsArrRischioAnagrafiche.push({
            name: t("valutazione.form.rischioInerenteLivello4"),
            value: rischioGeo4Perc,
            fill: "#D82829",
          });
        }

        if (
          !isNaN(rischioGeo1Perc) &&
          !isNaN(rischioGeo2Perc) &&
          !isNaN(rischioGeo3Perc) &&
          !isNaN(rischioGeo4Perc) &&
          nRischioAnagrafiche > 0
        ) {
          setStatsFascicoliData(statsArrRischioAnagrafiche);
        }

        //PPE
        const statsPPEAnagraficheResponse = await apiPPEAnagraficheStats.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          userId: loggedUserId,
        });
        let statsArrPPEAnagrafiche = [];
        let nPPEAnagrafiche = 0;
        let ppe1 = 0;
        let ppe2 = 0;
        let ppe3 = 0;
        for (
          let i = 0;
          i < statsPPEAnagraficheResponse.data.data.anagrafica.length;
          i++
        ) {
          const item = statsPPEAnagraficheResponse.data.data.anagrafica[i];
          if (item.IsPep) {
            ppe1 = ppe1 + 1;
          } else {
            if (!item.IsNotPepMoreThanYear) {
              ppe2 = ppe2 + 1;
            } else if (item.IsNotPepMoreThanYear) {
              ppe3 = ppe3 + 1;
            }
          }
        }
        nPPEAnagrafiche =
          statsPPEAnagraficheResponse.data.data.anagrafica.length;
        setEntityCounters((prevState) => ({
          ...prevState,
          anagrafiche: nPPEAnagrafiche,
        }));

        const pep1Perc = parseFloat(
          ((ppe1 / nPPEAnagrafiche) * 100).toFixed(2)
        );
        const pep2Perc = parseFloat(
          ((ppe2 / nPPEAnagrafiche) * 100).toFixed(2)
        );
        const pep3Perc = parseFloat(
          ((ppe3 / nPPEAnagrafiche) * 100).toFixed(2)
        );

        if (!isNaN(pep2Perc) && pep2Perc > 0) {
          statsArrPPEAnagrafiche.push({
            name: "Non è una PPE",
            value: pep2Perc,
            fill: "#09822A",
          });
        }
        if (!isNaN(pep1Perc) && pep1Perc > 0) {
          statsArrPPEAnagrafiche.push({
            name: "E' una PPE",
            value: pep1Perc,
            fill: "#D82829",
          });
        }
        if (!isNaN(pep3Perc) && pep3Perc > 0) {
          statsArrPPEAnagrafiche.push({
            name: "Non è una PPE da più di un anno",
            value: pep3Perc,
            fill: "#FC4E3D",
          });
        }

        if (
          !isNaN(pep1Perc) &&
          !isNaN(pep2Perc) &&
          !isNaN(pep3Perc) &&
          nPPEAnagrafiche > 0
        ) {
          setStatsPfPgData(statsArrPPEAnagrafiche);
        }

        //Prestazioni
        const statsPrestResponse = await apiPrestByTenantPrestStats.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          userId: loggedUserId,
        });
        let statsArrPrest = [];
        let nRischioPrest = 0;
        let rischioPrest1 = 0;
        let rischioPrest2 = 0;
        let rischioPrest3 = 0;
        let rischioPrest4 = 0;
        for (
          let i = 0;
          i < statsPrestResponse.data.data.prestazione.length;
          i++
        ) {
          const item = statsPrestResponse.data.data.prestazione[i];

          switch (item.base_tipo_prestazione.Rischio) {
            case 1: {
              rischioPrest1 = rischioPrest1 + 1;
              break;
            }
            case 2: {
              rischioPrest2 = rischioPrest2 + 1;
              break;
            }
            case 3: {
              rischioPrest3 = rischioPrest3 + 1;
              break;
            }
            case 4: {
              rischioPrest4 = rischioPrest4 + 1;
              break;
            }
            default:
              break;
          }
        }

        const nRischioPrestResp = await apiPrestByTenantCount.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          userId: loggedUserId,
        });
        nRischioPrest =
          nRischioPrestResp.data.data.prestazione_aggregate.aggregate.count;
        setEntityCounters((prevState) => ({
          ...prevState,
          prestazioni: nRischioPrest,
        }));

        const rischioPrest1Perc = parseFloat(
          ((rischioPrest1 / nRischioPrest) * 100).toFixed(2)
        );
        const rischioPrest2Perc = parseFloat(
          ((rischioPrest2 / nRischioPrest) * 100).toFixed(2)
        );
        const rischioPrest3Perc = parseFloat(
          ((rischioPrest3 / nRischioPrest) * 100).toFixed(2)
        );
        const rischioPrest4Perc = parseFloat(
          ((rischioPrest4 / nRischioPrest) * 100).toFixed(2)
        );

        if (!isNaN(rischioPrest1Perc) && rischioPrest1Perc > 0) {
          statsArrPrest.push({
            name: t("valutazione.form.rischioInerenteLivello1"),
            value: rischioPrest1Perc,
            fill: "#09822A",
          });
        }
        if (!isNaN(rischioPrest2Perc) && rischioPrest2Perc > 0) {
          statsArrPrest.push({
            name: t("valutazione.form.rischioInerenteLivello2"),
            value: rischioPrest2Perc,
            fill: "#FDB927",
          });
        }
        if (!isNaN(rischioPrest3Perc) && rischioPrest3Perc > 0) {
          statsArrPrest.push({
            name: t("valutazione.form.rischioInerenteLivello3"),
            value: rischioPrest3Perc,
            fill: "#FC4E3D",
          });
        }
        if (!isNaN(rischioPrest4Perc) && rischioPrest4Perc > 0) {
          statsArrPrest.push({
            name: t("valutazione.form.rischioInerenteLivello4"),
            value: rischioPrest4Perc,
            fill: "#D82829",
          });
        }

        if (
          !isNaN(rischioPrest1Perc) &&
          !isNaN(rischioPrest2Perc) &&
          !isNaN(rischioPrest3Perc) &&
          !isNaN(rischioPrest4Perc) &&
          nRischioPrest > 0
        ) {
          setStatsPrestData(statsArrPrest);
        }

        //Rischi
        const statsRischiResponse = await apiRischioByTenantStats.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          userId: loggedUserId,
        });
        let statsArrRischi = [];
        let nRischioEff = 0;
        let rischioEff1 = 0;
        let rischioEff2 = 0;
        let rischioEff3 = 0;
        let rischioEff4 = 0;
        for (let i = 0; i < statsRischiResponse.data.data.rischio.length; i++) {
          const item = statsRischiResponse.data.data.rischio[i];

          if (item.EfTotale >= 1 && item.EfTotale <= 1.5) {
            rischioEff1 = rischioEff1 + 1;
          } else if (item.EfTotale > 1.5 && item.EfTotale <= 2.5) {
            rischioEff2 = rischioEff2 + 1;
          } else if (item.EfTotale > 2.5 && item.EfTotale <= 3.5) {
            rischioEff3 = rischioEff3 + 1;
          } else if (item.EfTotale > 3.5) {
            rischioEff4 = rischioEff4 + 1;
          }
        }
        nRischioEff = statsRischiResponse.data.data.rischio.length;
        setEntityCounters((prevState) => ({
          ...prevState,
          valutazioni: nRischioEff,
        }));

        const rischioEff1Perc = parseFloat(
          ((rischioEff1 / nRischioEff) * 100).toFixed(2)
        );
        const rischioEff2Perc = parseFloat(
          ((rischioEff2 / nRischioEff) * 100).toFixed(2)
        );
        const rischioEff3Perc = parseFloat(
          ((rischioEff3 / nRischioEff) * 100).toFixed(2)
        );
        const rischioEff4Perc = parseFloat(
          ((rischioEff4 / nRischioEff) * 100).toFixed(2)
        );

        if (!isNaN(rischioEff1Perc) && rischioEff1Perc > 0) {
          statsArrRischi.push({
            name: t("valutazione.form.rischioInerenteLivello1"),
            value: rischioEff1Perc,
            fill: "#09822A",
          });
        }
        if (!isNaN(rischioEff2Perc) && rischioEff2Perc > 0) {
          statsArrRischi.push({
            name: t("valutazione.form.rischioInerenteLivello2"),
            value: rischioEff2Perc,
            fill: "#FDB927",
          });
        }
        if (!isNaN(rischioEff3Perc) && rischioEff3Perc > 0) {
          statsArrRischi.push({
            name: t("valutazione.form.rischioInerenteLivello3"),
            value: rischioEff3Perc,
            fill: "#FC4E3D",
          });
        }
        if (!isNaN(rischioEff4Perc) && rischioEff4Perc > 0) {
          statsArrRischi.push({
            name: t("valutazione.form.rischioInerenteLivello4"),
            value: rischioEff4Perc,
            fill: "#D82829",
          });
        }

        if (
          !isNaN(rischioEff1Perc) &&
          !isNaN(rischioEff2Perc) &&
          !isNaN(rischioEff3Perc) &&
          !isNaN(rischioEff4Perc) &&
          nRischioEff > 0
        ) {
          setStatsRischiData(statsArrRischi);
        }

        //Anagrafiche con documento in scadenza
        const dtTo = new Date(new Date().setMonth(new Date().getMonth() + 1));
        const anagDocInScadResponse = await apiAnagDocInScadenza.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          userId: loggedUserId,
        });
        let anagDocInScadArr = [];

        for (
          let i = 0;
          i < anagDocInScadResponse.data.data.anagrafica.length;
          i++
        ) {
          const item = anagDocInScadResponse.data.data.anagrafica[i];
          const dtDocScad =
            item.anagrafica_identificaziones.length > 0
              ? item.anagrafica_identificaziones[0].DocScad
              : null;
          const fascicolo =
            item.anagrafica_fascicolos.length > 0
              ? item.anagrafica_fascicolos[0].fascicolo
              : null;
          let fascicoloId = 0;
          let fascicoloName = "";
          if (fascicolo !== null) {
            fascicoloId = fascicolo.Id;
            fascicoloName = fascicolo.Name;
          }

          //let isScaduto = new Date(dtDocScad).setHours(0,0,0,0) >= new Date().setHours(0,0,0,0) && new Date(dtDocScad).setHours(0,0,0,0) <= new Date(dtTo).setHours(0,0,0,0);
          let isScaduto =
            new Date(dtDocScad).setHours(0, 0, 0, 0) <=
            new Date(dtTo).setHours(0, 0, 0, 0);

          if (isScaduto) {
            const itemModel = {
              id: item.Id,
              fascicoloId: fascicoloId,
              fascicoloName: fascicoloName,
              denominazione: item.Denominazione,
              date: dtDocScad,
              scaduto: isScaduto,
            };
            anagDocInScadArr.push(itemModel);
          }
        }

        setAnagDocInScadList(anagDocInScadArr);
        setAnagDocInScadListColumns([
          { field: "id", headerName: "Id", flex: 0.2, type: "number" },
          {
            field: "date",
            headerName: "Scade il",
            flex: 0.5,
            valueFormatter: (params) => {
              if (params.value == null) {
                return "";
              }
              return new Date(params.value).toLocaleDateString();
            },
          },
          //{ field: "fascicoloName", headerName: "Fascicolo", flex: 0.4 },
          {
            field: "denominazione",
            headerName: "Denominazione",
            flex: 0.8,
            editable: false,
          },
          {
            field: "scaduto",
            headerName: "Scade tra",
            flex: 0.8,
            editable: false,
            renderCell: (params) => renderColorCell(params.row.date),
            valueGetter: (params) => renderColorCellGetter(params.row.date),
          },
          {
            field: "actions",
            headerName: "",
            flex: 0.1,
            filterable: false,
            renderCell: (params) =>
              renderAnagDocInScadDetailsButton(params.row),
          },
        ]);

        //Recupero i rischi la cui data di controllo costante scade tra un mese o meno
        const rischiResponse = await apiRischiByDataControlloCostante.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          userId: loggedUserId,
          dtFrom: new Date(1970,1,1),
          dtTo: new Date(new Date().setMonth(new Date().getMonth() + 6)),
        });
        const rischi = rischiResponse.data.data.rischio;
        let arrRischi = [];
        for (let i = 0; i < rischi.length; i++) {
          const item = rischi[i];
          const itemModel = {
            id: item.Id,
            fascicoloId: item.prestazione.fascicolo.Id,
            fascicoloName: item.prestazione.fascicolo.Name,
            prestazioneId: item.PrestazioneId,
            date: item.DataControlloCostante,
          };
          arrRischi.push(itemModel);
        }
        setRischiList(arrRischi);
        setRischiColumns([
          { field: "id", headerName: "Id", type: "number", flex: 0.2 },
          {
            field: "date",
            headerName: "Prossima verifica",
            flex: 0.5,
            valueFormatter: (params) => {
              if (params.value == null) {
                return "";
              }
              return new Date(params.value).toLocaleDateString();
            },
          },
          { field: "fascicoloName", headerName: "Nome", flex: 1 },
          {
            field: "scaduto",
            headerName: "Scade tra",
            flex: 0.8,
            editable: false,
            renderCell: (params) => renderColorCell(params.row.date),
            valueGetter : (params) => renderColorCellGetter(params.row.date)
          },
          {
            field: "actions",
            headerName: "",
            flex: 0.2,
            filterable: false,
            renderCell: (params) => renderRischiDetailsButton(params.row),
          },
        ]);

        //Recupero le autovalutazioni in scadenza
        let responseDatas = null
        if(tenantTypeId !== 3){
          const autovalResponse = await apiAutovalutazioneByExpire.fetch({
            tenantId : tenantId,
            aziendaId : aziendaId,
            dtFrom: new Date(),
            dtTo : new Date(new Date().setMonth(new Date().getMonth() + 6)),
          })
          responseDatas = autovalResponse.data.data.autovalutazione
        } else if (tenantTypeId === 3){
          const autoValAvvResponse = await apiAutovalutazioneAvvByExpire.fetch({
            tenantId : tenantId,
            aziendaId : aziendaId,
            dtFrom: new Date(),
            dtTo : new Date(new Date().setMonth(new Date().getMonth() + 6)),
          })
          responseDatas = autoValAvvResponse.data.data.autovalutazione_avvocati
        }
        let autovalArr = []
        if(responseDatas !== null && responseDatas.length > 0){
          responseDatas.forEach(async (autoval)=>{
            let retUser = await apiUserById.fetch({
              userId : tenantTypeId === 3 ? autoval.ProvdedBy : autoval.ProvidedBy
            })
            let user = ""
            if(retUser.data.data.user.length > 0){
              user = retUser.data.data.user[0].Name + " " + retUser.data.data.user[0].Surname
            }
            let itemModel = {
              id : autoval.Id,
              creationDate : autoval.Date,
              expireDate : autoval.ExpireDate,
              providedBy : user,
            }
            autovalArr.push(itemModel)
          })
          setAutovalList(autovalArr)
          setAutovalColumns([
            { field: "id", headerName: "Id", type: "number", flex: 0.2},
            {
              field: "creationDate",
              headerName: "Data di creazione",
              flex: 0.5,
              valueFormatter: (params) => {
                if (params.value == null) {
                  return "";
                }
                return new Date(params.value).toLocaleDateString();
              },
            },
            {
              field: "expireDate",
              headerName: "Data scadenza",
              flex: 0.5,
              valueFormatter: (params) => {
                if (params.value == null) {
                  return "";
                }
                return new Date(params.value).toLocaleDateString();
              },
            },
            {
              field:"providedBy",
              headerName: "Autore autovalutazione",
              flex:0.5,           
            },
            {
              field: "scaduto",
              headerName: "Scade tra",
              flex: 0.8,
              editable: false,
              renderCell: (params) => renderColorCell(params.row.expireDate),
              valueGetter : (params) => renderColorCellGetter(params.row.expireDate)
            },      
          ])
        }

        /**************************************/
        //Carico i fascicoli
        const fascicoliResponse = await apiFascicoli.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          userId: loggedUserId,
        });
        setFascicoliTotN(fascicoliResponse.data.data.fascicolo.length);
        let arrItemsIncomplete = [];
        let arrItemsComplete = [];
        for (let i = 0; i < fascicoliResponse.data.data.fascicolo.length; i++) {
          const item = fascicoliResponse.data.data.fascicolo[i];
          const status = await getFascicoloStatus(item);
          const itemModel = new fascicoloModel(
            item.Id,
            item.TenantId,
            item.AziendaId,
            item.Name,
            item.Date,
            item.IsPrivate,
            item.CreatedOn,
            item.ModifiedOn,
            [],
            status,
            "",
            item.CreatedBy,
            item.ModifiedBy
          );
          if (!status.status) {
            arrItemsIncomplete.push(itemModel);
          } else {
            arrItemsComplete.push(itemModel)
          }
        }
        let topItems = arrItemsIncomplete.filter(fascicolo => fascicolo.status.reasons.includes("Cliente non presente"))
        let bottomItems = arrItemsIncomplete.filter(fascicolo => !fascicolo.status.reasons.includes("Cliente non presente"))
        let newArray = topItems.concat(bottomItems)
        setFascicoliIncompleteList(newArray)
        setFascicoliIncompleteColumns([
          {
            field: "id",
            headerName: "Id",
            type: "number",
            flex: 0.2
          },
          {
            field: "date",
            headerName: t("autovalutazione.form.data"),
            flex: 0.5,
            valueFormatter: (params) => {
              if (params.value == null) {
                return "";
              }
              return new Date(params.value).toLocaleDateString();
            }
          },
          { field:"status", headerName: "Status", flex : 1, valueFormatter: (params) => params.value.reasons.includes("Cliente non presente") ?  "Cliente mancante, eliminare il fascicolo": "Incompleto" },
          { field: "name", headerName: "Intestazione", flex: 1},
          {
            field: "actions",
            headerName: "",
            flex: 0.2,
            filterable: false,
            renderCell: (params) =>
              renderFascicoliIncompletiDetailsButton(params.row),
          },
        ]);

        setFascicoliCompleteList(arrItemsComplete)
        setFascicoliCompleteColumns([
          {
            field: "id",
            headerName: "Id",
            type: "number",
            flex: 0.2
          },
          {
            field: "date",
            headerName: t("autovalutazione.form.data"),
            flex: 0.5,
            valueFormatter: (params) => {
              if (params.value == null) {
                return "";
              }
              return new Date(params.value).toLocaleDateString();
            }
          },
          { field:"status", headerName: "Status", flex : 1, valueFormatter: (params) => params.value.reasons.includes("Cliente non presente") ?  "Cliente mancante, eliminare il fascicolo": "Completo" },
          { field: "name", headerName: "Intestazione", flex: 1},
          {
            field: "actions",
            headerName: "",
            flex: 0.2,
            filterable: false,
            renderCell: (params) =>
              renderFascicoliIncompletiDetailsButton(params.row),
          },
        ])
        /**************************************/
        //Antiriciclaggio Desktop
        try {
          const tsARicDesktopResponse = await tsApiARicDesktop.fetch({
            url:
              "aml:aric-desktop:licensing://info_licenza.cfm?magic=12345&piva=" +
              sharedDatas.getPropertyByName("tenantModel").vatNumber,
          });
          setIsARicDesktopActive(tsARicDesktopResponse.data.includes("OK"));
          //setIsARicDesktopActive(true);
        } catch (e) {
          AddLog(e.message,e.stack,"loadDatas:aRicDesktop")               
        }

        //Verifico la versione corrente e notifico l'utente se sta utilizzando una versione vecchia
        //Carico la versione e i relativi controlli
        let responseMessage = await getSystemMessage.fetch()
        if(responseMessage.data.data.system_message.length > 0){
            setMessage(responseMessage.data.data.system_message[0])
        }
        if(responseMessage.data.data.system_message[0].Version !== info.version){
          setIsVersionCorrect(false)
        }

        setIsDataLoaded(true);
        setBackDropOpen(false);
      }
    } catch (e) {
      AddLog(e.message,e.stack,"loadDatas")           
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };
  //#endregion

  //#region Renders
  const renderColorCell = (date)=>{
    let currentDate = new Date()
    let monthsDiff = monthDiff(currentDate,new Date(date))
    if(monthsDiff > 3){ //Verde
      return <VaporTag variant='standard' label='Scade entro 6 mesi' sx={chipGreen} />;
    } else if (monthsDiff > 1 && monthsDiff <= 3){ //Giallo
      return <VaporTag variant='standard' label='Scade entro 3 mesi' sx={chipYellow} />;
    } else if (monthsDiff === 0 || monthsDiff === 1){ //Arancione
      return <VaporTag variant='standard' label='Scade entro 1 mese' sx={chipOrange} />;
    } else if (monthsDiff < 0){//Rosso
      return <VaporTag variant='standard' label='Scaduto' sx={chipRed} />;
    }
  }
  const renderColorCellGetter = (date) => {
    let currentDate = new Date()
    let monthsDiff = monthDiff(currentDate,new Date(date))
    if( monthsDiff > 3){ //Verde
      return "Scade entro 6 mesi";
    } else if (monthsDiff > 1 && monthsDiff <= 3){ //Giallo
      return "Scade entro 3 mesi";
    } else if (monthsDiff === 0 || monthsDiff === 1){ //Rosso
      return "Scade entro 1 mese"
    } else if (monthsDiff < 0){
      return "Scaduto"
    }
  }
  const renderAnagDocInScadDetailsButton = (item) => {
    return (
      <IconButton
        color='primary'
        onClick={(e) => handleGoToAnagrafica(e, item)}
      >
        <ArrowForwardIcon />
      </IconButton>
    );
  };
  const renderRischiDetailsButton = (item) => {
    return (
      <IconButton color='primary' onClick={(e) => handleGoToRischio(e, item)}>
        <ArrowForwardIcon />
      </IconButton>
    );
  };
  const renderFascicoliIncompletiDetailsButton = (item) => {
    return (
      <IconButton color='primary' onClick={(e) => handleGoToFascicolo(e, item)}>
        <ArrowForwardIcon />
      </IconButton>
    );
  };
  //#endregion

  //#region Handler
  // const  handleTest = async ()=>{
  //   navigate(pathNominationHome)
  // }
  const handleFascicoloHome = () => {
    navigate(pathAnagraficaHome);
  };
  const handleAnagraficaHome = () => {
    navigate(pathAnagraficaHome);
  };
  const handlePrestazioneHome = () => {
    navigate(pathPrestazioneHome);
  };
  const handleValutazioneHome = () => {
    navigate(pathRischioHome);
  };
  const handleImportDesktop = (type) => {
    setLocalStorageImportType(type);
    navigate(pathAnagraficaImportDesktop);
  };
  const handleLastActivityClick = () => {
    if (lastActivity.path === pathAziendaHome) {
      navigate(pathAziendaHome);
    } else if (lastActivity.path === pathAziendaAdd) {
      setLocalStorageSelectedAzienda(parseInt(lastActivity.entityId));
      navigate(pathAziendaAdd);
    } else if (lastActivity.path === pathFascicoloHome) {
      navigate(pathFascicoloHome);
    } else if (lastActivity.path === pathFascicoloAdd) {
      setLocalStorageSelectedFascicolo(parseInt(lastActivity.entityId));
      navigate(pathFascicoloAdd);
    } else if (lastActivity.path === pathAnagraficaHome) {
      navigate(pathAnagraficaHome);
    } else if (lastActivity.path === pathPrestazioneHome) {
      navigate(pathPrestazioneHome);
    } else if (lastActivity.path === pathRischioHome) {
      navigate(pathRischioHome);
    } else if (lastActivity.path === pathAutoValutazioneHome) {
      navigate(pathAutoValutazioneHome);
    } else if (lastActivity.path === pathAutoValutazioneAdd) {
      setLocalStorageSelectedAutovalutazione(parseInt(lastActivity.entityId));
      navigate(pathAutoValutazioneAdd);
    } else if (lastActivity.path === pathSegnalazioneHome) {
      navigate(pathSegnalazioneHome);
    } else if (lastActivity.path === pathNormativaHome) {
      navigate(pathNormativaHome);
    } else if (lastActivity.path === pathImpTabBaseHome) {
      navigate(pathImpTabBaseHome);
    } else if (lastActivity.path === pathImpUtenteHome) {
      navigate(pathImpUtenteHome);
    } else if (lastActivity.path === pathImpAuditHome) {
      navigate(pathImpAuditHome);
    } else if (lastActivity.path === pathReleaseNotesHome) {
      navigate(pathReleaseNotesHome)
    } else if (lastActivity.path === pathExportDatiHome){
      navigate(pathExportDatiHome)
    } else if (lastActivity.path === pathNominationHome){
      navigate(pathNominationHome)
    }
  };
  const handleGoToAnagrafica = async (event, item) => {
    try {
      event.stopPropagation();
      setLocalStorageSelectedFascicolo(parseInt(item.fascicoloId));
      setLocalStorageSelectedEntity("anagrafica", item.id);
      navigate(pathFascicoloAdd);
    } catch (e) {
      AddLog(e.message,e.stack,"handleGoToAnagrafica")           
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };
  const handleGoToRischio = async (event, item) => {
    try {
      event.stopPropagation();
      setLocalStorageSelectedFascicolo(parseInt(item.fascicoloId));
      setLocalStorageSelectedEntity("rischio", item.id);
      navigate(pathFascicoloAdd);
    } catch (e) {
      AddLog(e.message,e.stack,"handleGoToAnagrafica")           
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };
  const handleGoToFascicolo = async (event, item) => {
    try {
      event.stopPropagation();
      setLocalStorageSelectedFascicolo(parseInt(item.id));
      navigate(pathFascicoloAdd);
    } catch (e) {
      AddLog(e.message,e.stack,"handleGoToFascicolo")           
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };
  //#endregion

  //#region Tabs
  const [selectedScadTab, setSelectedScadTab] = useState("tabDocumenti");
  const handleChangeScadTab = (event, newValue) => {
    setSelectedScadTab(newValue);
  };
  const [selectedIncompleteTab,setSelectedIncompleteTab] = useState("tabIncomplete");
  const handleChangeIncompleteTab = (event,newValue) => {
    setSelectedIncompleteTab(newValue)
  }
  //#endregion

  //#region Modal Version
  const handleModalVersionClose = () =>{
    setIsLastVersionModalShow(false)
  }
  const handleModalVersionOk = async () => {
    let currentUserModel = sharedDatas.getPropertyByName("userModel")
    let currentVersion = sharedDatas.getPropertyByName("version")

    await apiUpdateUser.fetch({
      tenantId: currentUserModel.tenantId,
      userId: currentUserModel.userId,
      name: currentUserModel.name,
      surname: currentUserModel.surname,
      isRespAntiRic: currentUserModel.isRespAntiRic,
      isActive: currentUserModel.isActive,
      isDeleted: currentUserModel.isDeleted,
      isBanned: currentUserModel.isBanned,
      createdOn: currentUserModel.createdOn,
      cf: currentUserModel.cf,
      lastVersion : currentVersion
    })


    setIsLastVersionModalShow(false)
  }
  const handleModalVersionErrorOk = async () => {
    setIsVersionCorrect(true)
  }
  //#endregion

  //#region Return graphics
  return (
    <Fragment>

      {isLastVersionModalShow &&
        <ModalVersion
          show={isLastVersionModalShow}
          onClose={handleModalVersionClose}
          onOk={handleModalVersionOk}
        />
      }

      {!isVersionCorrect &&
        <ModalErrorVersion
          messageFromParent={message}
          show={!isVersionCorrect}         
          onOk={handleModalVersionErrorOk}
        />
      }

      <Loading open={backDropOpen} />

      {isUserLoaded && isDataLoaded ? (
        <Stack
          direction='column'
          spacing={2}
          sx={{
            p: 2,
            height: "100% !important",
            background: "hsl(199, 15%, 95%)",
          }}
        >
          <Stack direction='row' spacing={2}>
            <Card sx={{ width: "100%" }}>
              <CardContent sx={{ p: 0, pb: "0 !important" }}>
                <Stack
                  direction='row'
                  justifyContent='center'
                  alignItems='center'
                >
                  <Stack direction='column' sx={{ p: 4 }} spacing={2}>
                    <ExtendedTypography
                      variant='titleMedium'
                      color='primary.main'
                    >
                      {t("dashboard.welcome")} {userName}
                    </ExtendedTypography>
                    {lastActivity.path !== "" ? (
                      <Fragment>
                        <ExtendedTypography variant='p' color='text.secondary'>
                          {t("dashboard.resume")} {lastActivity.area}
                          {lastActivity.path === pathAziendaAdd ? " " : null}
                          {lastActivity.path === pathFascicoloAdd
                            ? " di "
                            : null}
                          {lastActivity.path === pathAutoValutazioneAdd
                            ? " del "
                            : null}
                          {lastActivity.entityName}?
                        </ExtendedTypography>
                        <Button
                          variant='contained'
                          size='small'
                          sx={{ width: "200px" }}
                          onClick={handleLastActivityClick}
                        >
                          {t("dashboard.resumeButton")}
                        </Button>
                      </Fragment>
                    ) : null}
                  </Stack>
                  <Box flexGrow='1'></Box>

                    <Stack direction='column' sx={{ p: 1 }} spacing={1}>
                      <VersionAlert messageFromParent={message}/>
                      {/* <Button onClick={handleTest}>Open normativa</Button> */}
                    </Stack>

                  <Stack direction='column' sx={{ p: 4 }} spacing={1}>
                    <ExtendedTypography
                      variant='titleSmall'
                      color='primary.main'
                    >
                      {t("dashboard.amlresp")}
                    </ExtendedTypography>
                    {userRespAntiricName !== "" ? (
                      <VaporTag
                        variant='ghost'
                        type='blueSapphire'
                        label={userRespAntiricName}
                      />
                    ) : null}
                    {userRespAntiricName === "" ? (
                      <VaporTag
                        variant='ghost'
                        type='blueSapphire'
                        label={t("dashboard.label1")}
                      />
                    ) : null}

                    <ExtendedTypography
                      variant='titleSmall'
                      color='primary.main'
                    >
                      {t("dashboard.lvlrisk")}
                    </ExtendedTypography>
                    {livelloS === "" ? (
                      <Fragment>
                        <VaporTag
                          variant='ghost'
                          type='blueSapphire'
                          label={t("dashboard.label2")}
                        />
                      </Fragment>
                    ) : null}
                    {livelloS !== "" ? (
                      <Fragment>
                        <VaporTag
                          variant='standard'
                          label={livelloS}
                          sx={livelloC}
                        />
                      </Fragment>
                    ) : null}
                  </Stack>
                </Stack>
              </CardContent>
            </Card>
          </Stack>

          <Stack
            direction='row'
            spacing={2}
            sx={{ mt: 6, mb: 4, ml: 4, mr: 4 }}
          >
            <Stack direction='column' sx={{ width: tenantTypeId === 3 ? "50%" : "25%" }}>
              <Card>
                <CardContent sx={{ p: 0, pb: "0 !important" }}>
                  <Stack direction='column' sx={{ p: 4 }}>
                    <ExtendedTypography
                      variant='titleSmall'
                      color='primary.main'
                      sx={{ mb: 1 }}
                    >
                      {t("dashboard.georisk")}
                    </ExtendedTypography>
                    {/* <ExtendedTypography variant="body" color="text.secondary">
                        Organizza le attività di Antiriciclaggio per i tuoi clienti in un unico ambiente
                      </ExtendedTypography> */}

                    <ResponsiveContainer
                      width='100%'
                      height={200}
                      minWidth={270}
                    >
                      {statsFascicoliData.length > 0 ? (
                        <PieChart>
                          <Pie
                            data={statsFascicoliData}
                            dataKey='value'
                            nameKey='name'
                            cx='50%'
                            cy='50%'
                            innerRadius={35}
                            outerRadius={50}
                            fill='#5676D4'
                            label={renderCustomizedLabel}
                            isAnimationActive={true}
                          >
                            {statsFascicoliData.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.fill} />
                            ))}
                          </Pie>
                          <Tooltip content={<CustomTooltip />} />
                        </PieChart>
                      ) : (
                        <NoData size='small' />
                      )}
                    </ResponsiveContainer>
                  </Stack>
                </CardContent>
                <CardActions sx={{ p: 0 }}>
                  <Stack
                    direction='row'
                    sx={{
                      width: "100%",
                      p: 1,
                      pl: 4,
                      borderTop: "solid 1px #cccccc",
                    }}
                    alignItems='center'
                  >
                    <Stack direction='column'>
                      <ExtendedTypography
                        variant='bodySmall'
                        color='text.secondary'
                      >
                        {t("dashboard.regTot")}
                      </ExtendedTypography>
                      <ExtendedTypography
                        variant='bodySmall'
                        color='text.secondary'
                        sx={{ fontWeight: "bold" }}
                      >
                        {entityCounters.fascicoli}
                      </ExtendedTypography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton color='primary' onClick={handleFascicoloHome}>
                      <ArrowForwardIcon />
                    </IconButton>
                  </Stack>
                </CardActions>
              </Card>
            </Stack>
            <Stack direction='column' sx={{ width: tenantTypeId === 3 ? "50%" : "25%" }}>
              <Card>
                <CardContent sx={{ p: 0, pb: "0 !important" }}>
                  <Stack direction='column' sx={{ p: 4 }}>
                    <ExtendedTypography
                      variant='titleSmall'
                      color='primary.main'
                      sx={{ mb: 1 }}
                    >
                      {t("dashboard.exposedPeopleTotal")}
                    </ExtendedTypography>
                    {/* <ExtendedTypography variant="body" color="text.secondary">
                        Organizza le attività di Antiriciclaggio per i tuoi clienti in un unico ambiente
                      </ExtendedTypography> */}

                    <ResponsiveContainer
                      width='100%'
                      height={200}
                      minWidth={270}
                    >
                      {statsPfPgData.length > 0 ? (
                        <PieChart>
                          <Pie
                            data={statsPfPgData}
                            dataKey='value'
                            nameKey='name'
                            cx='50%'
                            cy='50%'
                            innerRadius={35}
                            outerRadius={50}
                            fill='#5676D4'
                            label={renderCustomizedLabel}
                            isAnimationActive={true}
                          >
                            {statsPfPgData.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.fill} />
                            ))}
                          </Pie>
                          <Tooltip content={<CustomTooltip />} />
                        </PieChart>
                      ) : (
                        <NoData size='small' />
                      )}
                    </ResponsiveContainer>
                  </Stack>
                </CardContent>
                <CardActions sx={{ p: 0 }}>
                  <Stack
                    direction='row'
                    sx={{
                      width: "100%",
                      p: 1,
                      pl: 4,
                      borderTop: "solid 1px #cccccc",
                    }}
                    alignItems='center'
                  >
                    <Stack direction='column'>
                      <ExtendedTypography
                        variant='bodySmall'
                        color='text.secondary'
                      >
                        {t("dashboard.pfTotal")}
                      </ExtendedTypography>
                      <ExtendedTypography
                        variant='bodySmall'
                        color='text.secondary'
                        sx={{ fontWeight: "bold" }}
                      >
                        {entityCounters.anagrafiche}
                      </ExtendedTypography>
                    </Stack>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton color='primary' onClick={handleAnagraficaHome}>
                      <ArrowForwardIcon />
                    </IconButton>
                  </Stack>
                </CardActions>
              </Card>
            </Stack>
            {tenantTypeId !== 3 && (
                <>
                  <Stack direction='column' sx={{ width: "25%" }}>
                    <Card>
                      <CardContent sx={{ p: 0, pb: "0 !important" }}>
                        <Stack direction='column' sx={{ p: 4, width: "100%" }}>
                          <ExtendedTypography
                            variant='titleSmall'
                            color='primary.main'
                            sx={{ mb: 1 }}
                          >
                            {t("dashboard.perfRisk")}
                          </ExtendedTypography>

                          <ResponsiveContainer
                            width='100%'
                            height={200}
                            minWidth={270}
                          >
                            {statsPrestData.length > 0 ? (
                              <PieChart>
                                <Pie
                                  data={statsPrestData}
                                  dataKey='value'
                                  nameKey='name'
                                  cx='50%'
                                  cy='50%'
                                  innerRadius={35}
                                  outerRadius={50}
                                  fill='#5676D4'
                                  label={renderCustomizedLabel}
                                  isAnimationActive={true}
                                >
                                  {statsPrestData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.fill} />
                                  ))}
                                </Pie>
                                <Tooltip content={<CustomTooltip />} />
                              </PieChart>
                            ) : (
                              <NoData size='small' />
                            )}
                          </ResponsiveContainer>
                        </Stack>
                      </CardContent>
                      <CardActions sx={{ p: 0 }}>
                        <Stack
                          direction='row'
                          sx={{
                            width: "100%",
                            p: 1,
                            pl: 4,
                            borderTop: "solid 1px #cccccc",
                          }}
                          alignItems='center'
                        >
                          <Stack direction='column'>
                            <ExtendedTypography
                              variant='bodySmall'
                              color='text.secondary'
                            >
                              {t("dashboard.perfTotal")}
                            </ExtendedTypography>
                            <ExtendedTypography
                              variant='bodySmall'
                              color='text.secondary'
                              sx={{ fontWeight: "bold" }}
                            >
                              {entityCounters.prestazioni}
                            </ExtendedTypography>
                          </Stack>
                          <Box sx={{ flexGrow: 1 }} />
                          <IconButton color='primary' onClick={handlePrestazioneHome}>
                            <ArrowForwardIcon />
                          </IconButton>
                        </Stack>
                      </CardActions>
                    </Card>
                  </Stack>
                  <Stack direction='column' sx={{ width: "25%" }}>
                    <Card>
                      <CardContent sx={{ p: 0, pb: "0 !important" }}>
                        <Stack direction='column' sx={{ p: 4 }}>
                          <ExtendedTypography
                            variant='titleSmall'
                            color='primary.main'
                            sx={{ mb: 1 }}
                          >
                            {t("dashboard.riskeff")}
                          </ExtendedTypography>
                          <ResponsiveContainer
                            width='100%'
                            height={200}
                            minWidth={270}
                          >
                            {statsRischiData.length > 0 ? (
                              <PieChart>
                                <Pie
                                  data={statsRischiData}
                                  dataKey='value'
                                  nameKey='name'
                                  cx='50%'
                                  cy='50%'
                                  innerRadius={35}
                                  outerRadius={50}
                                  fill='#5676D4'
                                  label={renderCustomizedLabel}
                                  isAnimationActive={true}
                                >
                                  {statsRischiData.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={entry.fill} />
                                  ))}
                                </Pie>
                                <Tooltip content={<CustomTooltip />} />
                              </PieChart>
                            ) : (
                              <NoData size='small' />
                            )}
                          </ResponsiveContainer>
                        </Stack>
                      </CardContent>
                      <CardActions sx={{ p: 0 }}>
                        <Stack
                          direction='row'
                          sx={{
                            width: "100%",
                            p: 1,
                            pl: 4,
                            borderTop: "solid 1px #cccccc",
                          }}
                          alignItems='center'
                        >
                          <Stack direction='column'>
                            <ExtendedTypography
                              variant='bodySmall'
                              color='text.secondary'
                            >
                              {t("dashboard.evalTot")}
                            </ExtendedTypography>
                            <ExtendedTypography
                              variant='bodySmall'
                              color='text.secondary'
                              sx={{ fontWeight: "bold" }}
                            >
                              {entityCounters.valutazioni}
                            </ExtendedTypography>
                          </Stack>
                          <Box sx={{ flexGrow: 1 }} />
                          <IconButton color='primary' onClick={handleValutazioneHome}>
                            <ArrowForwardIcon />
                          </IconButton>
                        </Stack>
                      </CardActions>
                    </Card>
                  </Stack>
                </>
              )}
          </Stack>

          <Card sx={{ width: "100%", minHeight: 160 }}>
            <CardContent sx={{ p: 0, pb: "0 !important" }}>
              <Stack direction='row'>
                <Stack
                  direction='column'
                  sx={{ width: "25%", p: 4 }}
                  spacing={1}
                >
                  <ExtendedTypography variant='titleSmall' color='primary.main'>
                    {t("dashboard.amlDesktop")}
                  </ExtendedTypography>
                  <ExtendedTypography variant='body' color='text.secondary'>
                    {t("dashboard.importDesc")}
                  </ExtendedTypography>
                  <Button
                    variant='contained'
                    size='small'
                    sx={{ width: "200px" }}
                    onClick={() => handleImportDesktop("aricdesktop")}
                    disabled={!isARicDesktopActive}
                  >
                    {t("dashboard.proceed")}
                  </Button>
                </Stack>
                <Stack
                  direction='column'
                  sx={{ width: "50%", p: 4 }}
                  spacing={1}
                >
                  <ExtendedTypography variant='titleSmall' color='primary.main'>
                    TS Studio Contabilità o Paghe
                  </ExtendedTypography>
                  <ExtendedTypography variant='body' color='text.secondary'>
                    Importa i tuoi fascicoli da TS Studio Contabilità o Paghe
                  </ExtendedTypography>
                  <Button
                    variant='contained'
                    size='small'
                    sx={{ width: "200px" }}
                    onClick={() => handleImportDesktop("multi")}
                  >
                    {t("dashboard.proceed")}
                  </Button>
                </Stack>
              </Stack>
            </CardContent>
          </Card>

          <Stack
            direction='row'
            spacing={2}
            sx={{ mt: 4, mb: 4, ml: 4, mr: 4 }}
          >
            <Card sx={{ width: "50%" }}>
              <CardContent sx={{ p: 4 }}>
                <Stack direction='column' spacing={1}>
                  <ExtendedTypography
                    variant='titleSmall'
                    color='primary.main'
                    gutterBottom
                  >
                    {t("dashboard.nxtExp")} (
                    {anagDocInScadList.length + rischiList.length + autovalList.length})
                  </ExtendedTypography>

                  <ExtendedTabs
                    variant='standard'
                    size='small'
                    value={selectedScadTab}
                    onChange={handleChangeScadTab}
                    sx={{ mb: 2 }}
                  >
                    <ExtendedTab
                      value='tabDocumenti'
                      label={t("dashboard.label3")}
                    />
                    <ExtendedTab
                      value='tabContCost'
                      label={t("dashboard.label4")}
                    />

                    <ExtendedTab
                      value='tabAutoval'
                      label="Autovalutazioni"
                    />
                  </ExtendedTabs>

                  {selectedScadTab === "tabDocumenti" ? (
                    <Stack
                      direction='column'
                      spacing={0}
                      justifyContent='left'
                      alignItems='left'
                    >
                      {anagDocInScadList.length > 0 ? (
                        <DataGridPro
                          rows={anagDocInScadList}
                          columns={anagDocInScadListColumns}
                          slots={{ toolbar: GridToolbar }}
                          autoHeight={true}
                          pageSizeOptions={[5, 10, 15]}
                          localeText={
                            itIT.components.MuiDataGrid.defaultProps.localeText
                          }
                          pagination
                          disableRowSelectionOnClick
                          onRowClick={(item, e) =>
                            handleGoToAnagrafica(e, item.row)
                          }
                          initialState={{
                            columns: {
                              columnVisibilityModel: {
                                id: false,
                              },
                            },
                            pagination: {
                              paginationModel: {
                                pageSize: 5,
                              },
                            },
                          }}
                          slotProps={{
                            toolbar: {
                              printOptions: { disableToolbarButton: true },
                            },
                          }}
                        />
                      ) : (
                        <NoData size='small' />
                      )}
                    </Stack>
                  ) : null}

                  {selectedScadTab === "tabContCost" ? (
                    <Stack
                      direction='column'
                      spacing={0}
                      justifyContent='left'
                      alignItems='left'
                    >
                      {rischiList.length > 0 ? (
                        <DataGridPro
                          rows={rischiList}
                          columns={rischiColumns}
                          slots={{ toolbar: GridToolbar }}
                          autoHeight={true}
                          pageSizeOptions={[5, 10, 15]}
                          localeText={
                            itIT.components.MuiDataGrid.defaultProps.localeText
                          }
                          pagination
                          disableRowSelectionOnClick
                          onRowClick={(item, e) =>
                            handleGoToRischio(e, item.row)
                          }
                          initialState={{
                            columns: {
                              columnVisibilityModel: {
                                id: false,
                              },
                            },
                            pagination: {
                              paginationModel: {
                                pageSize: 5,
                              },
                            },
                          }}
                          slotProps={{
                            toolbar: {
                              printOptions: { disableToolbarButton: true },
                            },
                          }}
                        />
                      ) : (
                        <NoData size='small' />
                      )}
                    </Stack>
                  ) : null}

                  {selectedScadTab === "tabAutoval" ? (
                      <Stack
                        direction='column'
                        spacing={0}
                        justifyContent='left'
                        alignItems='left'
                      >
                        {autovalList.length > 0 ? (
                          <DataGridPro
                            rows={autovalList}
                            columns={autovalColumns}
                            slots={{ toolbar: GridToolbar }}
                            autoHeight={true}
                            pageSizeOptions={[5, 10, 15]}                            
                            localeText={
                              itIT.components.MuiDataGrid.defaultProps.localeText
                            }
                            pagination
                            disableRowSelectionOnClick
                            // onRowClick={(item, e) =>
                            //   handleGoToRischio(e, item.row)
                            // }
                            initialState={{
                              columns: {
                                columnVisibilityModel: {
                                  id: false,
                                },
                              },
                              pagination: {
                                paginationModel: {
                                  pageSize: 5,
                                },
                              },
                            }}
                            slotProps={{
                              toolbar: {
                                printOptions: { disableToolbarButton: true },
                              },
                            }}
                          />
                        ) : (
                          <NoData size='small' />
                        )}
                    </Stack>
                  ) : null}
                </Stack>
              </CardContent>
            </Card>
            {
            //#region hermes
            /* <Card sx={{ width: "40%" }}>
              <CardContent sx={{ p: 4 }}>
                <Stack direction='column' spacing={1}>
                  <ExtendedTypography
                    variant='titleSmall'
                    color='primary.main'
                    gutterBottom
                  >
                    {t("dashboard.varAnag")} ({notificationList.length})
                  </ExtendedTypography>

                  {notificationList.length > 0 ? (
                    <TableContainer component={Paper} sx={{ maxHeight: 500 }}>
                      <Table size='small' stickyHeader>
                        <TableHead>
                          <TableRow>
                            <TableCell></TableCell>
                            <TableCell>{t("dashboard.cf")}</TableCell>
                            <TableCell>{t("dashboard.piva")}</TableCell>
                            <TableCell>{t("dashboard.nome")}</TableCell>
                            <TableCell>{t("dashboard.data")}</TableCell>
                            <TableCell align='right'></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {notificationList.map((item) => (
                            <Fragment key={uuidv4()}>
                              <TableRow
                                key={uuidv4()}
                                sx={{ "& > *": { borderBottom: "unset" } }}
                                hover
                              >
                                <TableCell>
                                  <IconButton
                                    size='small'
                                    onClick={() =>
                                      handleOpenNotificationDetail(item.id)
                                    }
                                  >
                                    {openNotificationDetail === item.id ? (
                                      <KeyboardArrowUpIcon />
                                    ) : (
                                      <KeyboardArrowDownIcon />
                                    )}
                                  </IconButton>
                                </TableCell>
                                <TableCell
                                  component='th'
                                  scope='row'
                                  sx={{ pl: 1 }}
                                >
                                  {JSON.parse(item.payload).cf}
                                </TableCell>
                                <TableCell sx={{ pl: 1 }}>
                                  {JSON.parse(item.payload).pIva}
                                </TableCell>
                                <TableCell sx={{ pl: 1 }}>
                                  {JSON.parse(item.payload).name}
                                </TableCell>
                                <TableCell sx={{ pl: 1 }}>
                                  {new Date(
                                    item.createdOn
                                  ).toLocaleDateString()}
                                </TableCell>
                                <TableCell align='right'>
                                  <IconButton
                                    color='primary'
                                    onClick={() =>
                                      handleUpdateNotification(item)
                                    }
                                  >
                                    <ArrowForwardIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  style={{ paddingBottom: 0, paddingTop: 0 }}
                                  colSpan={6}
                                >
                                  <Collapse
                                    in={openNotificationDetail === item.id}
                                    timeout='auto'
                                    unmountOnExit
                                  >
                                    <Box sx={{ margin: 1 }}>
                                      <ExtendedTypography
                                        variant='titleXSmall'
                                        gutterBottom
                                        component='div'
                                      >
                                        {t("dashboard.var")}
                                      </ExtendedTypography>
                                      <Table
                                        size='small'
                                        aria-label='purchases'
                                      >
                                        <TableHead>
                                          <TableRow>
                                            <TableCell
                                              sx={{ pl: "0px !important" }}
                                            >
                                              {t("dashboard.prop")}
                                            </TableCell>
                                            <TableCell
                                              sx={{ pl: "0px !important" }}
                                            >
                                              {t("dashboard.prec")}
                                            </TableCell>
                                            <TableCell
                                              sx={{ pl: "0px !important" }}
                                            >
                                              {t("dashboard.att")}
                                            </TableCell>
                                          </TableRow>
                                        </TableHead>
                                        <TableBody>
                                          {JSON.parse(item.payload).changes.map(
                                            (change) => (
                                              <TableRow key={change.key} hover>
                                                <TableCell
                                                  component='th'
                                                  scope='row'
                                                  sx={{ pl: 0 }}
                                                >
                                                  {change.key}
                                                </TableCell>
                                                <TableCell sx={{ pl: 0 }}>
                                                  {change.previous}
                                                </TableCell>
                                                <TableCell sx={{ pl: 0 }}>
                                                  {change.current}
                                                </TableCell>
                                              </TableRow>
                                            )
                                          )}
                                        </TableBody>
                                      </Table>
                                    </Box>
                                  </Collapse>
                                </TableCell>
                              </TableRow>
                            </Fragment>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  ) : (
                    <NoData size='small' />
                  )}
                </Stack>
              </CardContent>
            </Card> */
            //#endregion
            }

            <Card sx={{ width: "50%" }}>
              <CardContent sx={{ p: 4 }}>
                <Stack direction='column' spacing={1}>
                  <ExtendedTypography
                    variant='titleSmall'
                    color='primary.main'
                    gutterBottom
                  >
                    Fascicoli                   
                  </ExtendedTypography>

                  <ExtendedTabs
                    variant="standard"
                    size="small"
                    value={selectedIncompleteTab}
                    onChange={handleChangeIncompleteTab}
                    sx={{mb:2}}
                  >
                    <ExtendedTab
                      value='tabIncomplete'
                      label='Incompleti'
                    />
                    <ExtendedTab
                      value='tabComplete'
                      label='Completi'
                    />
                  </ExtendedTabs>

                  {selectedIncompleteTab === "tabIncomplete" &&
                    <Stack
                      direction='column'
                      spacing={0}
                      justifyContent='left'
                      alignItems='left'
                    >
                      {fascicoliIncompleteList.length > 0 ? (
                        <DataGridPro
                          rows={fascicoliIncompleteList}
                          columns={fascicoliIncompleteColumns}
                          slots={{ toolbar: GridToolbar }}
                          autoHeight={true}
                          pageSizeOptions={[5, 10, 15]}
                          localeText={
                            itIT.components.MuiDataGrid.defaultProps.localeText
                          }
                          pagination
                          disableRowSelectionOnClick
                          onRowClick={(item, e) => handleGoToFascicolo(e, item.row)}
                          initialState={{
                            columns: {
                              columnVisibilityModel: {
                                id: false,
                              },
                            },
                            pagination: {
                              paginationModel: {
                                pageSize: 5,
                              },
                            },
                          }}
                          slotProps={{
                            toolbar: {
                              printOptions: { disableToolbarButton: true },
                            },
                          }}
                        />
                      ) : (
                        <NoData size='small' />
                      )}
                    </Stack>
                  }

                  {selectedIncompleteTab === "tabComplete" &&
                    <Stack
                      direction='column'
                      spacing={0}
                      justifyContent='left'
                      alignItems='left'
                    >
                      {fascicoliCompleteList.length > 0 ? (
                        <DataGridPro
                          rows={fascicoliCompleteList}
                          columns={fascicoliCompleteColumns}
                          slots={{ toolbar: GridToolbar }}
                          autoHeight={true}
                          pageSizeOptions={[5, 10, 15]}
                          localeText={
                            itIT.components.MuiDataGrid.defaultProps.localeText
                          }
                          pagination
                          disableRowSelectionOnClick
                          onRowClick={(item, e) => handleGoToFascicolo(e, item.row)}
                          initialState={{
                            columns: {
                              columnVisibilityModel: {
                                id: false,
                              },
                            },
                            pagination: {
                              paginationModel: {
                                pageSize: 5,
                              },
                            },
                          }}
                          slotProps={{
                            toolbar: {
                              printOptions: { disableToolbarButton: true },
                            },
                          }}
                        />
                      ) : (
                        <NoData size='small'/>
                      )}
                    </Stack>
                  }
                </Stack>
              </CardContent>
            </Card>
          </Stack>
        </Stack>
      ) : null}
    </Fragment>
  );
  //#endregion
};
