//#region Imports
import React, { useState, useEffect } from "react";
import sharedDatas from "../../businessLogic/sharedDatas";

//oneFront SDK
import {
  useSnackbar,
  useQuery,
  useMutation,
  useTranslation,
} from "@onefront/react-sdk";

//Vapor Components
import TextField from "@vapor/react-material/TextField";
import Divider from "@vapor/react-material/Divider";
import Stack from "@vapor/react-material/Stack";
import Box from "@vapor/react-material/Box";
import Button from "@vapor/react-material/Button";
import Modal from "@vapor/react-material/Modal";
import Autocomplete from "@vapor/react-material/Autocomplete";
import Grid from "@vapor/react-material/Grid";
import Checkbox from "@vapor/react-material/Checkbox";
import FormControlLabel from "@vapor/react-material/FormControlLabel";
import FormGroup from "@vapor/react-material/FormGroup";

import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";

//Constants, Api and Data Models
import { bsaSettingsTabellaBase } from "../../businessLogic/constants";

import {
  baseGenereModel,
  baseMezzoPagamentoModel,
  baseNaturaPrestazioneModel,
  baseNaturaRapportoModel,
  baseOrigineFondiModel,
  baseScopoPrestazioneModel,
  baseTipoDocumentoModel,
  baseTipoIdentificazioneModel,
  baseTipoPersonalitaModel,
} from "../../businessLogic/models";

import {
  query_base_genere_byId,
  query_base_mezzo_pagamento_byId,
  query_base_natura_prestazione_byId,
  query_base_natura_rapporto_byId,
  query_base_origine_fondi_byId,
  query_base_scopo_prestazione_byId,
  query_base_tipo_documento_byId,
  query_base_tipo_identificazione_byId,
  query_base_tipo_personalita_byId,
  query_base_genere_byValue,
  query_base_mezzo_pagamento_byValue,
  query_base_natura_prestazione_byValue,
  query_base_natura_rapporto_byValue,
  query_base_origine_fondi_byValue,
  query_base_scopo_prestazione_byValue,
  query_base_tipo_identificazione_byValue,
  query_base_tipo_personalita_byValue,
  query_anagrafiche_byTenant,
} from "../../businessLogic/query";

import {
  mutation_add_log,
  mutation_add_audit,
  mutation_add_base_genere,
  mutation_update_base_genere,
  mutation_add_base_mezzo_pagamento,
  mutation_update_base_mezzo_pagamento,
  mutation_add_base_natura_prestazione,
  mutation_update_base_natura_prestazione,
  mutation_add_base_natura_rapporto,
  mutation_update_base_natura_rapporto,
  mutation_add_base_origine_fondi,
  mutation_update_base_origine_fondi,
  mutation_add_base_scopo_prestazione,
  mutation_update_base_scopo_prestazione,
  mutation_add_base_tipo_documento,
  mutation_update_base_tipo_documento,
  mutation_add_base_tipo_identificazione,
  mutation_update_base_tipo_identificazione,
  mutation_add_base_tipo_personalita,
  mutation_update_base_tipo_personalita,
} from "../../businessLogic/mutation";

//Style
import { modalStyle } from "../../businessLogic/styles";

//Other components
import { v4 as uuidv4 } from "uuid";
//#endregion

export const TabellaBaseForm = ({
  show,
  tabella,
  nItems,
  itemId,
  onClose,
  onAdded,
  baseTipoDocumento,
}) => {
  const tenantId = sharedDatas.getPropertyByName("tenantId");
  const aziendaId = sharedDatas.getPropertyByName("aziendaId");
  const loggedUserId = sharedDatas.getPropertyByName("userId");
  const version = sharedDatas.getPropertyByName("version");
  const env = sharedDatas.getPropertyByName("env");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  //#region Query
  const apiBaseGenereById = useQuery(
    query_base_genere_byId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseGenereByValue = useQuery(
    query_base_genere_byValue,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseMezzoPagamentoById = useQuery(
    query_base_mezzo_pagamento_byId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseMezzoPagamentoByValue = useQuery(
    query_base_mezzo_pagamento_byValue,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseNaturaPrestazioneById = useQuery(
    query_base_natura_prestazione_byId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseNaturaPrestazioneByValue = useQuery(
    query_base_natura_prestazione_byValue,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseNaturaRapportoById = useQuery(
    query_base_natura_rapporto_byId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseNaturaRapportoByValue = useQuery(
    query_base_natura_rapporto_byValue,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseOrigineFondiById = useQuery(
    query_base_origine_fondi_byId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseOrigineFondiByValue = useQuery(
    query_base_origine_fondi_byValue,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseScopoPrestazioneById = useQuery(
    query_base_scopo_prestazione_byId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseScopoPrestazioneByValue = useQuery(
    query_base_scopo_prestazione_byValue,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseTipoDocumentoById = useQuery(
    query_base_tipo_documento_byId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseTipoIdentificazioneById = useQuery(
    query_base_tipo_identificazione_byId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseTipoIdentificazioneByValue = useQuery(
    query_base_tipo_identificazione_byValue,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseTipoPersonalitaById = useQuery(
    query_base_tipo_personalita_byId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseTipoPersonalitaByValue = useQuery(
    query_base_tipo_personalita_byValue,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAnagraficheByTenant = useQuery(
    query_anagrafiche_byTenant,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  //#endregion

  //#region Mutation
  const apiAddLog = useMutation(mutation_add_log, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAudit = useMutation(mutation_add_audit, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddBaseGenere = useMutation(mutation_add_base_genere, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddBaseMezzoPagamento = useMutation(
    mutation_add_base_mezzo_pagamento,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAddBaseNaturaPrestazione = useMutation(
    mutation_add_base_natura_prestazione,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAddBaseNaturaRapporto = useMutation(
    mutation_add_base_natura_rapporto,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAddBaseOrigineFondi = useMutation(mutation_add_base_origine_fondi, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddBaseScopoPrestazione = useMutation(
    mutation_add_base_scopo_prestazione,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAddBaseTipoDocumento = useMutation(
    mutation_add_base_tipo_documento,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAddBaseTipoIdentificazione = useMutation(
    mutation_add_base_tipo_identificazione,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAddBaseTipoPersonalita = useMutation(
    mutation_add_base_tipo_personalita,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  const apiUpdateBaseGenere = useMutation(mutation_update_base_genere, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUpdateBaseMezzoPagamento = useMutation(
    mutation_update_base_mezzo_pagamento,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUpdateBaseNaturaPrestazione = useMutation(
    mutation_update_base_natura_prestazione,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUpdateBaseNaturaRapporto = useMutation(
    mutation_update_base_natura_rapporto,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUpdateBaseOrigineFondi = useMutation(
    mutation_update_base_origine_fondi,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUpdateBaseScopoPrestazione = useMutation(
    mutation_update_base_scopo_prestazione,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUpdateBaseTipoDocumento = useMutation(
    mutation_update_base_tipo_documento,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUpdateBaseTipoIdentificazione = useMutation(
    mutation_update_base_tipo_identificazione,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiUpdateBaseTipoPersonalita = useMutation(
    mutation_update_base_tipo_personalita,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  //#endregion

  //#region UsesStates
  const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api
  const [selItem, setSelItem] = useState({
    id: 0,
    key: "",
    value: "",
    isSystem: false,
  });
  const [selItemOriginal, setSelItemOriginal] = useState(
    new baseMezzoPagamentoModel()
  ); //Used for Audit
  const [selInputError, setSelInputError] = useState({ value: false });
  const [nextBirthDayChecked, setNextBirthDayChecked] = useState(false);
  const [birthDayDisable, setBirthDayDisable] = useState(true);
  //#endregion

  useEffect(() => {
    if (itemId > 0 && !isDataLoaded) {
      loadItemDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoaded]);

  const loadItemDetails = async () => {
    try {
      if (tabella === "base_genere") {
        const tbResponse = await apiBaseGenereById.fetch({
          id: parseInt(itemId),
        });
        const result = tbResponse.data.data.base_genere_by_pk;
        setSelItem({
          id: result.Id,
          key: result.Key,
          value: result.Value,
          isSystem: result.IsSystem,
        });
        setSelItemOriginal(
          new baseGenereModel(
            result.Id,
            result.Key,
            result.Value,
            result.Order,
            result.IsSystem
          )
        );
      } else if (tabella === "base_mezzo_pagamento") {
        const tbResponse = await apiBaseMezzoPagamentoById.fetch({
          id: parseInt(itemId),
        });
        const result = tbResponse.data.data.base_mezzo_pagamento_by_pk;
        setSelItem({
          id: result.Id,
          key: result.Key,
          value: result.Value,
          isSystem: result.IsSystem,
        });
        setSelItemOriginal(
          new baseMezzoPagamentoModel(
            result.Id,
            result.Key,
            result.Value,
            result.Order,
            result.IsSystem
          )
        );
      } else if (tabella === "base_natura_prestazione") {
        const tbResponse = await apiBaseNaturaPrestazioneById.fetch({
          id: parseInt(itemId),
        });
        const result = tbResponse.data.data.base_natura_prestazione_by_pk;
        setSelItem({
          id: result.Id,
          key: result.Key,
          value: result.Value,
          isSystem: result.IsSystem,
        });
        setSelItemOriginal(
          new baseNaturaPrestazioneModel(
            result.Id,
            result.Key,
            result.Value,
            result.Order,
            result.IsSystem
          )
        );
      } else if (tabella === "base_natura_rapporto") {
        const tbResponse = await apiBaseNaturaRapportoById.fetch({
          id: parseInt(itemId),
        });
        const result = tbResponse.data.data.base_natura_rapporto_by_pk;
        setSelItem({
          id: result.Id,
          key: result.Key,
          value: result.Value,
          isSystem: result.IsSystem,
        });
        setSelItemOriginal(
          new baseNaturaRapportoModel(
            result.Id,
            result.Key,
            result.Value,
            result.Order,
            result.IsSystem
          )
        );
      } else if (tabella === "base_origine_fondi") {
        const tbResponse = await apiBaseOrigineFondiById.fetch({
          id: parseInt(itemId),
        });
        const result = tbResponse.data.data.base_origine_fondi_by_pk;
        setSelItem({
          id: result.Id,
          key: result.Key,
          value: result.Value,
          isSystem: result.IsSystem,
        });
        setSelItemOriginal(
          new baseOrigineFondiModel(
            result.Id,
            result.Key,
            result.Value,
            result.Order,
            result.IsSystem
          )
        );
      } else if (tabella === "base_scopo_prestazione") {
        const tbResponse = await apiBaseScopoPrestazioneById.fetch({
          id: parseInt(itemId),
        });
        const result = tbResponse.data.data.base_scopo_prestazione_by_pk;
        setSelItem({
          id: result.Id,
          key: result.Key,
          value: result.Value,
          isSystem: result.IsSystem,
        });
        setSelItemOriginal(
          new baseScopoPrestazioneModel(
            result.Id,
            result.Key,
            result.Value,
            result.Order,
            result.IsSystem
          )
        );
      } else if (tabella === "base_tipo_documento") {
        const tbResponse = await apiBaseTipoDocumentoById.fetch({
          id: parseInt(itemId),
        });
        const result = tbResponse.data.data.base_tipo_documento_by_pk;
        setSelItem({
          id: result.Id,
          key: result.Key,
          value: result.Value,
          isSystem: result.IsSystem,
        });
        setSelItemOriginal(
          new baseTipoDocumentoModel(
            result.Id,
            result.Key,
            result.Value,
            result.Order,
            result.IsSystem,
            result.PersonType,
            result.DocumentTypeId,
            result.ValidityMonths,
            result.ValidityOffsetDays,
            result.NextBirthDay
          )
        );
        setNextBirthDayChecked(result.NextBirthDay);
        if (result.DocumentTypeId === 1 || result.DocumentTypeId === 5) {
          setBirthDayDisable(false);
        } else {
          setBirthDayDisable(true);
        }
      } else if (tabella === "base_tipo_identificazione") {
        const tbResponse = await apiBaseTipoIdentificazioneById.fetch({
          id: parseInt(itemId),
        });
        const result = tbResponse.data.data.base_tipo_identificazione_by_pk;
        setSelItem({
          id: result.Id,
          key: result.Key,
          value: result.Value,
          isSystem: result.IsSystem,
        });
        setSelItemOriginal(
          new baseTipoIdentificazioneModel(
            result.Id,
            result.Key,
            result.Value,
            result.Order,
            result.IsSystem
          )
        );
      } else if (tabella === "base_tipo_personalita") {
        const tbResponse = await apiBaseTipoPersonalitaById.fetch({
          id: parseInt(itemId),
        });
        const result = tbResponse.data.data.base_tipo_personalita_by_pk;
        setSelItem({
          id: result.Id,
          key: result.Key,
          value: result.Value,
          isSystem: result.IsSystem,
        });
        setSelItemOriginal(
          new baseTipoPersonalitaModel(
            result.Id,
            result.Key,
            result.Value,
            result.Order,
            result.IsSystem
          )
        );
      }
    } catch (e) {
      AddLog(e.message,e.stack,"LoadItemDetails")      
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setIsDataLoaded(true);
    }
  };

  const AddLog = (message, stack, method) => {
    if(env === "production"){
      apiAddLog.fetch({
        tenantId: tenantId,
        aziendaId: aziendaId,
        level: "ERROR",
        message: message,
        stack: stack,
        area: "TabellaBaseForm",
        method: method,
        version: version,
      });
    }
  } 

  //#region Actions
  const handleModalClose = (e) => {
    onClose(e);
  };
  const handleTxtChange = (e) => {
    try {
      const id = e.target.id;
      const value = e.target.value;

      if (id === "txtValue") {
        selItem.value = value;
        selInputError.value = value === "";
      } else if (id === "txtValidityMonths") {
        selItemOriginal.validityMonths = value;
      } else if (id === "txtOffsetDays") {
        selItemOriginal.validityOffsetDays = value;
      }
      setSelItem(selItem);
      setSelItemOriginal(selItemOriginal);
      setSelInputError(selInputError);
    } catch (e) {
      AddLog(e.message,e.stack,"HandleTxtChange")      
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };
  const handleCatChange = (event, value) => {
    const { id } = event.target;
    if (id === "ddlCategory" || id.indexOf("ddlCategory-option") !== -1) {
      selItemOriginal.documentTypeId = baseTipoDocumento.filter(
        (doc) => doc.Value === value
      )[0].Id;
    }

    if (
      selItemOriginal.documentTypeId === 1 ||
      selItemOriginal.documentTypeId === 5
    ) {
      setBirthDayDisable(false);
    } else {
      setBirthDayDisable(true);
    }
  };
  const handleChangeBirthday = (event) => {
    const { id } = event.target;

    if (id === "chkNextBirthday") {
      setNextBirthDayChecked(!nextBirthDayChecked);

      if (!nextBirthDayChecked) {
        setSelItemOriginal((prevState) => ({
          ...prevState,
          validityOffsetDays: 0,
        }));
      }
    }
  };
  const handleBtnAddClick = async (e) => {
    try {
      if (tabella === "base_genere") {
        //Verifico se esiste già un item con lo stesso value, nel caso blocco
        const exists = await apiBaseGenereByValue.fetch({
          tenantId: tenantId,
          value: selItem.value,
        });
        if (exists.data.data.base_genere.length <= 0) {
          if (selItem.value !== "") {
            //Se itemId è 0 vuol dire che sto aggiungendo un nuovo item, altrimenti ne sto modificando uno esistente
            if (itemId === 0) {
              const newCount = nItems + 1;
              const key = tabella + "_" + newCount;

              const tbResponse = await apiAddBaseGenere.fetch({
                tenantId: tenantId,
                key: key,
                value: selItem.value,
                order: parseInt(newCount),
              });
              if (tbResponse.data.errors !== undefined) {
                enqueueSnackbar(t("error.generale"), { variant: "error" });
              } else {
                setSelItem((prev) => ({ ...prev, id: 0, key: "", value: "" }));
                setSelInputError((prev) => ({ ...prev, value: false }));

                //Aggiungo la Audit per tracciare l'azione
                const result = tbResponse.data.data.insert_base_genere_one;
                const newValues = new baseGenereModel(
                  result.Id,
                  result.Key,
                  result.Value,
                  result.Order,
                  result.IsSystem
                );
                apiAddAudit.fetch({
                  tenantId: tenantId,
                  aziendaId: aziendaId,
                  area: bsaSettingsTabellaBase,
                  operation: "Create",
                  entityType: "base_genere",
                  entityId: result.Id.toString(),
                  oldValues: "",
                  newValues: JSON.stringify(newValues),
                });

                enqueueSnackbar(t("message.success"), { variant: "success" });
                onAdded(e);
              }
            } else {
              const tbResponse = await apiUpdateBaseGenere.fetch({
                id: parseInt(itemId),
                tenantId: tenantId,
                value: selItem.value,
              });
              if (tbResponse.data.errors !== undefined) {
                enqueueSnackbar(t("error.generale"), { variant: "error" });
              } else {
                //Aggiungo la Audit per tracciare l'azione
                const result =
                  tbResponse.data.data.update_base_genere.returning[0];
                const newValues = new baseGenereModel(
                  result.Id,
                  result.Key,
                  result.Value,
                  result.Order,
                  result.IsSystem
                );
                apiAddAudit.fetch({
                  tenantId: tenantId,
                  aziendaId: aziendaId,
                  area: bsaSettingsTabellaBase,
                  operation: "Update",
                  entityType: "base_genere",
                  entityId: itemId.toString(),
                  oldValues: JSON.stringify(selItemOriginal),
                  newValues: JSON.stringify(newValues),
                });

                enqueueSnackbar(t("message.success"), { variant: "success" });
                onAdded(e);
              }
            }
          } else {
            setSelInputError((prev) => ({
              ...prev,
              value: selItem.value === "",
            }));
          }
        } else {
          enqueueSnackbar("Esiste già un record con questo valore", {
            variant: "error",
          });
        }
      } else if (tabella === "base_mezzo_pagamento") {
        //Verifico se esiste già un item con lo stesso value, nel caso blocco
        const exists = await apiBaseMezzoPagamentoByValue.fetch({
          tenantId: tenantId,
          value: selItem.value,
        });
        if (exists.data.data.base_mezzo_pagamento.length <= 0) {
          if (selItem.value !== "") {
            //Se itemId è 0 vuol dire che sto aggiungendo un nuovo item, altrimenti ne sto modificando uno esistente
            if (itemId === 0) {
              const newCount = nItems + 1;
              const key = tabella + "_" + newCount;

              const tbResponse = await apiAddBaseMezzoPagamento.fetch({
                tenantId: tenantId,
                key: key,
                value: selItem.value,
                order: parseInt(newCount),
              });
              if (tbResponse.data.errors !== undefined) {
                enqueueSnackbar(t("error.generale"), { variant: "error" });
              } else {
                setSelItem((prev) => ({ ...prev, id: 0, key: "", value: "" }));
                setSelInputError((prev) => ({ ...prev, value: false }));

                //Aggiungo la Audit per tracciare l'azione
                const result =
                  tbResponse.data.data.insert_base_mezzo_pagamento_one;
                const newValues = new baseMezzoPagamentoModel(
                  result.Id,
                  result.Key,
                  result.Value,
                  result.Order,
                  result.IsSystem
                );
                apiAddAudit.fetch({
                  tenantId: tenantId,
                  aziendaId: aziendaId,
                  area: bsaSettingsTabellaBase,
                  operation: "Create",
                  entityType: "base_mezzo_pagamento",
                  entityId: result.Id.toString(),
                  oldValues: "",
                  newValues: JSON.stringify(newValues),
                });

                enqueueSnackbar(t("message.success"), { variant: "success" });
                onAdded(e);
              }
            } else {
              const tbResponse = await apiUpdateBaseMezzoPagamento.fetch({
                id: parseInt(itemId),
                tenantId: tenantId,
                value: selItem.value,
              });
              if (tbResponse.data.errors !== undefined) {
                enqueueSnackbar(t("error.generale"), { variant: "error" });
              } else {
                //Aggiungo la Audit per tracciare l'azione
                const result =
                  tbResponse.data.data.update_base_mezzo_pagamento.returning[0];
                const newValues = new baseMezzoPagamentoModel(
                  result.Id,
                  result.Key,
                  result.Value,
                  result.Order,
                  result.IsSystem
                );
                apiAddAudit.fetch({
                  tenantId: tenantId,
                  aziendaId: aziendaId,
                  area: bsaSettingsTabellaBase,
                  operation: "Update",
                  entityType: "base_mezzo_pagamento",
                  entityId: itemId.toString(),
                  oldValues: JSON.stringify(selItemOriginal),
                  newValues: JSON.stringify(newValues),
                });

                enqueueSnackbar(t("message.success"), { variant: "success" });
                onAdded(e);
              }
            }
          } else {
            setSelInputError((prev) => ({
              ...prev,
              value: selItem.value === "",
            }));
          }
        } else {
          enqueueSnackbar("Esiste già un record con questo valore", {
            variant: "error",
          });
        }
      } else if (tabella === "base_natura_prestazione") {
        //Verifico se esiste già un item con lo stesso value, nel caso blocco
        const exists = await apiBaseNaturaPrestazioneByValue.fetch({
          tenantId: tenantId,
          value: selItem.value,
        });
        if (exists.data.data.base_natura_prestazione.length <= 0) {
          if (selItem.value !== "") {
            //Se itemId è 0 vuol dire che sto aggiungendo un nuovo item, altrimenti ne sto modificando uno esistente
            if (itemId === 0) {
              const newCount = nItems + 1;
              const key = tabella + "_" + newCount;

              const tbResponse = await apiAddBaseNaturaPrestazione.fetch({
                tenantId: tenantId,
                key: key,
                value: selItem.value,
                order: parseInt(newCount),
              });
              if (tbResponse.data.errors !== undefined) {
                enqueueSnackbar(t("error.generale"), { variant: "error" });
              } else {
                setSelItem((prev) => ({ ...prev, id: 0, key: "", value: "" }));
                setSelInputError((prev) => ({ ...prev, value: false }));

                //Aggiungo la Audit per tracciare l'azione
                const result =
                  tbResponse.data.data.insert_base_natura_prestazione_one;
                const newValues = new baseNaturaPrestazioneModel(
                  result.Id,
                  result.Key,
                  result.Value,
                  result.Order,
                  result.IsSystem
                );
                apiAddAudit.fetch({
                  tenantId: tenantId,
                  aziendaId: aziendaId,
                  area: bsaSettingsTabellaBase,
                  operation: "Create",
                  entityType: "base_natura_prestazione",
                  entityId: result.Id.toString(),
                  oldValues: "",
                  newValues: JSON.stringify(newValues),
                });

                enqueueSnackbar(t("message.success"), { variant: "success" });
                onAdded(e);
              }
            } else {
              const tbResponse = await apiUpdateBaseNaturaPrestazione.fetch({
                id: parseInt(itemId),
                tenantId: tenantId,
                value: selItem.value,
              });
              if (tbResponse.data.errors !== undefined) {
                enqueueSnackbar(t("error.generale"), { variant: "error" });
              } else {
                //Aggiungo la Audit per tracciare l'azione
                const result =
                  tbResponse.data.data.update_base_natura_prestazione
                    .returning[0];
                const newValues = new baseNaturaPrestazioneModel(
                  result.Id,
                  result.Key,
                  result.Value,
                  result.Order,
                  result.IsSystem
                );
                apiAddAudit.fetch({
                  tenantId: tenantId,
                  aziendaId: aziendaId,
                  area: bsaSettingsTabellaBase,
                  operation: "Update",
                  entityType: "base_natura_prestazione",
                  entityId: itemId.toString(),
                  oldValues: JSON.stringify(selItemOriginal),
                  newValues: JSON.stringify(newValues),
                });

                enqueueSnackbar(t("message.success"), { variant: "success" });
                onAdded(e);
              }
            }
          } else {
            setSelInputError((prev) => ({
              ...prev,
              value: selItem.value === "",
            }));
          }
        } else {
          enqueueSnackbar("Esiste già un record con questo valore", {
            variant: "error",
          });
        }
      } else if (tabella === "base_natura_rapporto") {
        //Verifico se esiste già un item con lo stesso value, nel caso blocco
        const exists = await apiBaseNaturaRapportoByValue.fetch({
          tenantId: tenantId,
          value: selItem.value,
        });
        if (exists.data.data.base_natura_rapporto.length <= 0) {
          if (selItem.value !== "") {
            //Se itemId è 0 vuol dire che sto aggiungendo un nuovo item, altrimenti ne sto modificando uno esistente
            if (itemId === 0) {
              const newCount = nItems + 1;
              const key = tabella + "_" + newCount;

              const tbResponse = await apiAddBaseNaturaRapporto.fetch({
                tenantId: tenantId,
                key: key,
                value: selItem.value,
                order: parseInt(newCount),
              });
              if (tbResponse.data.errors !== undefined) {
                enqueueSnackbar(t("error.generale"), { variant: "error" });
              } else {
                setSelItem((prev) => ({ ...prev, id: 0, key: "", value: "" }));
                setSelInputError((prev) => ({ ...prev, value: false }));

                //Aggiungo la Audit per tracciare l'azione
                const result =
                  tbResponse.data.data.insert_base_natura_rapporto_one;
                const newValues = new baseNaturaRapportoModel(
                  result.Id,
                  result.Key,
                  result.Value,
                  result.Order,
                  result.IsSystem
                );
                apiAddAudit.fetch({
                  tenantId: tenantId,
                  aziendaId: aziendaId,
                  area: bsaSettingsTabellaBase,
                  operation: "Create",
                  entityType: "base_natura_rapporto",
                  entityId: result.Id.toString(),
                  oldValues: "",
                  newValues: JSON.stringify(newValues),
                });

                enqueueSnackbar(t("message.success"), { variant: "success" });
                onAdded(e);
              }
            } else {
              const tbResponse = await apiUpdateBaseNaturaRapporto.fetch({
                id: parseInt(itemId),
                tenantId: tenantId,
                value: selItem.value,
              });
              if (tbResponse.data.errors !== undefined) {
                enqueueSnackbar(t("error.generale"), { variant: "error" });
              } else {
                //Aggiungo la Audit per tracciare l'azione
                const result =
                  tbResponse.data.data.update_base_natura_rapporto.returning[0];
                const newValues = new baseNaturaRapportoModel(
                  result.Id,
                  result.Key,
                  result.Value,
                  result.Order,
                  result.IsSystem
                );
                apiAddAudit.fetch({
                  tenantId: tenantId,
                  aziendaId: aziendaId,
                  area: bsaSettingsTabellaBase,
                  operation: "Update",
                  entityType: "base_natura_rapporto",
                  entityId: itemId.toString(),
                  oldValues: JSON.stringify(selItemOriginal),
                  newValues: JSON.stringify(newValues),
                });

                enqueueSnackbar(t("message.success"), { variant: "success" });
                onAdded(e);
              }
            }
          } else {
            setSelInputError((prev) => ({
              ...prev,
              value: selItem.value === "",
            }));
          }
        } else {
          enqueueSnackbar("Esiste già un record con questo valore", {
            variant: "error",
          });
        }
      } else if (tabella === "base_origine_fondi") {
        //Verifico se esiste già un item con lo stesso value, nel caso blocco
        const exists = await apiBaseOrigineFondiByValue.fetch({
          tenantId: tenantId,
          value: selItem.value,
        });
        if (exists.data.data.base_origine_fondi.length <= 0) {
          if (selItem.value !== "") {
            //Se itemId è 0 vuol dire che sto aggiungendo un nuovo item, altrimenti ne sto modificando uno esistente
            if (itemId === 0) {
              const newCount = nItems + 1;
              const key = tabella + "_" + newCount;

              const tbResponse = await apiAddBaseOrigineFondi.fetch({
                tenantId: tenantId,
                key: key,
                value: selItem.value,
                order: parseInt(newCount),
              });
              if (tbResponse.data.errors !== undefined) {
                enqueueSnackbar(t("error.generale"), { variant: "error" });
              } else {
                setSelItem((prev) => ({ ...prev, id: 0, key: "", value: "" }));
                setSelInputError((prev) => ({ ...prev, value: false }));

                //Aggiungo la Audit per tracciare l'azione
                const result =
                  tbResponse.data.data.insert_base_origine_fondi_one;
                const newValues = new baseOrigineFondiModel(
                  result.Id,
                  result.Key,
                  result.Value,
                  result.Order,
                  result.IsSystem
                );
                apiAddAudit.fetch({
                  tenantId: tenantId,
                  aziendaId: aziendaId,
                  area: bsaSettingsTabellaBase,
                  operation: "Create",
                  entityType: "base_origine_fondi",
                  entityId: result.Id.toString(),
                  oldValues: "",
                  newValues: JSON.stringify(newValues),
                });

                enqueueSnackbar(t("message.success"), { variant: "success" });
                onAdded(e);
              }
            } else {
              const tbResponse = await apiUpdateBaseOrigineFondi.fetch({
                id: parseInt(itemId),
                tenantId: tenantId,
                value: selItem.value,
              });
              if (tbResponse.data.errors !== undefined) {
                enqueueSnackbar(t("error.generale"), { variant: "error" });
              } else {
                //Aggiungo la Audit per tracciare l'azione
                const result =
                  tbResponse.data.data.update_base_origine_fondi.returning[0];
                const newValues = new baseOrigineFondiModel(
                  result.Id,
                  result.Key,
                  result.Value,
                  result.Order,
                  result.IsSystem
                );
                apiAddAudit.fetch({
                  tenantId: tenantId,
                  aziendaId: aziendaId,
                  area: bsaSettingsTabellaBase,
                  operation: "Update",
                  entityType: "base_origine_fondi",
                  entityId: itemId.toString(),
                  oldValues: JSON.stringify(selItemOriginal),
                  newValues: JSON.stringify(newValues),
                });

                enqueueSnackbar(t("message.success"), { variant: "success" });
                onAdded(e);
              }
            }
          } else {
            setSelInputError((prev) => ({
              ...prev,
              value: selItem.value === "",
            }));
          }
        } else {
          enqueueSnackbar("Esiste già un record con questo valore", {
            variant: "error",
          });
        }
      } else if (tabella === "base_scopo_prestazione") {
        //Verifico se esiste già un item con lo stesso value, nel caso blocco
        const exists = await apiBaseScopoPrestazioneByValue.fetch({
          tenantId: tenantId,
          value: selItem.value,
        });
        if (exists.data.data.base_scopo_prestazione.length <= 0) {
          if (selItem.value !== "") {
            //Se itemId è 0 vuol dire che sto aggiungendo un nuovo item, altrimenti ne sto modificando uno esistente
            if (itemId === 0) {
              const newCount = nItems + 1;
              const key = tabella + "_" + newCount;

              const tbResponse = await apiAddBaseScopoPrestazione.fetch({
                tenantId: tenantId,
                key: key,
                value: selItem.value,
                order: parseInt(newCount),
              });
              if (tbResponse.data.errors !== undefined) {
                enqueueSnackbar(t("error.generale"), { variant: "error" });
              } else {
                setSelItem((prev) => ({ ...prev, id: 0, key: "", value: "" }));
                setSelInputError((prev) => ({ ...prev, value: false }));

                //Aggiungo la Audit per tracciare l'azione
                const result =
                  tbResponse.data.data.insert_base_scopo_prestazione_one;
                const newValues = new baseScopoPrestazioneModel(
                  result.Id,
                  result.Key,
                  result.Value,
                  result.Order,
                  result.IsSystem
                );
                apiAddAudit.fetch({
                  tenantId: tenantId,
                  aziendaId: aziendaId,
                  area: bsaSettingsTabellaBase,
                  operation: "Create",
                  entityType: "base_scopo_prestazione",
                  entityId: result.Id.toString(),
                  oldValues: "",
                  newValues: JSON.stringify(newValues),
                });

                enqueueSnackbar(t("message.success"), { variant: "success" });
                onAdded(e);
              }
            } else {
              const tbResponse = await apiUpdateBaseScopoPrestazione.fetch({
                id: parseInt(itemId),
                tenantId: tenantId,
                value: selItem.value,
              });
              if (tbResponse.data.errors !== undefined) {
                enqueueSnackbar(t("error.generale"), { variant: "error" });
              } else {
                //Aggiungo la Audit per tracciare l'azione
                const result =
                  tbResponse.data.data.update_base_scopo_prestazione
                    .returning[0];
                const newValues = new baseScopoPrestazioneModel(
                  result.Id,
                  result.Key,
                  result.Value,
                  result.Order,
                  result.IsSystem
                );
                apiAddAudit.fetch({
                  tenantId: tenantId,
                  aziendaId: aziendaId,
                  area: bsaSettingsTabellaBase,
                  operation: "Update",
                  entityType: "base_scopo_prestazione",
                  entityId: itemId.toString(),
                  oldValues: JSON.stringify(selItemOriginal),
                  newValues: JSON.stringify(newValues),
                });

                enqueueSnackbar(t("message.success"), { variant: "success" });
                onAdded(e);
              }
            }
          } else {
            setSelInputError((prev) => ({
              ...prev,
              value: selItem.value === "",
            }));
          }
        } else {
          enqueueSnackbar("Esiste già un record con questo valore", {
            variant: "error",
          });
        }
      } else if (tabella === "base_tipo_documento") {
        //Verifico se esiste già un'anagrafica che utilizza questo tipo di documento
        //se è così la blocco
        const anagraifcheTenantResponse = await apiAnagraficheByTenant.fetch({
          tenantId: tenantId,
          aziendaId: aziendaId,
          userId: loggedUserId,
        });

        var docTypeIdents = [];
        if (itemId !== 0) {
          var allIdents = [];
          var anagrafiche = anagraifcheTenantResponse.data.data.anagrafica;
          var idents = anagrafiche.map(
            (anag) => anag.anagrafica_identificaziones
          );
          idents.forEach((identArr) => {
            identArr.forEach((ident) => {
              allIdents.push(ident);
            });
          });
          docTypeIdents = allIdents.map((ident) => ident.DocTipoId);
        }

        if (selItem.value !== "") {
          //Se itemId è 0 vuol dire che sto aggiungendo un nuovo item, altrimenti ne sto modificando uno esistente
          if (itemId === 0) {
            const newCount = nItems + 1;
            const key = tabella + "_" + newCount;

            const tbResponse = await apiAddBaseTipoDocumento.fetch({
              tenantId: tenantId,
              key: key,
              value: selItem.value,
              order: parseInt(newCount),
              documentTypeId: baseTipoDocumento.filter(
                (p) => p.Id === selItemOriginal.documentTypeId
              )[0].Id,
              validityMonths: parseInt(selItemOriginal.validityMonths),
              offsetDays: selItemOriginal.validityOffsetDays,
              nextBirthDay: nextBirthDayChecked,
              personType:
                baseTipoDocumento.filter(
                  (p) => p.Id === selItemOriginal.documentTypeId
                )[0].Id === 3
                  ? "PG"
                  : baseTipoDocumento.filter(
                      (p) => p.Id === selItemOriginal.documentTypeId
                    )[0].Id === 8
                  ? ""
                  : "PF",
            });
            if (tbResponse.data.errors !== undefined) {
              enqueueSnackbar(t("error.generale"), { variant: "error" });
            } else {
              setSelItem((prev) => ({ ...prev, id: 0, key: "", value: "" }));
              setSelInputError((prev) => ({ ...prev, value: false }));

              //Aggiungo la Audit per tracciare l'azione
              const result =
                tbResponse.data.data.insert_base_tipo_documento_one;
              const newValues = new baseTipoDocumentoModel(
                result.Id,
                result.Key,
                result.Value,
                result.Order,
                result.IsSystem
              );
              apiAddAudit.fetch({
                tenantId: tenantId,
                aziendaId: aziendaId,
                area: bsaSettingsTabellaBase,
                operation: "Create",
                entityType: "base_tipo_documento",
                entityId: result.Id.toString(),
                oldValues: "",
                newValues: JSON.stringify(newValues),
              });

              enqueueSnackbar(t("message.success"), { variant: "success" });
              onAdded(e);
            }
          } else if (docTypeIdents.includes(itemId)) {
            enqueueSnackbar(
              "Il tipo di documento è in uso, impossibile aggiornarlo",
              { variant: "error" }
            );
            return;
          } else {
            const tbResponse = await apiUpdateBaseTipoDocumento.fetch({
              id: parseInt(itemId),
              tenantId: tenantId,
              value: selItem.value,
              documentTypeId: baseTipoDocumento.filter(
                (p) => p.Id === selItemOriginal.documentTypeId
              )[0].Id,
              validityMonths: parseInt(selItemOriginal.validityMonths),
              validityOffsetDays: selItemOriginal.validityOffsetDays,
              nextBirthDay: nextBirthDayChecked,
            });
            if (tbResponse.data.errors !== undefined) {
              enqueueSnackbar(t("error.generale"), { variant: "error" });
            } else {
              //Aggiungo la Audit per tracciare l'azione
              const result =
                tbResponse.data.data.update_base_tipo_documento.returning[0];
              const newValues = new baseTipoDocumentoModel(
                result.Id,
                result.Key,
                result.Value,
                result.Order,
                result.IsSystem
              );
              apiAddAudit.fetch({
                tenantId: tenantId,
                aziendaId: aziendaId,
                area: bsaSettingsTabellaBase,
                operation: "Update",
                entityType: "base_tipo_documento",
                entityId: itemId.toString(),
                oldValues: JSON.stringify(selItemOriginal),
                newValues: JSON.stringify(newValues),
              });

              enqueueSnackbar(t("message.success"), { variant: "success" });
              onAdded(e);
            }
          }
        } else {
          setSelInputError((prev) => ({
            ...prev,
            value: selItem.value === "",
          }));
        }
      } else if (tabella === "base_tipo_identificazione") {
        //Verifico se esiste già un item con lo stesso value, nel caso blocco
        const exists = await apiBaseTipoIdentificazioneByValue.fetch({
          tenantId: tenantId,
          value: selItem.value,
        });
        if (exists.data.data.base_tipo_identificazione.length <= 0) {
          if (selItem.value !== "") {
            //Se itemId è 0 vuol dire che sto aggiungendo un nuovo item, altrimenti ne sto modificando uno esistente
            if (itemId === 0) {
              const newCount = nItems + 1;
              const key = tabella + "_" + newCount;

              const tbResponse = await apiAddBaseTipoIdentificazione.fetch({
                tenantId: tenantId,
                key: key,
                value: selItem.value,
                order: parseInt(newCount),
              });
              if (tbResponse.data.errors !== undefined) {
                enqueueSnackbar(t("error.generale"), { variant: "error" });
              } else {
                setSelItem((prev) => ({ ...prev, id: 0, key: "", value: "" }));
                setSelInputError((prev) => ({ ...prev, value: false }));

                //Aggiungo la Audit per tracciare l'azione
                const result =
                  tbResponse.data.data.insert_base_tipo_identificazione_one;
                const newValues = new baseTipoIdentificazioneModel(
                  result.Id,
                  result.Key,
                  result.Value,
                  result.Order,
                  result.IsSystem
                );
                apiAddAudit.fetch({
                  tenantId: tenantId,
                  aziendaId: aziendaId,
                  area: bsaSettingsTabellaBase,
                  operation: "Create",
                  entityType: "base_tipo_identificazione",
                  entityId: result.Id.toString(),
                  oldValues: "",
                  newValues: JSON.stringify(newValues),
                });

                enqueueSnackbar(t("message.success"), { variant: "success" });
                onAdded(e);
              }
            } else {
              const tbResponse = await apiUpdateBaseTipoIdentificazione.fetch({
                id: parseInt(itemId),
                tenantId: tenantId,
                value: selItem.value,
              });
              if (tbResponse.data.errors !== undefined) {
                enqueueSnackbar(t("error.generale"), { variant: "error" });
              } else {
                //Aggiungo la Audit per tracciare l'azione
                const result =
                  tbResponse.data.data.update_base_tipo_identificazione
                    .returning[0];
                const newValues = new baseTipoIdentificazioneModel(
                  result.Id,
                  result.Key,
                  result.Value,
                  result.Order,
                  result.IsSystem
                );
                apiAddAudit.fetch({
                  tenantId: tenantId,
                  aziendaId: aziendaId,
                  area: bsaSettingsTabellaBase,
                  operation: "Update",
                  entityType: "base_tipo_identificazione",
                  entityId: itemId.toString(),
                  oldValues: JSON.stringify(selItemOriginal),
                  newValues: JSON.stringify(newValues),
                });

                enqueueSnackbar(t("message.success"), { variant: "success" });
                onAdded(e);
              }
            }
          } else {
            setSelInputError((prev) => ({
              ...prev,
              value: selItem.value === "",
            }));
          }
        } else {
          enqueueSnackbar("Esiste già un record con questo valore", {
            variant: "error",
          });
        }
      } else if (tabella === "base_tipo_personalita") {
        //Verifico se esiste già un item con lo stesso value, nel caso blocco
        const exists = await apiBaseTipoPersonalitaByValue.fetch({
          tenantId: tenantId,
          value: selItem.value,
        });
        if (exists.data.data.base_tipo_personalita.length <= 0) {
          if (selItem.value !== "") {
            //Se itemId è 0 vuol dire che sto aggiungendo un nuovo item, altrimenti ne sto modificando uno esistente
            if (itemId === 0) {
              const newCount = nItems + 1;
              const key = tabella + "_" + newCount;

              const tbResponse = await apiAddBaseTipoPersonalita.fetch({
                tenantId: tenantId,
                key: key,
                value: selItem.value,
                order: parseInt(newCount),
              });
              if (tbResponse.data.errors !== undefined) {
                enqueueSnackbar(t("error.generale"), { variant: "error" });
              } else {
                setSelItem((prev) => ({ ...prev, id: 0, key: "", value: "" }));
                setSelInputError((prev) => ({ ...prev, value: false }));

                //Aggiungo la Audit per tracciare l'azione
                const result =
                  tbResponse.data.data.insert_base_tipo_personalita_one;
                const newValues = new baseTipoPersonalitaModel(
                  result.Id,
                  result.Key,
                  result.Value,
                  result.Order,
                  result.IsSystem
                );
                apiAddAudit.fetch({
                  tenantId: tenantId,
                  aziendaId: aziendaId,
                  area: bsaSettingsTabellaBase,
                  operation: "Create",
                  entityType: "base_tipo_personalita",
                  entityId: result.Id.toString(),
                  oldValues: "",
                  newValues: JSON.stringify(newValues),
                });

                enqueueSnackbar(t("message.success"), { variant: "success" });
                onAdded(e);
              }
            } else {
              const tbResponse = await apiUpdateBaseTipoPersonalita.fetch({
                id: parseInt(itemId),
                tenantId: tenantId,
                value: selItem.value,
              });
              if (tbResponse.data.errors !== undefined) {
                enqueueSnackbar(t("error.generale"), { variant: "error" });
              } else {
                //Aggiungo la Audit per tracciare l'azione
                const result =
                  tbResponse.data.data.update_base_tipo_personalita
                    .returning[0];
                const newValues = new baseTipoPersonalitaModel(
                  result.Id,
                  result.Key,
                  result.Value,
                  result.Order,
                  result.IsSystem
                );
                apiAddAudit.fetch({
                  tenantId: tenantId,
                  aziendaId: aziendaId,
                  area: bsaSettingsTabellaBase,
                  operation: "Update",
                  entityType: "base_tipo_personalita",
                  entityId: itemId.toString(),
                  oldValues: JSON.stringify(selItemOriginal),
                  newValues: JSON.stringify(newValues),
                });

                enqueueSnackbar(t("message.success"), { variant: "success" });
                onAdded(e);
              }
            }
          } else {
            setSelInputError((prev) => ({
              ...prev,
              value: selItem.value === "",
            }));
          }
        } else {
          enqueueSnackbar("Esiste già un record con questo valore", {
            variant: "error",
          });
        }
      }
    } catch (e) {
      AddLog(e.message + " Tab: " + tabella + " item: " + selItem,e.stack,"HandleBtnAddClick")      
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };
  //#endregion

  //#region Return Graphics
  return (
    <Modal
      key="addModal"
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      open={show}
      onClose={handleModalClose}
      onBackdropClick={handleModalClose}
    >
      <Box sx={modalStyle}>
        <Stack
          direction="column"
          spacing={0}
          justifyContent="left"
          alignItems="left"
        >
          <ExtendedTypography
            variant="titleSmall"
            color="primary.main"
            sx={{ p: 2, pl: 3 }}
          >
            {itemId === 0
              ? t("impostazioni.tabellaBase.form.addTitle")
              : t("impostazioni.tabellaBase.form.editTitle")}
          </ExtendedTypography>
          <Divider orientation="horizontal" flexItem />

          <Box sx={{ p: 3 }}>
            {tabella === "base_tipo_documento" && !selItem.isSystem ? (
              <>
                <Grid container xs={12} sx={{ mt: 2, mb: 2 }}>
                  <Autocomplete
                    id="ddlCategory"
                    key={uuidv4()}
                    label="Categoria"
                    size="small"
                    fullWidth
                    options={baseTipoDocumento.map((val) => val.Value)}
                    defaultValue={
                      baseTipoDocumento !== null &&
                      selItemOriginal.documentTypeId !== 0
                        ? baseTipoDocumento.map((val) => val.Value)[
                            selItemOriginal.documentTypeId - 1
                          ]
                        : null
                    }
                    onChange={handleCatChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        label="Categoria"
                        size="small"
                        sx={{ lineHeight: 1 }}
                      />
                    )}
                  ></Autocomplete>
                </Grid>

                <Grid container xs={12} sx={{ mt: 2, mb: 2 }}>
                  <Grid item xs={6} sx={{ pr: 2 }}>
                    <TextField
                      id="txtValidityMonths"
                      key={uuidv4()}
                      label="Mesi di validità"
                      size="small"
                      type="number"
                      fullWidth
                      defaultValue={selItemOriginal.validityMonths}
                      onChange={handleTxtChange}
                      inputProps={{ autocomplete: "nope" }}
                    />
                  </Grid>

                  <Grid item xs={6} sx={{ pl: 2 }}>
                    <TextField
                      id="txtOffsetDays"
                      key={uuidv4()}
                      label="Offset giorni"
                      size="small"
                      type="number"
                      fullWidth
                      disabled={nextBirthDayChecked}
                      defaultValue={selItemOriginal.validityOffsetDays}
                      onChange={handleTxtChange}
                      inputProps={{ autocomplete: "nope" }}
                    />
                  </Grid>
                </Grid>

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="chkNextBirthday"
                        checked={nextBirthDayChecked}
                        onChange={handleChangeBirthday}
                        disabled={birthDayDisable}
                      />
                    }
                    label="Prossimo compleanno"
                  />
                </FormGroup>
              </>
            ) : null}

            <TextField
              id="txtValue"
              key={uuidv4()}
              label={t("impostazioni.tabellaBase.form.value")}
              size="small"
              fullWidth
              required
              disabled={itemId > 0 && selItem.isSystem}
              defaultValue={selItem.value}
              onChange={handleTxtChange}
              error={selInputError.value}
              inputProps={{ autocomplete: "nope" }}
            />

            {selItem.isSystem ? (
              <>
                <TextField
                  id="txtValidityMonths"
                  key={uuidv4()}
                  label="Mesi di validità"
                  size="small"
                  type="number"
                  fullWidth
                  disabled={true}
                  defaultValue={selItemOriginal.validityMonths}
                  onChange={handleTxtChange}
                  inputProps={{ autocomplete: "nope" }}
                  sx={{ mt: 2 }}
                />

                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="chkNextBirthday"
                        checked={nextBirthDayChecked}
                        onChange={handleChangeBirthday}
                        disabled={true}
                      />
                    }
                    label="Prossimo compleanno"
                  />
                </FormGroup>
              </>
            ) : null}
          </Box>
        </Stack>
        <Stack
          direction="row"
          spacing={1}
          sx={{ p: 1, mt: 1, bgcolor: "#F2F5F8" }}
          justifyContent="right"
          alignItems="right"
        >
          <Button variant="outlined" size="small" onClick={handleModalClose}>
            {t("actions.annulla")}
          </Button>
          {!selItem.isSystem ? (
            <Button
              variant="contained"
              size="small"
              disabled={itemId > 0 && selItem.isSystem}
              onClick={handleBtnAddClick}
            >
              {t("actions.salva")}
            </Button>
          ) : null}
        </Stack>
      </Box>
    </Modal>
  );
  //#endregion
};
