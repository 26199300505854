import React, { Fragment, useEffect, useState } from "react";

//Vapor Components
import Stack from "@vapor/react-material/Stack";
import Box from "@vapor/react-material/Box";
import Button from "@vapor/react-material/Button";
import Modal from "@vapor/react-material/Modal";
import Divider from "@vapor/react-material/Divider";
import ExtendedTypography from "@vapor/react-extended/ExtendedTypography";
import {useQuery } from "@onefront/react-sdk";
import {userModel} from "../../../businessLogic/models"

//Vapor Date Pickers
import { AdapterDateFns } from "@vapor/react-x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@vapor/react-x-date-pickers/LocalizationProvider";
import { DatePicker } from "@vapor/react-x-date-pickers/DatePicker";
import { it } from "date-fns/locale";

//Style
import {  modalStyleLarge } from "../../../businessLogic/styles";
import { useTranslation } from "@onefront/react-sdk";
import { query_users_byTenantId } from "../../../businessLogic/query";
import { isDateValid } from "../../../businessLogic/bl";
import { Autocomplete, TextField } from "@vapor/react-material";
import { v4 as uuidv4 } from "uuid";

export const ModalNominationAdd = ({show, onClose, onOk,tenantId,userFromParent,selectedRoleFromParent}) =>{

    const { t } = useTranslation();        
    const possibleRoles = ["Revisore indipendente","Responsabile antiriciclaggio"]
    const [allUsers,setAllUsers] = useState([])
    const [isDataLoaded,setIsDataLoaded] = useState(false)
    const [selectedRole,setSelectedRole] = useState("")
    const [selUser,setSelUser] = useState(null)
    const [startDate,setStartDate] = useState("")
    const [endDate,setEndDate] = useState("")
    const usersByTenant = useQuery(
        query_users_byTenantId,
        {},
        { lazy :true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    )

    useEffect(()=>{
        loadUsers()
        setSelectedRole(selectedRoleFromParent === "revisore_indipendente" ? "Revisore indipendente" : "Responsabile antiriciclaggio")
        if(userFromParent !== null){
            setSelectedRole(userFromParent.type)
            setSelUser(allUsers.filter(p=>p.userId === userFromParent.userId)[0]) 
            setStartDate(new Date(userFromParent.dateStart).toDateString())
            setEndDate(userFromParent.dateEnd !== null ? new  Date(userFromParent.dateEnd).toDateString() : "")                   
        } else {            
            setStartDate("")
            setEndDate("")
            setSelUser(null)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[userFromParent,selectedRoleFromParent])

    async function loadUsers() {
        if(!isDataLoaded){
            try {
                const allUsersResponse = await usersByTenant.fetch({
                    tenantId: tenantId,
                });
                const arrAllUsers = [];
                allUsersResponse.data.data.user_tenant.map((item) =>
                    arrAllUsers.push(
                    new userModel(
                        item.UserId,
                        item.TenantId,
                        item.user.Username,
                        item.user.Email,
                        item.user.Name,
                        item.user.Surname,
                        item.user.Name + " " + item.user.Surname,
                        item.Type,
                        item.user.Locale,
                        item.TenantTipoId,
                        item.user.IsRespAntiRic,
                        item.base_tipo_tenant.Value,
                        item.user.IsActive,
                        item.user.IsDeleted,
                        item.user.IsBanned,
                        item.user.CreatedOn,
                        item.user.Cf
                    )
                    )
                );
                setAllUsers(arrAllUsers)                                            
            } catch (error) {
                
            } finally {
                setIsDataLoaded(true)
            }
        }
    }

    //Actions
    const handleModalClose = (e) => {
        onClose(e);
    };
    const handleModalOk = (e) => {
        onOk(selUser,startDate,endDate,selectedRole)
    };    
    const handleChange = (event,value) => {
        const {id} = event.target

        if(id.indexOf("ddlModalRole") !== -1 || id === "ddlModIdent"){        
            setSelectedRole(value)            
        } else if(id.indexOf("ddlModalUsers") !== -1 || id === "ddlModalUsers"){
            setSelUser(value)
        }        
    }
    const handleChangeStartDate = (date) => {
        if(date !== null && isDateValid(date)){
            const newDate = new Date(date);
            setStartDate(newDate.toDateString())        
        } else {
            setStartDate("")
            setEndDate("")
        }   
    }
    const handleChangeEndDate = (date) => {        
        if(date !== null && isDateValid(date)){        
            const newDate = new Date(date);
            setEndDate(newDate.toDateString())            
        } else {
            setEndDate("")
        }        
    }

    return (
        <Fragment>
            <Modal
                key="nominationAddModal"
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                open={show}
                onClose={handleModalClose}    
                onOk={handleModalOk}            
            >                
                <Box sx={modalStyleLarge}>
                    <Stack
                        direction="column"
                        spacing={0}
                        justifyContent="left"
                        alignItems="left"
                    >
                        <ExtendedTypography
                            variant="titleSmall"
                            color="primary.main"
                            sx={{ p: 2, pl: 3 }}
                        >
                            {userFromParent !== null ? "Aggiorna informazioni dell'utente" : "Nomina un nuovo utente"}
                        </ExtendedTypography>
                        <Divider orientation="horizontal" flexItem />
                        
                        <Stack sx={{p : 2}} spacing={2}>
                            {possibleRoles.length > 0 &&                            
                                <Autocomplete
                                    id="ddlModalRole"
                                    key={uuidv4()}
                                    size="small"
                                    options={possibleRoles}
                                    sx={{ width: "100%" }}
                                    fullWidth
                                    onChange={handleChange}                                                                
                                    defaultValue={selectedRole}                                                               
                                    disabled = {userFromParent !== null}
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        required
                                        label="Ruolo"
                                        sx={{ lineHeight: 2 }}
                                        />
                                )}/>
                            }
                            
                            {allUsers.length > 0 &&                        
                                <Autocomplete
                                    id="ddlModalUsers"
                                    key={uuidv4()}
                                    size="small"
                                    options={allUsers}
                                    sx={{ width: "100%" }}
                                    fullWidth
                                    onChange={handleChange}                            
                                    getOptionLabel={(option) => option?.completeName ?? ""}                                    
                                    defaultValue={selUser !== null ? selUser : ""}                                    
                                    renderInput={(params) => (
                                        <TextField
                                        {...params}
                                        required
                                        label="Utente"
                                        sx={{ lineHeight: 2 }}
                                    />
                                )}/>
                            }
                            

                            <Stack direction="row" spacing={2}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={it}
                                >
                                    <DatePicker
                                        label="Dal"
                                        closeOnSelect
                                        format="dd/MM/yyyy"
                                        mask="__/__/____"
                                        minDate={
                                            selUser !== null && isDateValid(selUser.createdOn)
                                            ? new Date(selUser.createdOn)
                                            : null                                         
                                        }
                                        maxDate={new Date()}
                                        value={
                                            isDateValid(startDate)
                                            ? new Date(startDate)
                                            : null
                                        }
                                        onChange={handleChangeStartDate}
                                        slotProps={{
                                            textField: {
                                            placeholder: "dd/MM/yyyy",
                                            size: "small",
                                            fullWidth: true,
                                            },
                                        }}                                        
                                    />
                                    <DatePicker
                                        label="Al"
                                        closeOnSelect
                                        format="dd/MM/yyyy"
                                        mask="__/__/____"
                                        minDate={
                                            isDateValid(startDate)
                                            ? new Date(startDate)
                                            : null                                         
                                        }                                       
                                        value={
                                            isDateValid(endDate)
                                            ? new Date(endDate)
                                            : null
                                        }
                                        onChange={handleChangeEndDate}
                                        slotProps={{
                                            textField: {
                                            placeholder: "dd/MM/yyyy",
                                            size: "small",
                                            fullWidth: true,
                                            },
                                        }}
                                        disabled={!isDateValid(startDate)}
                                    />
                                </LocalizationProvider>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={1}
                        sx={{ p: 1, mt: 1, bgcolor: "#F2F5F8" }}
                        justifyContent="right"
                        alignItems="right"
                    >
                        <Button variant="outlined" size="small" onClick={handleModalClose}>
                            {t("actions.annulla")}
                        </Button>
                        <Button variant="contained" size="small" onClick={handleModalOk}>
                            {userFromParent!== null ? t("actions.aggiorna") : t("actions.salva")}
                        </Button>
                    </Stack>
                </Box>
            </Modal>
        </Fragment>
    );
}