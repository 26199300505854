//#region Imports
import React, { useState, useEffect, Fragment } from "react";
import sharedDatas from "../../businessLogic/sharedDatas";
import { useNavigate } from "react-router-dom";

//oneFront SDK
import {
  useSnackbar,
  useQuery,
  usePost,
  useMutation,
  useAuth,
  useTranslation,
} from "@onefront/react-sdk";

//Vapor Components
import Grid from "@vapor/react-material/Grid";
import Stack from "@vapor/react-material/Stack";
import Button from "@vapor/react-material/Button";
import Autocomplete from "@vapor/react-material/Autocomplete";
import TextField from "@vapor/react-material/TextField";

import VaporPage from "@vapor/react-custom/VaporPage";
import VaporToolbar from "@vapor/react-custom/VaporToolbar";

//Icons
import {
  SaveOutlined as SaveIcon,
  CloseOutlined as CancelIcon,
} from "@mui/icons-material";

//Custom components
import { Loading } from "../../components/Loading";
import { NotAuth } from "../../components/NotAuth";

import {
  bsaAzienda,
  pathAziendaHome,
  pathAziendaAdd,
  pathHome,
} from "../../businessLogic/constants";
import {
  getLocalStorageSelectedAzienda,
  setLocalStorageSelectedAzienda,
} from "../../businessLogic/bl";
import {
  aziendaModel,
  basePaeseModel,
  baseProvinciaModel,
  baseComuneModel,
} from "../../businessLogic/models";
import {
  query_aziende_byId,
  query_base_all,
  query_base_comune,
  query_aziende_byTenant,
  query_aziende_byCfPIva,
} from "../../businessLogic/query";
import {
  mutation_add_log,
  mutation_add_audit,
  mutation_add_lastActivity_byUserId,
  mutation_delete_lastActivity,
  mutation_add_azienda,
  mutation_update_azienda,
  mutation_add_metering_consumption,
} from "../../businessLogic/mutation";

//Other components
import { v4 as uuidv4 } from "uuid";
import postal from "postal";
//#endregion

export const AziendaAdd = ({ basePath }) => {
  const navigate = useNavigate();
  if (
    sharedDatas.getPropertyByName("userId") === "" ||
    sharedDatas.getPropertyByName("tenantId") === "" ||
    sharedDatas.getPropertyByName("aziendaId") === 0
  ) {
    navigate(pathHome);
  }

  const tenantId = sharedDatas.getPropertyByName("tenantId");
  const tenant = sharedDatas.getPropertyByName("tenantModel");
  const aziendaId = sharedDatas.getPropertyByName("aziendaId");
  const loggedUserId = sharedDatas.getPropertyByName("userId");
  const version = sharedDatas.getPropertyByName("version");
  const env = sharedDatas.getPropertyByName("env");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const selectedAzienda = getLocalStorageSelectedAzienda();

  //#region Query
  const apiBaseAll = useQuery(
    query_base_all,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAziendaById = useQuery(
    query_aziende_byId,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiBaseComune = useQuery(
    query_base_comune,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAziendeByTenant = useQuery(
    query_aziende_byTenant,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  const apiAziendeByCfPIva = useQuery(
    query_aziende_byCfPIva,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  //#endregion

  //#region Mutation
  const apiAddLog = useMutation(mutation_add_log, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAudit = useMutation(mutation_add_audit, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddLastActivity = useMutation(mutation_add_lastActivity_byUserId, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeleteLastActivity = useMutation(mutation_delete_lastActivity, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddAzienda = useMutation(mutation_add_azienda, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiUpdateAzienda = useMutation(mutation_update_azienda, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddMeteringConsumption = useMutation(
    mutation_add_metering_consumption,
    { graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );
  //#endregion

  //TS Digital Api
  const [accessToken] = useState(useAuth().token);
  const tsApiWorkspace = usePost(
    "aml:workspace-write:api://",
    {},
    {
      headers: {
        Authorization: "Bearer " + accessToken,
        "X-App-Name": "TS420",
        "X-App-Version": "1.0.0",
        "X-Request-ID": uuidv4(),
        "X-Correlation-ID": uuidv4(),
        "Content-Type": "application/json",
        "X-Item-ID": tenantId,
        "X-User-ID": loggedUserId,
      },
    }
  );

  //#region UseStates
  const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
  const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api
  const [isUserOwner] = useState(
    sharedDatas.getPropertyByName("userModel").type === "OWNER"
  );
  //Tabelle di base
  const [basePaeseList, setBasePaeseList] = useState([]);
  const [baseProvinciaList, setBaseProvinciaList] = useState([]);
  const [baseComuneList, setBaseComuneList] = useState([]);
  const [currentAzienda, setCurrentAzienda] = useState(
    new aziendaModel(
      0,
      tenantId,
      "",
      "",
      "",
      0,
      0,
      "",
      0,
      "",
      "",
      "",
      "",
      new Date(),
      new Date(),
      loggedUserId,
      "",
      0
    )
  );
  const [currentAziendaOriginal, setCurrentAziendaOriginal] = useState(
    new aziendaModel(
      0,
      tenantId,
      "",
      "",
      "",
      0,
      0,
      "",
      0,
      "",
      "",
      "",
      "",
      new Date(),
      new Date(),
      loggedUserId,
      "",
      0
    )
  );
  //Valori form
  const [isAddrPaeseIta, setIsAddrPaeseIta] = useState(true);
  //#endregion

  useEffect(() => {
    loadDatas();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoaded]);

  const AddLog = async (message,stack,method) => {
    if(env === "production"){
        apiAddLog.fetch({
        tenantId: tenantId,
            aziendaId: aziendaId,
            level: "ERROR",
            message: message,
            stack: stack,
            area: "AziendaAdd",
            method: method,
            version: version,
        })
    }
  }

  //#region Load Methods
  async function loadDatas() {
    try {
      if (
        sharedDatas.getPropertyByName("tenantId") === "" ||
        sharedDatas.getPropertyByName("tenantModel") === null ||
        sharedDatas.getPropertyByName("aziendaId") === 0 ||
        sharedDatas.getPropertyByName("aziendaModelList") === null ||
        sharedDatas.getPropertyByName("userId") === "" ||
        sharedDatas.getPropertyByName("userModel") === null
      ) {
        navigate("/");
      } else {
        setBackDropOpen(true);

        if (!isDataLoaded) {
          /**************************************/
          //Carico i permessi relativi all'utente

          /**************************************/
          //Carico le tabelle di base
          const baseAllResponse = await apiBaseAll.fetch({
            tenantId: tenantId,
          });
          const paesi = [];
          baseAllResponse.data.data.base_paese.map((item) =>
            paesi.push(
              new basePaeseModel(
                item.Id,
                item.Nome,
                item.Identificativo,
                item.IsRischio
              )
            )
          );
          setBasePaeseList(paesi);

          const province = [];
          baseAllResponse.data.data.base_provincia.map((item) =>
            province.push(
              new baseProvinciaModel(
                item.Id,
                item.Nome,
                item.Sigla,
                item.Rischio
              )
            )
          );
          setBaseProvinciaList(province);

          /**************************************/
          //Carico l'azienda
          const selectedAziendaResponse = await selectedAzienda;
          const itemId = parseInt(selectedAziendaResponse);
          let azienda = new aziendaModel();

          if (itemId > 0) {
            const aziendaResponse = await apiAziendaById.fetch({ id: itemId });
            const item = aziendaResponse.data.data.azienda_by_pk;
            azienda = new aziendaModel(
              item.Id,
              item.TenantId,
              item.Denominazione,
              item.Cf,
              item.PIva,
              item.AddrPaeseId,
              item.AddrProvinciaId,
              item.AddrProvincia,
              item.AddrComuneId,
              item.AddrComune,
              item.AddrIndirizzo,
              item.AddrN,
              item.AddrCap,
              item.CreatedOn,
              item.ModifiedOn,
              item.CreatedBy,
              item.ModifiedBy,
              item.MeteringSlotId
            );
            setCurrentAzienda(azienda);
            setCurrentAziendaOriginal(azienda);

            if (azienda.addrPaeseId !== null && azienda.addrPaeseId !== 0) {
              const paese = paesi.filter(
                (item) => item.id === azienda.addrPaeseId
              )[0];
              if (paese.identificativo === "ITA") {
                const provincia = province.filter(
                  (item) => item.id === azienda.addrProvinciaId
                )[0];
                loadBaseComune(provincia.sigla);
              }

              setIsAddrPaeseIta(paese.identificativo === "ITA");
            }

            //Last Activity
            await apiDeleteLastActivity.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              userId: loggedUserId,
            });
            await apiAddLastActivity.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              path: pathAziendaAdd,
              entityId: azienda.id.toString(),
              entityName: azienda.denominazione,
            });

            setIsDataLoaded(true);
          }
        }
      }
    } catch (e) {
      AddLog(e.message,e.stack,"loadDatas")      
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      setIsDataLoaded(true);
    }
  }
  const loadBaseComune = async (provincia) => {
    try {
      const comuneResponse = await apiBaseComune.fetch({
        provincia: provincia,
      });
      const arrItems = [];
      comuneResponse.data.data.base_comune.map((item) =>
        arrItems.push(
          new baseComuneModel(item.Id, item.Codice, item.Provincia, item.Nome)
        )
      );
      setBaseComuneList(arrItems);
    } catch (e) {
      AddLog(e.message,e.stack,"loadBaseComune")      
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    }
  };
  //#endregion

  //#region Handlers
  //Home Button
  const handleGoHome = () => {
    setLocalStorageSelectedAzienda(0);
    navigate(basePath);
  };
  //Salvataggio degli inputs
  const handleChange = (event, value) => {
    const { id } = event.target;

    if (id === "txtCf") {
      currentAzienda.cf = event.target.value;
    } else if (id === "txtDenominazione") {
      currentAzienda.denominazione = event.target.value;
    } else if (id === "txtPIva") {
      currentAzienda.pIva = event.target.value;
    } else if (
      id.indexOf("ddlAddrPaese-option") !== -1 ||
      id === "ddlAddrPaese"
    ) {
      currentAzienda.addrPaeseId = value.id;

      currentAzienda.addrProvincia = "";
      currentAzienda.addrComune = "";
      currentAzienda.addrProvinciaId = null;
      currentAzienda.addrComuneId = null;

      var country = basePaeseList.filter(
        (item) => item.id === currentAzienda.addrPaeseId
      )[0];

      setIsAddrPaeseIta(country.identificativo === "ITA");
    } else if (
      id.indexOf("ddlAddrProvincia-option") !== -1 ||
      id === "ddlAddrProvincia"
    ) {
      currentAzienda.addrProvinciaId = value.id;

      //Recupero le città
      var provincia = baseProvinciaList.filter(
        (item) => item.id === currentAzienda.addrProvinciaId
      )[0];
      loadBaseComune(provincia.sigla);
    } else if (
      id.indexOf("ddlAddrComune-option") !== -1 ||
      id === "ddlAddrComune"
    ) {
      currentAzienda.addrComuneId = value.id;
    } else if (id === "txtAddrProvincia") {
      currentAzienda.addrProvincia = event.target.value;
    } else if (id === "txtAddrComune") {
      currentAzienda.addrComune = event.target.value;
    } else if (id === "txtAddrIndirizzo") {
      currentAzienda.addrIndirizzo = event.target.value;
    } else if (id === "txtAddrN") {
      currentAzienda.addrN = event.target.value;
    } else if (id === "txtAddrCap") {
      currentAzienda.addrCap = event.target.value;
    }

    setCurrentAzienda(currentAzienda);
  };
  const handleCancelClick = () => {
    setLocalStorageSelectedAzienda(0);
    navigate(basePath);
  };
  const handleSaveClick = async () => {
    try {
      setBackDropOpen(true);

      if (
        currentAzienda.cf !== undefined &&
        currentAzienda.cf !== "" &&
        currentAzienda.pIva !== undefined &&
        currentAzienda.pIva !== "" &&
        currentAzienda.denominazione !== undefined &&
        currentAzienda.denominazione !== "" &&
        currentAzienda.addrPaeseId !== undefined &&
        currentAzienda.addrPaeseId > 0 &&
        ((currentAzienda.addrProvinciaId !== undefined &&
          currentAzienda.addrProvinciaId > 0) ||
          (currentAzienda.addrProvincia !== undefined &&
            currentAzienda.addrProvincia !== "")) &&
        ((currentAzienda.addrComuneId !== undefined &&
          currentAzienda.addrComuneId > 0) ||
          (currentAzienda.addrComune !== undefined &&
            currentAzienda.addrComune !== "")) &&
        currentAzienda.addrIndirizzo !== undefined &&
        currentAzienda.addrIndirizzo !== "" &&
        currentAzienda.addrCap !== undefined &&
        currentAzienda.addrCap !== ""
      ) {
        //Verifico l'esistenza tramite Cf/PIva
        const aziendeByCfPIvaResponse = await apiAziendeByCfPIva.fetch({
          tenantId: tenantId,
          cf: currentAzienda.cf,
          pIva: currentAzienda.pIva,
        });
        if (aziendeByCfPIvaResponse.data.data.azienda.length > 0 && currentAzienda.id > 0) {
          //Se esiste già il record aggiorno le informazioni
          const updateAziendaResponse = await apiUpdateAzienda.fetch({
            id: currentAzienda.id,
            cf: currentAzienda.cf,
            pIva: currentAzienda.pIva,
            denominazione: currentAzienda.denominazione,
            addrPaeseId: currentAzienda.addrPaeseId,
            addrProvinciaId: currentAzienda.addrProvinciaId,
            addrProvincia: currentAzienda.addrProvincia,
            addrComuneId: currentAzienda.addrComuneId,
            addrComune: currentAzienda.addrComune,
            addrIndirizzo: currentAzienda.addrIndirizzo,
            addrN: currentAzienda.addrN,
            addrCap: currentAzienda.addrCap,
            meteringSlotId: currentAzienda.meteringSlotId,
          });

          if (updateAziendaResponse.data.errors !== undefined) {
            enqueueSnackbar(t("error.generale"), { variant: "error" });
          } else {
            const result =
              updateAziendaResponse.data.data.update_azienda.returning[0];

            let aziendaResultModel = new aziendaModel(
              result.Id,
              result.TenantId,
              result.Denominazione,
              result.Cf,
              result.PIva,
              result.AddrPaeseId,
              result.AddrProvinciaId,
              result.AddrProvincia,
              result.AddrComuneId,
              result.AddrComune,
              result.AddrIndirizzo,
              result.AddrN,
              result.AddrCap,
              result.CreatedOn,
              result.ModifiedOn,
              result.CreatedBy,
              result.ModifiedBy,
              0
            );

            //Aggiungo la Audit per tracciare l'azione
            const newValues = aziendaResultModel;
            apiAddAudit.fetch({
              tenantId: tenantId,
              aziendaId: aziendaId,
              area: bsaAzienda,
              operation: "Update",
              entityType: "azienda",
              entityId: result.Id.toString(),
              oldValues: JSON.stringify(currentAziendaOriginal),
              newValues: JSON.stringify(newValues),
            });

            enqueueSnackbar(t("message.success"), { variant: "success" });
          }          
        } else {
          //Se sto creando l'azienda
          if (currentAzienda.id === 0) {
            const addAziendaResponse = await apiAddAzienda.fetch({
              tenantId: tenantId,
              cf: currentAzienda.cf,
              pIva: currentAzienda.pIva,
              denominazione: currentAzienda.denominazione,
              addrPaeseId: currentAzienda.addrPaeseId,
              addrProvinciaId: currentAzienda.addrProvinciaId,
              addrProvincia: currentAzienda.addrProvincia,
              addrComuneId: currentAzienda.addrComuneId,
              addrComune: currentAzienda.addrComune,
              addrIndirizzo: currentAzienda.addrIndirizzo,
              addrN: currentAzienda.addrN,
              addrCap: currentAzienda.addrCap,
            });

            if (addAziendaResponse.data.errors !== undefined) {
              enqueueSnackbar(t("error.generale"), { variant: "error" });
            } else {
              const result = addAziendaResponse.data.data.insert_azienda_one;

              let aziendaResultModel = new aziendaModel(
                result.Id,
                result.TenantId,
                result.Denominazione,
                result.Cf,
                result.PIva,
                result.AddrPaeseId,
                result.AddrProvinciaId,
                result.AddrProvincia,
                result.AddrComuneId,
                result.AddrComune,
                result.AddrIndirizzo,
                result.AddrN,
                result.AddrCap,
                result.CreatedOn,
                result.ModifiedOn,
                result.CreatedBy,
                result.ModifiedBy,
                0
              );

              //Aggiungo la Audit per tracciare l'azione
              const newValues = aziendaResultModel;
              apiAddAudit.fetch({
                tenantId: tenantId,
                aziendaId: aziendaId,
                area: bsaAzienda,
                operation: "Create",
                entityType: "azienda",
                entityId: result.Id.toString(),
                oldValues: "",
                newValues: JSON.stringify(newValues),
              });

              //Salvo le modifiche su Workspace
              var workspaceId = await savePersonalDataOnWorkspace(
                aziendaResultModel
              );
              await saveOfficesOnWorkSpace(
                workspaceId,
                aziendaResultModel
              );

              //Aggiungo il consumo sul metering
              let slotId = 0;

              const addConsumptionResp = await apiAddMeteringConsumption.fetch({
                tenantId: tenantId,
                companyName: aziendaResultModel.denominazione,
                companyId: aziendaResultModel.id,
              });
              slotId =
                addConsumptionResp.data.data.addMeteringConsumption.value;

              if (slotId === 0 || slotId === null || slotId === undefined) {
                enqueueSnackbar(t("error.generale"), { variant: "error" });
              } else {
                await apiUpdateAzienda.fetch({
                  id: aziendaResultModel.id,
                  cf: aziendaResultModel.cf,
                  pIva: aziendaResultModel.pIva,
                  denominazione: aziendaResultModel.denominazione,
                  addrPaeseId: aziendaResultModel.addrPaeseId,
                  addrProvinciaId: aziendaResultModel.addrProvinciaId,
                  addrProvincia: aziendaResultModel.addrProvincia,
                  addrComuneId: aziendaResultModel.addrComuneId,
                  addrComune: aziendaResultModel.addrComune,
                  addrIndirizzo: aziendaResultModel.addrIndirizzo,
                  addrN: aziendaResultModel.addrN,
                  addrCap: aziendaResultModel.addrCap,
                  meteringSlotId: slotId,
                });

                enqueueSnackbar(t("message.success"), { variant: "success" });
              }
            }
          }

          //Recupero il nuovo elenco di soggetti obbligati
          const aziendeResponse = await apiAziendeByTenant.fetch({
            tenantId: tenantId,
          });
          const arrAziende = [];
          aziendeResponse.data.data.azienda.map((item) =>
            arrAziende.push(
              new aziendaModel(
                item.Id,
                tenantId,
                item.Denominazione,
                item.Cf,
                item.PIva,
                item.AddrPaeseId,
                item.AddrProvinciaId,
                item.AddrProvincia,
                item.AddrComuneId,
                item.AddrComune,
                item.AddrIndirizzo,
                item.AddrN,
                item.AddrCap,
                item.CreatedOn,
                item.ModifiedOn,
                item.CreatedBy,
                item.ModifiedBy,
                0
              )
            )
          );

          sharedDatas.setPropertyValue("aziendaModelList", arrAziende);

          //Invio una notifica tramite pub/sub interno
          postal.publish({
            channel: "azienda",
            topic: "azienda.update",
            data: {
              aziende: arrAziende,
            },
          });

          navigate(pathAziendaHome);
        }
      } else {
        enqueueSnackbar(t("error.missingFields"), { variant: "error" });
      }
    } catch (e) {
      AddLog(e.message,e.stack,"handleSaveClick")      
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
    }
  };
  //#endregion

  //#region Workspace methods
  const savePersonalDataOnWorkspace = async (aziendaResultModel) => {
    let itemId = 0;
    try {
      const wsResponse = await tsApiWorkspace.fetch({
        headers: {
          Authorization: "Bearer " + accessToken,
          "X-App-Name": "TS420",
          "X-App-Version": "1.0.0",
          "X-Request-ID": uuidv4(),
          "X-Correlation-ID": uuidv4(),
          "Content-Type": "application/json",
          "X-Item-ID": tenantId,
          "X-User-ID": loggedUserId,
        },
        data: {
          ownerId: tenantId,
          classifier: "COMPANY",
          personalData: {
            taxIdentifier: aziendaResultModel.cf,
            vatIdentifier: aziendaResultModel.pIva,
            businessName: aziendaResultModel.denominazione,
            countryCode: "IT",
          },
          baseCurrency: "EUR",
        },
      });

      itemId = wsResponse.data.id !== null ? wsResponse.data.id : 0;
      return itemId;
    } catch (e) {
      AddLog(e.message,e.stack,"handleSaveClick")      
    }
  };
  const saveOfficesOnWorkSpace = async (workspaceId, aziendaResultModel) => {
    var officeData = {
      description: "Indirizzo Principale",
      officeType: "MAIN",
      streetNumber: aziendaResultModel.addrN,
      streetName: aziendaResultModel.addrIndirizzo,
      province: aziendaResultModel.addrProvincia,
      city: aziendaResultModel.addrComune,
      district: "",
      zipCode: aziendaResultModel.addrCap,
      region: "",
      country: "",
      officeClassifier: [],
      customClassifierDecription : null,
      registeredResidenceType: "REGISTERED_RESIDENCE_AND_DOMICILE",      
    }
    try{          
          var resp = await fetch(`aml:workspace-write:api://${workspaceId}/office`,{
            method : 'POST',
            headers: {
                "Authorization": "Bearer " + accessToken, "X-App-Name": "TS420", "X-App-Version": "1.0.0", "X-Request-ID": uuidv4(), "X-Correlation-ID": uuidv4(),
                "Content-Type": "application/json", "X-Item-ID": tenantId, "X-User-ID": loggedUserId, "X-Workspace-ID" : workspaceId 
            },
            body : JSON.stringify(officeData)
          })                      
          const stringResponse = await resp.json()
          var officeId = stringResponse.id
          return officeId; 
    }
    catch (e) {    
      AddLog(e.message,e.stack,"saveOfficesOnWorkspace")        
    } 
  }
  //#endregion

  //#region Return Graphics
  return (
    <Fragment>
      <VaporPage
        title={
          currentAzienda.id === 0
            ? t("azienda.titoloAdd")
            : t("azienda.titoloManage")
        }
        headerLeft={
          <Button
            variant="text"
            size="medium"
            color="primary"
            onClick={handleGoHome}
            sx={{ ml: 2 }}
          >
            {t("autovalutazione.autovalutazioneAdd.back")}
          </Button>
        }
      >
        <Loading open={backDropOpen} />

        {isDataLoaded && !isUserOwner ? <NotAuth /> : null}

        {isDataLoaded && isUserOwner ? (
          <VaporPage.Section>
            <Stack direction="column" spacing={2}>
              <Grid container>
                <Grid item xs={3} sx={{ pr: 2 }}>
                  <TextField
                    id="txtCf"
                    key={uuidv4()}
                    label={t("anagrafica.ImportDesktop.codFisc")}
                    defaultValue={currentAzienda.cf}
                    size="small"
                    fullWidth
                    required
                    onChange={handleChange}
                    inputProps={{ autocomplete: "nope",maxLength:16}}
                    readOnly = {currentAzienda.cf}
                  />
                </Grid>
                <Grid item xs={3} sx={{ pr: 2 }}>
                  <TextField
                    id="txtPIva"
                    key={uuidv4()}
                    label={t("fascicolo.fascicoloNew.vatNumber")}
                    defaultValue={currentAzienda.pIva}
                    size="small"
                    fullWidth
                    required
                    onChange={handleChange}
                    inputProps={{ autocomplete: "nope",maxLength:11 }}
                    readOnly = {currentAzienda.pIva}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="txtDenominazione"
                    key={uuidv4()}
                    label={t("anagrafica.ImportDesktop.denom")}
                    defaultValue={currentAzienda.denominazione}
                    size="small"
                    fullWidth
                    required
                    onChange={handleChange}
                    inputProps={{ autocomplete: "nope",maxLength:500 }}
                    readOnly = {tenant.identifier === currentAzienda.cf}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={4} sx={{ pr: 2 }}>
                  <Autocomplete
                    id="ddlAddrPaese"
                    key={uuidv4()}
                    size="small"
                    fullWidth
                    options={basePaeseList}
                    sx={{ width: "100%" }}
                    isOptionEqualToValue={(option, value) =>
                      currentAzienda.addrPaeseId === undefined
                        ? option.id === value.id
                        : option.id === currentAzienda.addrPaeseId
                    }
                    getOptionLabel={(option) => option.nome}
                    defaultValue={
                      currentAzienda.addrPaeseId !== undefined
                        ? basePaeseList.filter(
                            (item) => item.id === currentAzienda.addrPaeseId
                          )[0]
                        : null
                    }
                    renderInput={(params) => (
                      <TextField {...params} required label="Nazione" />
                    )}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={3} sx={{ pr: 2 }}>
                  {isAddrPaeseIta ? (
                    <Autocomplete
                      id="ddlAddrProvincia"
                      key={uuidv4()}
                      size="small"
                      fullWidth
                      options={baseProvinciaList}
                      sx={{ width: "100%" }}
                      isOptionEqualToValue={(option, value) =>
                        currentAzienda.addrProvinciaId === undefined
                          ? option.id === value.id
                          : option.id === currentAzienda.addrProvinciaId
                      }
                      getOptionLabel={(option) => option.nome}
                      defaultValue={
                        currentAzienda.addrProvinciaId !== undefined
                          ? baseProvinciaList.filter(
                              (item) =>
                                item.id === currentAzienda.addrProvinciaId
                            )[0]
                          : null
                      }
                      renderInput={(params) => (
                        <TextField {...params} required label="Provincia" />
                      )}
                      onChange={handleChange}
                    />
                  ) : (
                    <TextField
                      id="txtAddrProvincia"
                      key={uuidv4()}
                      label={t("prestazione.form.provincia")}
                      size="small"
                      defaultValue={currentAzienda.addrProvincia}
                      fullWidth
                      required
                      onChange={handleChange}
                      inputProps={{ autocomplete: "nope",maxLength:255 }}
                    />
                  )}
                </Grid>
                <Grid item xs={5}>
                  {isAddrPaeseIta ? (
                    <Autocomplete
                      id="ddlAddrComune"
                      key={uuidv4()}
                      size="small"
                      fullWidth
                      options={baseComuneList}
                      sx={{ width: "100%" }}
                      isOptionEqualToValue={(option, value) =>
                        currentAzienda.addrComuneId === undefined
                          ? option.id === value.id
                          : option.id === currentAzienda.addrComuneId
                      }
                      getOptionLabel={(option) => option.nome}
                      defaultValue={
                        currentAzienda.addrComuneId !== undefined
                          ? baseComuneList.filter(
                              (item) => item.id === currentAzienda.addrComuneId
                            )[0]
                          : null
                      }
                      renderInput={(params) => (
                        <TextField {...params} required label="Comune" />
                      )}
                      onChange={handleChange}
                    />
                  ) : (
                    <TextField
                      id="txtAddrComune"
                      key={uuidv4()}
                      label={t("prestazione.form.comune")}
                      size="small"
                      defaultValue={currentAzienda.addrComune}
                      fullWidth
                      required
                      onChange={handleChange}
                      inputProps={{ autocomplete: "nope",maxLength:255 }}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={8} sx={{ pr: 2 }}>
                  <TextField
                    id="txtAddrIndirizzo"
                    key={uuidv4()}
                    label={t("prestazione.form.indirizzo")}
                    size="small"
                    defaultValue={currentAzienda.addrIndirizzo}
                    fullWidth
                    required
                    onChange={handleChange}
                    inputProps={{ autocomplete: "nope",maxLength:255 }}
                  />
                </Grid>
                <Grid item xs={2} sx={{ pr: 2 }}>
                  <TextField
                    id="txtAddrN"
                    key={uuidv4()}
                    label="N."
                    size="small"
                    defaultValue={currentAzienda.addrN}
                    fullWidth
                    onChange={handleChange}
                    inputProps={{ autocomplete: "nope",maxLength:10 }}
                  />
                </Grid>
                <Grid item xs={2}>
                  <TextField
                    id="txtAddrCap"
                    key={uuidv4()}
                    label="CAP"
                    size="small"
                    defaultValue={currentAzienda.addrCap}
                    fullWidth
                    required
                    onChange={handleChange}
                    inputProps={{ autocomplete: "nope",maxLength:10 }}
                  />
                </Grid>
              </Grid>
            </Stack>
          </VaporPage.Section>
        ) : null}
      </VaporPage>

      {isDataLoaded && isUserOwner? (
        <VaporToolbar
          variant="regular"
          size="medium"
          withoutAppBar={false}
          contentRight={
            <Stack direction="row" spacing={2}>
              <Button
                variant="outlined"
                color="secondary"
                size="medium"
                endIcon={<CancelIcon />}
                onClick={handleCancelClick}
              >
                {t("actions.annulla")}
              </Button>
              <Button
                variant="contained"
                size="medium"
                endIcon={<SaveIcon />}
                onClick={handleSaveClick}
              >
                {currentAzienda.id > 0
                  ? t("actions.aggiorna")
                  : t("actions.salva")}
              </Button>
            </Stack>
          }
        ></VaporToolbar>
      ) : null}
    </Fragment>
  );
  //#endregion
};
