import React from "react";
import { useEffect, useState } from "react"
import { Loading } from "../../../components/Loading";
import { count_active_user_per_role, get_user_roles_ByTenant } from "../../../businessLogic/query";
import sharedDatas from "../../../businessLogic/sharedDatas";
import VaporPage from "@vapor/react-custom/VaporPage";
import Grid from "@vapor/react-material/Grid";
import { NoData } from "../../../components/NoData";
import {
    DataGridPro,
    GridToolbar,
    itIT,
} from "@mui/x-data-grid-pro";
import VaporTag from "@vapor/react-custom/VaporTag";

//oneFront SDK
import {
    useQuery,
    useMutation,
    useSnackbar,
    useTranslation
} from "@onefront/react-sdk";
import { ExtendedTabs, ExtendedTab } from "@vapor/react-extended";
import { userModel,userPermissionModel } from "../../../businessLogic/models";
import { bsaSettingsNominations, pathNominationHome } from "../../../businessLogic/constants";
import { chipGreen, chipRed } from "../../../businessLogic/styles";
import { mutation_add_lastActivity_byUserId, mutation_add_log, mutation_add_user_role, mutation_delete_lastActivity, mutation_delete_user_role, mutation_update_user_role } from "../../../businessLogic/mutation";
import { Add as AddIcon, DeleteOutlineOutlined as DeleteIcon, ArrowForward as DetailIcon } from "@mui/icons-material";
import { Button, IconButton, Stack } from "@vapor/react-material";
import { ModalNominationAdd } from "./ModalNominationAdd";
import { ModalDelete } from "../../../components/ModalDelete";
import { NotAuth } from "../../../components/NotAuth";

export const NominationHome = () => {

    //const loggedUserId = useTokenData("sub");
    const tenantId = sharedDatas.getPropertyByName("tenantId");
    const version = sharedDatas.getPropertyByName("version");
    const env = sharedDatas.getPropertyByName("env");
    const aziendaId = sharedDatas.getPropertyByName("aziendaId");
    const loggedUserId = sharedDatas.getPropertyByName("userId");    
    const { t } = useTranslation();    
    const { enqueueSnackbar } = useSnackbar();
    const dataColumns = [
        { field: "userId", headerName: "userId", flex: 0.2, hidden : true  },
        {
                field: "email",
                headerName: t("impostazioni.utenti.form.email"),
                flex: 0.5,
                editable: false,
        },
        {
            field: "name",
            headerName: t("impostazioni.utenti.form.name"),
            flex: 0.3,
            editable: false,
        },
        {
            field: "surname",
            headerName: t("impostazioni.utenti.form.surname"),
            flex: 0.3,
            editable: false,
        },
        {
            field: "type",
            headerName: t("impostazioni.utenti.form.role"),
            flex: 0.3,
            editable: false,
        },        
        {
            field : "dateStart",
            headerName: "Data inizio ruolo",
            flex:0.2,
            editable:false,
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return new Date(params.value).toLocaleDateString();
            }
        },
        {
            field : "dateEnd",
            headerName: "Data fine ruolo",
            flex:0.2,
            editable:false,
            valueFormatter: (params) => {
                if (params.value == null) {
                    return "";
                }
                return new Date(params.value).toLocaleDateString();
            }
        },
        {
            field : "roleActive",
            headerName: "Stato",
            flex:0.2,
            editable:false,
            renderCell: (params) => renderColorCell(params.row),
            valueGetter : (params) => renderColorCellGetter(params.row)
        },
        {
            field: "actions",
            headerName: "",
            flex: 0.2,
            filterable: false,
            renderCell: (params) =>
                renderEditButton(params.row),
        },
    ]
    
    //#region UseState  
    const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
    const [selectedRole, setSelectedRole] = useState("responsabile_antiriciclaggio")
    const [usersList,setUsersList] = useState([])
    const [showAddModal,setShowAddModal] = useState(false)
    const [preSelectedUser,setPreSelectedUser] = useState(null)
    const [showDeleteModal, setShowDeleteModal] = useState(false); //Modal per la conferma di eliminazione di un record
    const [userPermissions,setUserPermissions] = useState(new userPermissionModel(
        0,
        aziendaId,
        bsaSettingsNominations,
        false,
        false,
        false,
        false
    ))
    //#endregion

    //#region Query
    const getUserRoles = useQuery(
        get_user_roles_ByTenant,
        {},
        { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    )
    const countUserRoles = useQuery(
        count_active_user_per_role,
        {},
        { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
    )
    const apiAddLog = useMutation(mutation_add_log, {
        graphqlEndpoint: "aml:hasura:api://v1/graphql",
    });
    const apiAddLastActivity = useMutation(mutation_add_lastActivity_byUserId, {
        graphqlEndpoint: "aml:hasura:api://v1/graphql",
    });
    const apiDeleteLastActivity = useMutation(mutation_delete_lastActivity, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
    });
    const addUserRole = useMutation(
        mutation_add_user_role,
        {graphqlEndpoint: "aml:hasura:api://v1/graphql"}
    )
    const updateUserRole = useMutation(
        mutation_update_user_role,
        {graphqlEndpoint: "aml:hasura:api://v1/graphql"}
    )
    const deleteUserRole = useMutation(
        mutation_delete_user_role,
        {graphqlEndpoint: "aml:hasura:api://v1/graphql"}
    )
    //#endregion

    //#region Render
    const renderColorCell = (item)=>{
        if(!item.roleActive){
            return <VaporTag variant='standard' label='Cessato' sx={chipRed} />;
        } else {
            return <VaporTag variant='standard' label='Attivo' sx={chipGreen} />;
        }
    }
    const renderColorCellGetter = (item) => {
        if(!item.roleActive){
            return "Cessato"
        } else {
            return "Attivo"
        }
    }
    const renderEditButton = (itemToedit)=>{
        return (
            <Stack direction="row" spacing={0}>
                {userPermissions.canDelete &&
                    <IconButton
                        color='error'
                        onClick={(e) => handleDeleteRole(e, itemToedit)}
                    >
                        <DeleteIcon />
                    </IconButton>                
                }
                {userPermissions.canUpdate &&
                    <IconButton
                        color="primary"
                        onClick={(e) => handleOpenEditModal(e,itemToedit)}
                    >
                        <DetailIcon />
                    </IconButton>
                }
            </Stack>
        );
    }
    //#endregion

    useEffect(()=>{
        /**************************************/
        //Carico i permessi relativi all'utente
        setUserPermissions(
            sharedDatas
            .getPropertyByName("userPermissions")
            .filter((x) => x.area === bsaSettingsNominations)[0]
        );
        manageLastActivity()        
        loadNominationRoles()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[selectedRole,showAddModal,showDeleteModal])

    //#region Logs
    const AddLog = (message, stack, method) => {
        if(env === "production"){
            apiAddLog.fetch({
                tenantId: tenantId,
                aziendaId: 0,
                level: "ERROR",
                message: message,
                stack: stack,
                area: "NominationHome",
                method: method,
                version: version,
            });
        }
    };
    //#endregion

    async function loadNominationRoles(){
        try {
            setBackDropOpen(true)
            let roles = await getUserRoles.fetch({
                tenantId: tenantId,
                role: selectedRole
            })
            let allUserInRoles = []
            let today = new Date()
            roles.data.data.user_roles.forEach(item => {
                allUserInRoles.push(new userModel(
                    item.UserId,
                    item.TenantId,
                    item.user.Username,
                    item.user.Email,
                    item.user.Name,
                    item.user.Surname,
                    item.user.Name + " " + item.user.Surname,
                    item.Role === "revisore_indipendente"? "Revisore indipendente" : "Responsabile antiriciclaggio" ,
                    item.user.Locale,
                    0,
                    item.user.IsRespAntiRic,
                    "",
                    item.user.IsActive,
                    item.user.IsDeleted,
                    item.user.IsBanned,
                    item.user.CreatedOn,
                    item.user.Cf,
                    "",
                    false,
                    new Date(item.DateStart) <= today && (item.DateEnd !== null ? new Date(item.DateEnd) >= today : true),
                    item.DateStart,
                    item.DateEnd
                ))
            });
            setUsersList(allUserInRoles)
        } catch (error) {
            AddLog(error.message,error.stack,"LoadNominationRoles")            
        } finally {
            setBackDropOpen(false)
        }
    }

    async function manageLastActivity() {
        try {
            await apiDeleteLastActivity.fetch({
                tenantId: tenantId,
                aziendaId: aziendaId,
                userId: loggedUserId,
            });
            await apiAddLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            path: pathNominationHome,
            entityId: "",
            entityName: "",
            });           
        } catch (error) {
            AddLog("Impossibile aggiornare l'ultima attività utente",error.stack,"manageLastActivity")
        }        
    }
    //#region handlers
    const handleChangeTab = (event,newValue) => {
        setSelectedRole(newValue)
    }
    const handleAddNew = ()=>{
        setShowAddModal(true)
    }
    const handleModalAddOk = async (selUser,startDate,endDate,selectedRole) => {
        try {  
            
            if(preSelectedUser !== null && preSelectedUser.userId !== selUser.userId){ 
                //Elimino il vecchio utente in edit se l'utente da aggiornare è diverso da quello iniziale(poi vado in salvataggio dei nuovi dati)
                await deleteUserRole.fetch({
                    tenantId : preSelectedUser.tenantId,
                    userId : preSelectedUser.userId,
                    role: preSelectedUser.type === "Revisore indipendente" ? "revisore_indipendente" : "responsabile_antiriciclaggio"
                })            
            }

            //Controllo se esiste già un utente attivo diverso da quello in salvataggio nel ruolo selezionato e nel caso torno un errore
            let responseCount = await countUserRoles.fetch({
                role : selectedRole === "Revisore indipendente" ? "revisore_indipendente" : "responsabile_antiriciclaggio",
                tenantId : tenantId
            })            
            if(responseCount.data.data.user_roles.length === 1){
                if(responseCount.data.data.user_roles[0].UserId !== selUser.userId){
                    enqueueSnackbar("Esiste già un utente con questo ruolo",{variant:"error"})
                    return
                }
            }
            
            if(preSelectedUser !== null && preSelectedUser.userId === selUser.userId){ 
                //Aggiorno il ruolo
                await updateUserRole.fetch({
                    tenantId : tenantId,
                    userId : selUser.userId,
                    role : selectedRole === "Revisore indipendente" ? "revisore_indipendente" : "responsabile_antiriciclaggio",
                    dateStart : new Date(new Date(startDate).setHours(12)),
                    dateEnd: endDate !== "" ? new Date(new Date(endDate).setHours(12)) : null
                })
                enqueueSnackbar("Aggiornamento avvenuto con successo",{variant:"success"})
            } else { 
                //Creo il ruolo                            

                //Controllo se l'utente in salvataggio ha già il ruolo che si sta aggiungendo in stato attivo e nel caso torno un errore
                let sameUser = usersList.filter(p=>p.userId === selUser.userId)[0]
                if(sameUser && sameUser.roleActive && sameUser.type === selectedRole){
                    enqueueSnackbar("L'utente selezionato ha già una nomina attiva per il ruolo selezionato",{variant:"error"})
                    return
                }                                

                await addUserRole.fetch({
                    tenantId : tenantId,
                    userId : selUser.userId,
                    role : selectedRole === "Revisore indipendente" ? "revisore_indipendente" : "responsabile_antiriciclaggio",
                    dateStart : new Date(new Date(startDate).setHours(12)),
                    dateEnd : endDate !== "" ? new Date(new Date(endDate).setHours(12)) : null
                })
                            
                enqueueSnackbar("Salvataggio avvenuto con successo",{variant:"success"})
            }
        } catch (error) {            
            enqueueSnackbar("Si è verififcato un errore durante il salvataggio dei dati",{variant: "error"})
        } finally {            
            setShowAddModal(false)
        }
    }
    const handleModalAddClose = () => {
        setPreSelectedUser(null)
        setShowAddModal(false)
    }
    const handleOpenEditModal = (e,itemToedit) =>{
        if(userPermissions.canUpdate){
            e.stopPropagation();
            setPreSelectedUser(itemToedit)
            setShowAddModal(true)
        }
    }
    const handleDeleteRole = (e,itemToDelete) => {
        e.stopPropagation();
        setPreSelectedUser(itemToDelete);
        setShowDeleteModal(true);
    }
    const handleModalDeleteClose = () => {
        setPreSelectedUser(null)
        setShowDeleteModal(false);
    };
    const handleModalDeleteOkClick = async () => {
        try {
            await deleteUserRole.fetch({
                tenantId : preSelectedUser.tenantId,
                userId : preSelectedUser.userId,
                role: preSelectedUser.type === "Revisore indipendente" ? "revisore_indipendente" : "responsabile_antiriciclaggio"
            })            
            enqueueSnackbar("Eliminazione avvenuta con successo", { variant: "success" });            
        } catch (e) {
            AddLog(e.message,e.stack,"handleModalDeleteOkClick")      
            enqueueSnackbar("si è verificato un errore durante l'eliminazione", { variant: "error" });
        } finally {
            setPreSelectedUser(null)
            setShowDeleteModal(false)
        }
    };
    //#endregion
    
    //#region Return graphics
    return(
        <VaporPage 
            title="Ruoli"
            headerRight={                
                <Button
                    variant='text'
                    size='medium'
                    endIcon={<AddIcon />}
                    color='primary'
                    onClick={handleAddNew}
                    disabled={!userPermissions.canCreate}
                    sx={{ mr: 2 }}
                    >
                    Nomina
                </Button>                
            }
        >        

            <Loading open={backDropOpen} />
            
            {showDeleteModal ? (
                <ModalDelete
                    show={showDeleteModal}
                    onClose={handleModalDeleteClose}
                    onOk={handleModalDeleteOkClick}
                ></ModalDelete>
            ) : null}

            <ModalNominationAdd
                tenantId={tenantId}
                show={showAddModal}
                userFromParent = {preSelectedUser}
                selectedRoleFromParent = {selectedRole}
                onClose={handleModalAddClose}
                onOk={handleModalAddOk}                
            />  
            {!userPermissions.canRead ? <NotAuth /> : (                                                                   
                <VaporPage.Section>
                    <ExtendedTabs
                        variant="standard"
                        size="small"
                        value={selectedRole}
                        onChange={handleChangeTab}
                        sx={{ mb: 2 }}
                    >                    
                        <ExtendedTab
                            value="responsabile_antiriciclaggio"
                            label="Responsabili antiriciclaggio"
                        />
                        <ExtendedTab 
                            value="revisore_indipendente" 
                            label="Revisori indipendenti" 
                        />
                    </ExtendedTabs>

                    <Grid container>
                        <Grid item xs={12} sx={{ width: "100%" }}>
                            {usersList.length > 0 ? (
                            <DataGridPro
                                rows={usersList}
                                columns={dataColumns}
                                getRowId={(row) => row.userId}
                                slots={{ toolbar: GridToolbar }}
                                autoHeight={true}
                                pageSizeOptions={[25, 50, 100]}
                                localeText={
                                itIT.components.MuiDataGrid.defaultProps.localeText
                                }
                                pagination
                                onRowClick={(params,e)=> handleOpenEditModal(e,params.row)}                            
                                initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                    userId: false,
                                    },
                                },
                                pagination: {
                                    paginationModel: {
                                    pageSize: 25,
                                    },
                                },
                                }}
                                slotProps={{
                                toolbar: { printOptions: { disableToolbarButton: true } },
                                }}
                            />
                            ) : (
                            <NoData />
                            )}
                        </Grid>
                </Grid>
                </VaporPage.Section>        
            )}

        </VaporPage>
    )
    //#endregion
}