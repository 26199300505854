//#region Imports
import React, { useState, useEffect } from "react";
import sharedDatas from "../../businessLogic/sharedDatas";
import { useNavigate } from "react-router-dom";

//oneFront SDK
import {
  useSnackbar,
  useQuery,
  useMutation,
  useTranslation,
} from "@onefront/react-sdk";

import VaporPage from "@vapor/react-custom/VaporPage";
import VaporTag from "@vapor/react-custom/VaporTag";

//Vapor Components
import Grid from "@vapor/react-material/Grid";
import Stack from "@vapor/react-material/Stack";
import IconButton from "@vapor/react-material/IconButton";

//Icons
import { ArrowForward as DetailIcon } from "@mui/icons-material";

//Custom styles
import {
  chipGreen,
  chipYellow,
  chipOrange,
  chipRed,
} from "../../businessLogic/styles";

import {
  DataGridPro,
  GridToolbar,
  itIT,
} from "@mui/x-data-grid-pro";

//Custom components
import { Loading } from "../../components/Loading";
import { NotAuth } from "../../components/NotAuth";
import { NoData } from "../../components/NoData";

//Constants, Api and Data Models
import {
  bsaPrestazione,
  pathPrestazioneHome,
} from "../../businessLogic/constants";
import {
  setLocalStorageSelectedFascicolo,
  setLocalStorageSelectedEntity,
} from "../../businessLogic/bl";
import { userPermissionModel } from "../../businessLogic/models";
import { query_prestazioni_byTenant } from "../../businessLogic/query";
import {
  mutation_add_log,
  mutation_add_lastActivity_byUserId,
  mutation_delete_lastActivity,
} from "../../businessLogic/mutation";
//#endregion

export const PrestazioneHome = ({ basePath }) => {
  const tenantId = sharedDatas.getPropertyByName("tenantId");
  const aziendaId = sharedDatas.getPropertyByName("aziendaId");
  const loggedUserId = sharedDatas.getPropertyByName("userId");
  const version = sharedDatas.getPropertyByName("version");
  const env = sharedDatas.getPropertyByName("env");
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  //Query
  const apiPrestazioniByTenant = useQuery(
    query_prestazioni_byTenant,
    {},
    { lazy: true, graphqlEndpoint: "aml:hasura:api://v1/graphql" }
  );

  //#region Mutation
  const apiAddLog = useMutation(mutation_add_log, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiAddLastActivity = useMutation(mutation_add_lastActivity_byUserId, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  const apiDeleteLastActivity = useMutation(mutation_delete_lastActivity, {
    graphqlEndpoint: "aml:hasura:api://v1/graphql",
  });
  //#endregion

  //#region UseStates
  const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
  const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api
  const [userPermissions, setUserPermissions] = useState(
    new userPermissionModel(
      0,
      aziendaId,
      bsaPrestazione,
      false,
      false,
      false,
      false
    )
  );
  const [, setPrestazioniList] = useState([]); //Contiene le prestazioni

  //DataGrid
  const [, setDataItems] = useState([]); //Contiene i records
  const [dataColumns, setDataColumns] = useState([]); //Contiene le columns
  const [dataRows, setDataRows] = useState([]); //Contiene le rows
  //#endregion

  useEffect(() => {
    loadDatas();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataLoaded]);
  
  const AddLog = async (message,stack,method) => {
    if(env === "production"){
        apiAddLog.fetch({
        tenantId: tenantId,
            aziendaId: aziendaId,
            level: "ERROR",
            message: message,
            stack: stack,
            area: "PrestazioneHome",
            method: method,
            version: version,
        })
    }
  }

  async function loadDatas() {
    try {
      if (
        sharedDatas.getPropertyByName("tenantId") === "" ||
        sharedDatas.getPropertyByName("tenantModel") === null ||
        sharedDatas.getPropertyByName("aziendaId") === 0 ||
        sharedDatas.getPropertyByName("aziendaModelList") === null ||
        sharedDatas.getPropertyByName("userId") === "" ||
        sharedDatas.getPropertyByName("userModel") === null
      ) {
        navigate("/");
      } else {
        setBackDropOpen(true);

        if (!isDataLoaded) {
          //Last Activity
          await apiDeleteLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            userId: loggedUserId,
          });
          await apiAddLastActivity.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            path: pathPrestazioneHome,
            entityId: "",
            entityName: "",
          });

          /**************************************/
          //Carico i permessi relativi all'utente
          const userPerms = sharedDatas
            .getPropertyByName("userPermissions")
            .filter((x) => x.area === bsaPrestazione)[0];
          setUserPermissions(userPerms);

          /**************************************/
          //Carico le prestazioni
          setDataColumns([
            { field: "id", headerName: "Id", type: "number", flex: 0.2 },
            {
              field: "fascicoloNome",
              headerName: "Fascicolo",
              type: "string",
              flex: 1,
            },
            {
              field: "prestazioneNome",
              headerName: "Prestazione",
              type: "string",
              flex: 1,
            },
            {
              field: "dataStart",
              headerName: "Data inizio",
              type: "date",
              flex: 0.4,
              valueFormatter: (params) => {
                if (params.value == null) {
                  return "";
                }
                return new Date(params.value).toLocaleDateString();
              },
            },
            {
              field: "scopoPrestazione",
              headerName: "Scopo",
              type: "string",
              flex: 1,
            },
            {
              field: "naturaPrestazione",
              headerName: "Natura",
              type: "string",
              flex: 0.5,
            },
            {
              field: "nUsers",
              headerName: "Professionisti",
              type: "number",
              flex: 0.5,
            },
            {
              field: "nPagamenti",
              headerName: "Mezzi di pagamento",
              type: "number",
              flex: 0.6,
            },
            {
              field: "nRischi",
              headerName: "Rischi",
              type: "number",
              flex: 0.5,
            },
            {
              field: "rischioInerente",
              headerName: "Rischio Inerente",
              type: "string",
              flex: 0.6,
              renderCell: (params) => renderLivelloRischio(params.row),
              valueGetter: (params) => renderLivelloRischioS(params.row),
            },
            {
              field: "actions",
              headerName: "",
              flex: 0.2,
              filterable: false,
              renderCell: (params) =>
                renderDetailsButton(userPerms, params.row),
            },
          ]);

          const prestResponse = await apiPrestazioniByTenant.fetch({
            tenantId: tenantId,
            aziendaId: aziendaId,
            userId: loggedUserId,
          });
          const arrItems = [];
          for (let i = 0; i < prestResponse.data.data.prestazione.length; i++) {
            const item = prestResponse.data.data.prestazione[i];
            const itemModel = {
              id: item.Id,
              fascicoloId: item.fascicolo.Id,
              fascicoloNome: item.fascicolo.Name,
              prestazioneNome: item.base_tipo_prestazione.Nome,
              dataStart: item.StartDate,
              scopoPrestazione: item.base_scopo_prestazione.Value,
              naturaPrestazione: item.base_natura_prestazione.Value,
              nPagamenti: item.prestazione_pagamentos_aggregate.aggregate.count,
              nUsers: item.prestazione_users_aggregate.aggregate.count,
              nRischi: item.rischios_aggregate.aggregate.count,
              rischioInerente: item.base_tipo_prestazione.Rischio,
            };

            arrItems.push(itemModel);
          }
          setPrestazioniList(arrItems);
          setDataItems(arrItems);
          setDataRows(arrItems);
        }
      }
    } catch (e) {
      AddLog(e.message,e.stack,"loadDatas")      
      enqueueSnackbar(t("error.generale"), { variant: "error" });
    } finally {
      setBackDropOpen(false);
      setIsDataLoaded(true);
    }
  }


  //DataGrid
  //#region Grid Button Detail
  const renderLivelloRischio = (item) => {
    let livelloS = t("valutazione.form.rischioInerenteLivello1");
    let livelloC = chipGreen;

    switch (item.rischioInerente) {
      case 1: {
        livelloS = t("valutazione.form.rischioInerenteLivello1");
        livelloC = chipGreen;
        break;
      }
      case 2: {
        livelloS = t("valutazione.form.rischioInerenteLivello2");
        livelloC = chipYellow;
        break;
      }
      case 3: {
        livelloS = t("valutazione.form.rischioInerenteLivello3");
        livelloC = chipOrange;
        break;
      }
      case 4: {
        livelloS = t("valutazione.form.rischioInerenteLivello4");
        livelloC = chipRed;
        break;
      }
      default: {
        break;
      }
    }

    return <VaporTag variant="standard" label={livelloS} sx={livelloC} />;
  };
  const renderLivelloRischioS = (item) => {
    let livelloS = t("valutazione.form.rischioInerenteLivello1");

    switch (item.rischioInerente) {
      case 1: {
        livelloS = t("valutazione.form.rischioInerenteLivello1");
        break;
      }
      case 2: {
        livelloS = t("valutazione.form.rischioInerenteLivello2");
        break;
      }
      case 3: {
        livelloS = t("valutazione.form.rischioInerenteLivello3");
        break;
      }
      case 4: {
        livelloS = t("valutazione.form.rischioInerenteLivello4");
        break;
      }
      default: {
        break;
      }
    }

    return livelloS;
  };
  const renderDetailsButton = (userPerms, item) => {
    return (
      <Stack direction="row" spacing={0}>
        {userPerms.canRead ? (
          <IconButton
            color="primary"
            onClick={(e) => handleGridBtnDetailClick(item)}
          >
            <DetailIcon />
          </IconButton>
        ) : null}
      </Stack>
    );
  };
  const handleGridBtnDetailClick = (item) => {
    setLocalStorageSelectedFascicolo(item.fascicoloId);
    setLocalStorageSelectedEntity("prestazione", item.id);
    navigate("/fascicolo/manage");
  };
  //#endregion

  //#region Return Graphics
  return (
    <VaporPage title={t("nav.prestazioni")}>
      <Loading open={backDropOpen} />

      {isDataLoaded && !userPermissions.canRead ? <NotAuth /> : null}

      {isDataLoaded && userPermissions.canRead ? (
        <VaporPage.Section>
          <Grid container>
            <Grid item xs={12} sx={{ width: "100%" }}>
              {dataRows.length > 0 ? (
                <DataGridPro
                  rows={dataRows}
                  columns={dataColumns}
                  slots={{ toolbar: GridToolbar }}
                  autoHeight={true}
                  pageSizeOptions={[25, 50, 100]}
                  localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                  }
                  pagination
                  disableRowSelectionOnClick
                  onRowClick={(item) => handleGridBtnDetailClick(item.row)}
                  initialState={{
                    columns: {
                      columnVisibilityModel: {
                        id: false,
                      },
                    },
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  slotProps={{
                    toolbar: { printOptions: { disableToolbarButton: true } },
                  }}
                />
              ) : (
                <NoData />
              )}
            </Grid>
          </Grid>
        </VaporPage.Section>
      ) : null}
    </VaporPage>
  );
  //#endregion
};
