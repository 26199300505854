import { React,useEffect,useState } from "react"
import sharedDatas from "../../businessLogic/sharedDatas";
import {
    useSnackbar,    
    useMutation,    
    useTranslation,
} from "@onefront/react-sdk";
import { DataGridPro,GridToolbar,itIT } from '@mui/x-data-grid-pro';

//Custom components
import { Loading } from "../Loading";

import Paper from '@mui/material/Paper';
import { mutation_add_log, mutation_getLegalRepresentatives } from "../../businessLogic/mutation";
import VaporTag from "@vapor/react-custom/VaporTag";
import { chipGreen, chipRed } from "../../businessLogic/styles";


export const LegaliRappresentantiList = ({fascicolo,cessato,onSelection}) => {

    const tenantId = sharedDatas.getPropertyByName("tenantId");
    const aziendaId = sharedDatas.getPropertyByName("aziendaId");
    const loggedUserId = sharedDatas.getPropertyByName("userId");
    const version = sharedDatas.getPropertyByName("version");
    const env = sharedDatas.getPropertyByName("env");
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const paginationModel = { page: 0, pageSize: 5 };

    //#region Mutation
    const apiGetLegalRapprByFascicoloId = useMutation(
        mutation_getLegalRepresentatives,
        {graphqlEndpoint: "aml:hasura:api://v1/graphql"}
    )
    const apiAddLog = useMutation(mutation_add_log, {
        graphqlEndpoint: "aml:hasura:api://v1/graphql",
    });
    //#endregion    

    //#region UseState
    const [backDropOpen, setBackDropOpen] = useState(true); //Loader utilizzato durante il caricamento
    const [isDataLoaded, setIsDataLoaded] = useState(false); //Indica lo stato di caricamento del result Api
    const [legalRapprList, setLegalRapprList] = useState([]); //Collezione dei legali rappresentanti riguardanti al fascicolo
    const columns = [
        { field: 'first_name', headerName : 'Nome', flex:0.5},
        { field:'last_name', headerName: 'Cognome', flex: 0.3},       
        { 
            field:'identification_date',
            headerName: 'Data di identificazione',  
            flex:0.3           
        },        
        {   field: 'rule_is_terminated',
            headerName: 'Stato', flex:0.5,
            renderCell: (params) => renderColorCell(params.row),
            valueGetter : (params) => renderColorCellGetter(params.row)}
    ]; 
    //#endregion

//#region Render
    const renderColorCell = (item)=>{
        if(item.rule_is_terminated){
            return <VaporTag variant='standard' label='Cessato' sx={chipRed} />;
        } else {
            return <VaporTag variant='standard' label='Attivo' sx={chipGreen} />;
        }
    }
    const renderColorCellGetter = (item) => {
        if(item.rule_is_terminated){
            return "Cessato"
        } else {
            return "Attivo"
        }
    }
    //#endregion

    useEffect (()=>{        
        loadDatas()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cessato])

    async function loadDatas(){        
        try{                       
            setBackDropOpen(true)
            const response = await apiGetLegalRapprByFascicoloId.fetch({
                fascicoloId : fascicolo.id,
                userId : loggedUserId,
                isTerminated : cessato
            })           
            
            let allLegalRappr = []
            response.data.data.getLegalRepresentatives.response.persons.forEach(person => {
                allLegalRappr.push(person)
            });            
            setLegalRapprList(allLegalRappr)            
        } catch (graphQlError){
            //enqueueSnackbar(t("error.generale"), { variant: "error" });            
            //AddLog(graphQlError.originalError.extensions.internal, graphQlError.stack, "loadDatas")
        } finally {
            setBackDropOpen(false)
            setIsDataLoaded(true)
        }                
    }

    const AddLog = (graphQlError,stack, method) => {
        let message = `Error Code : ${graphQlError.response.status} on ${graphQlError.request.transformed_request.url}`        
        if(env === "production"){
            apiAddLog.fetch({
                tenantId: tenantId,
                aziendaId: aziendaId,
                level: "ERROR",
                message: message,
                stack: stack,
                area: "LegaliReppresentantiList",
                method: method,
                version: version,
            });
        }
    }  

    const handleRowSelection = (event) => {                  
        onSelection(event)
    }

    return (        
        <Paper sx={{height : 400 , width : '100%'}}>
            <Loading open={backDropOpen} />
            <DataGridPro 
                rows={legalRapprList}
                columns={columns}
                initialState={{pagination : paginationModel}}
                slots={{ toolbar: GridToolbar }}
                localeText={
                    itIT.components.MuiDataGrid.defaultProps.localeText
                }
                pageSizeOptions={[5,10]}
                //isRowSelectable={item=> item.row.rischi.length > 0}
                checkboxSelection                
                onRowSelectionModelChange={handleRowSelection}
                sx={{border: 0}}
                slotProps={{
                    toolbar: { printOptions: { disableToolbarButton: true } },
                }}                
            />
        </Paper>
    )
}